import type {
  Action,
  Middleware,
  MiddlewareAPI,
  Reducer,
  Store,
  StoreEnhancer,
} from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import MyHistory, { EVENT_URL_CHANGED } from '../history/myHistoryApi';
import type { HzdEventMeta } from '../reducer/createEvent';
import { TriggeredBy } from '../reducer/createEvent';
import { URL_REPLACED } from '../reducer/events';
import type { State } from '../state/createInitialState';

import type { SimpleStore } from '../state/SimpleStore';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: (
      param: Partial<Record<string, string | boolean>>,
    ) => StoreEnhancer;
  }
}

function has<P extends PropertyKey>(
  target: object,
  property: P,
): target is { [K in P]: unknown } {
  // The `in` operator throws a `TypeError` for non-object values.
  return property in target;
}
function isHzdEventMeta(event: unknown): event is HzdEventMeta {
  if (
    !(typeof event === 'object') ||
    !event ||
    !('meta' in event) ||
    !('type' in event) ||
    !(typeof event.type === 'string')
  ) {
    return false;
  }
  const { meta } = event;
  return (
    typeof meta === 'object' &&
    meta != null &&
    has(meta, 'triggeredBy') &&
    typeof meta.triggeredBy === 'string' &&
    has(meta, 'volatile') &&
    typeof meta.volatile === 'number'
  );
}

const historyMiddleware: (myHistory: MyHistory) => Middleware =
  (myHistory) =>
  (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    store: MiddlewareAPI,
  ) =>
  (next) =>
  (action) => {
    const result = next(action);
    if (!EVENT_URL_CHANGED.match(result) && isHzdEventMeta(action)) {
      if (action.type === URL_REPLACED().type) {
        myHistory.updateUrlFromState(Boolean(action.meta.volatile), true);
      } else if (action.meta.triggeredBy === TriggeredBy.User) {
        myHistory.updateUrlFromState(Boolean(action.meta.volatile), false);
      }
    }
    return result;
  };

export default function createStoreMy(
  rootReducer: Reducer,
  myHistory: MyHistory,
): { simpleStore: SimpleStore; store: Store<State, Action> } {
  const store = configureStore({
    reducer: myHistory.reducer(rootReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({}).concat(historyMiddleware(myHistory)),
    devTools: {
      serialize: true,
      trace: process.env.NODE_ENV === 'development',
    },
  });
  const simpleStore = {
    dispatch: store.dispatch,
    getState: store.getState.bind(store),
    navigate: myHistory.navigate,
    rootReducer,
  };

  myHistory.setStore(simpleStore);
  return { simpleStore, store };
}
