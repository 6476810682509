export default function onSpecialKey(
  code: number,
  handler: (event: KeyboardEvent) => void,
): (event: KeyboardEvent) => void {
  return (event: KeyboardEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    if (event.keyCode === code) {
      handler.call(event.target, event);
    }
  };
}
