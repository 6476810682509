import { createEvent, TriggeredBy } from '../reducer/createEvent';
import type { StateMapData } from '../state/createInitialState';

export const MAP_COUNTS_LOADING_ERROR = createEvent(
  'MAP_COUNTS_LOADING_ERROR',
  TriggeredBy.Other,
);

export type MapOnlineCountsResult =
  | {
      timestamp: string;
      bezirke: { regschl: string; label: string }[];
      counts: Partial<Record<string, number | undefined>>;
    }
  | undefined;
export const MAP_COUNTS_LOADING_FINISHED = createEvent<MapOnlineCountsResult>(
  'MAP_COUNTS_LOADING_FINISHED',
  TriggeredBy.Other,
);
export const MAP_COUNTS_LOADING_STARTED = createEvent<{
  abortRequest: () => void;
}>('MAP_COUNTS_LOADING_STARTED', TriggeredBy.Other);
export const MAP_DATA_LOADING_ERROR = createEvent(
  'MAP_DATA_LOADING_ERROR',
  TriggeredBy.Other,
);
export const MAP_DATA_LOADING_FINISHED = createEvent<StateMapData>(
  'MAP_DATA_LOADING_FINISHED',
  TriggeredBy.Other,
);
export const MAP_DATA_LOADING_STARTED = createEvent<{
  abortRequest: () => void;
}>('MAP_DATA_LOADING_STARTED', TriggeredBy.Other);
export const MAP_DROPDOWN_SHOWN = createEvent<string>(
  'MAP_DROPDOWN_SHOWN',
  TriggeredBy.User,
);
export const MAP_DETAILS_SHOWN = createEvent<string>(
  'MAP_DETAILS_SHOWN',
  TriggeredBy.User,
);
export const MAP_DROPDOWN_HIDDEN = createEvent<string>(
  'MAP_DROPDOWN_HIDDEN',
  TriggeredBy.User,
);
export const MAP_DROPDOWN_SELECTED = createEvent<{
  mapDomId: string;
  index: number | undefined;
}>('MAP_DROPDOWN_SELECTED', TriggeredBy.User);
export const MAP_CITY_INPUT_CHANGED = createEvent<{
  mapDomId: string;
  input: string | undefined;
}>('MAP_CITY_INPUT_CHANGED', TriggeredBy.User);
export const MAP_CITY_CHANGED = createEvent<{
  mapDomId: string;
  regschl: string;
}>('MAP_CITY_CHANGED', TriggeredBy.Other);
/** Das Laden der Bereichsseite wurde gestartet */
