import { Dropdowns } from '../../action/search/searchCommon';
import type { State } from '../../state/createInitialState';

export function selectParamsForSearch(state: State) {
  return {
    dropdownState: state.dropdowns[Dropdowns.SEARCH],
    pvLage: state.search.request.filter.pvLage,
    inputValue: state.search.request.searchTerm,
    showError: Boolean(state.error.search),
    showValidationErrors: state.search.showValidationErrors,
    suggestions: state.search.suggestionData,
  };
}
