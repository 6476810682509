/* eslint-disable no-param-reassign */
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { State } from '../state/createInitialState';
import { PV_LAGEN_COUNTS_LOADED, PV_LAGEN_LOADED } from './pvLagenEvents';

export function createPvLagenReducer(builder: ActionReducerMapBuilder<State>) {
  builder.addCase(PV_LAGEN_LOADED, (state, action) => {
    state.pvLagen.pvLagenByCode = action.payload;
  });
  builder.addCase(PV_LAGEN_COUNTS_LOADED, (state, action) => {
    const { counts, regschl } = action.payload;
    state.pvLagen.countsByRegschlAndCode[regschl] = counts;
  });
}
