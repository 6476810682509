import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { renderCityField } from '../blocks/citySearch';
import { renderPvLagenSearchField } from '../blocks/search';
import { selectParamsForCitySearch } from '../read/selectParamsForCitySearch';
import { selectParamsForSearch } from '../read/selectParamsForSearch';
import type { SimpleStore } from '../../state/SimpleStore';
import { contentLeft, SELECTOR_CONTENT_LEFT } from '../frame/contentLeft';
import { pvLagenNavigation } from '../read/extractDataForPvLagenNavigation';

export function bereichsseite(
  simpleStore: SimpleStore,
): Partial<Record<string, RenderResultWithSsr>> {
  const state = simpleStore.getState();
  return {
    '.og-search-field-box': renderPvLagenSearchField(simpleStore, {
      ...selectParamsForSearch(state),
      isStartpage: false,
    }),
    '.og-city-field-box': renderCityField(
      simpleStore,
      selectParamsForCitySearch(state),
    ),
    [SELECTOR_CONTENT_LEFT]: contentLeft(
      pvLagenNavigation(
        simpleStore,
        false,
        state.search.request.filter.pvLage,
        state.search.request.filter.pvLage,
      ),
    ),
  };
}
