/* eslint-disable no-param-reassign */
import type { Draft } from 'immer';
import {
  INITIAL_MAP_ONLINE_SERVICE,
  type State,
} from '../state/createInitialState';

export function resetOdMapState(draft: Draft<State>, odMapDomIds: string[]) {
  const mapOS = draft.mapOnlineServices;
  mapOS.mapStates = Object.fromEntries(
    odMapDomIds.map((id) => [id, INITIAL_MAP_ONLINE_SERVICE]),
  );
}
