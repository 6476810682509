/**
 * Liest den neuen Wert des Eingabefelds aus. Beinhaltet auch eine Spezialbe-
 * handlung für Incremental DOM.
 *
 * @param input "target" des Eingabe-Events
 * @returns Den neuen Wert des Eingabefelds
 */
export default function handleInputChangedEvent(
  input: EventTarget | HTMLInputElement | null,
): string {
  if (input === null) {
    return '';
  }
  const target = input as HTMLInputElement;
  const newValue = target.value;

  // Damit Incremental DOM den Wert des Input-Elements beim nächsten Rendern
  // der Anwendungen nicht erneut setzt (dies führt zu Flackern und
  // unbeabsichtigten Bewegungen des Cursors),
  // wird hier der neue Wert in den Cache von Incremental DOM geschrieben
  const incrementalDomData = target.__incrementalDOMData;
  if (incrementalDomData && !incrementalDomData.hasEmptyAttrsArr()) {
    const attrsArr = incrementalDomData.getAttrsArr(0);
    for (let i = 0; i < attrsArr.length; i += 2) {
      if (attrsArr[i] === 'value') {
        attrsArr[i + 1] = newValue;
        break;
      }
    }
  }
  return newValue;
}
