import type { InPageNavEntry } from '../../action/common/ScreenData';
import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { a, i, li, ul } from '../../render/html';

export function renderInPageNavigation(
  navData: InPageNavEntry[],
): RenderResultWithSsr | undefined {
  if (navData.length) {
    return ul(
      { class: 'navbar-nav d-none d-lg-flex' },
      navData.map((nav) =>
        li(
          { class: 'nav-item' },
          a(
            {
              class: 'nav-link d-flex align-items-center',
              href: `#${nav.id}`,
            },
            [
              nav.iconClass
                ? i({ class: `bi me-2 ${nav.iconClass}` })
                : undefined,
              nav.title,
            ],
          ),
        ),
      ),
    );
  } else {
    return undefined;
  }
}
