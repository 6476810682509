import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { h1, section } from '../../render/html';
import type { StartpageSearchParams } from '../blocks/startseite';
import { search } from '../blocks/startseite';
import './startseite.css';
import { selectParamsForCitySearch } from '../read/selectParamsForCitySearch';
import { selectParamsForSearch } from '../read/selectParamsForSearch';
import type { SimpleStore } from '../../state/SimpleStore';

export function startseite(
  simpleStore: SimpleStore,
  title: string,
): Partial<Record<string, RenderResultWithSsr>> {
  const state = simpleStore.getState();
  const params: StartpageSearchParams = {
    title,
    search: selectParamsForSearch(state),
    citySearch: selectParamsForCitySearch(state),
  };
  return {
    '.vwp-search-section': renderStartseite(simpleStore, params),
  };
}

export function renderStartseite(
  simpleStoreForHandlers: SimpleStore,
  params: StartpageSearchParams,
) {
  return section(
    {
      'aria-label': 'Suche',
      class:
        'vwp-search-section pt-3 pb-2 py-3 px-3 bg-opacity-75 bg-primary-brightest',
      role: 'search',
    },
    [
      params.title ? h1({ class: 'h3 mb-3' }, params.title) : undefined,
      search(simpleStoreForHandlers, params),
    ],
  );
}
