import { getScreenRequest, hasPatch } from '../lib/htmlCache';
import { UnreachableCaseError } from '../util/UnreachableCaseError';
import { Screen } from '../view';
import { loadCityFromOrtId } from './search/citySearch';
import { searchIfNecessary } from './search/search';
import { loadLeistungIfNotLoading } from './detail/loadLeistungIfNotLoading';
import { loadOrgIfNotLoading } from './detail/loadOrgIfNotLoading';
import { loadBereichsseiteIfNotLoading } from './filter/loadBereichsseiteIfNotLoading';
import { loadSublagenseiteIfNotLoading } from './filter/loadSublagenseiteIfNotLoading';
import { loadStartseiteIfNotLoading } from './detail/loadStartseiteIfNotLoading';
import { loadLagenseiteIfNotLoading } from './filter/loadLagenseiteIfNotLoading';
import { loadInformationIfNotLoading } from './detail/loadInformationIfNotLoading';
import type { SimpleStore } from '../state/SimpleStore';
import { loadMapDataIfMissing } from '../map_online_services/loadMapDataIfMissing';

export function loadDataIfNecessary(simpleStore: SimpleStore): void {
  const state = simpleStore.getState();
  loadCityFromOrtId(simpleStore);
  loadMapDataIfMissing(simpleStore);

  const screenRequest = getScreenRequest(state);

  if (
    screenRequest.screen === Screen.OrgSuche ||
    screenRequest.screen === Screen.Suche
  ) {
    searchIfNecessary(simpleStore, hasPatch(state, screenRequest));
  } else if (!hasPatch(state, screenRequest)) {
    switch (screenRequest.screen) {
      case Screen.Information: {
        loadInformationIfNotLoading(simpleStore, screenRequest.forRequest);
        break;
      }
      case Screen.Leistung: {
        loadLeistungIfNotLoading(simpleStore, screenRequest.forRequest);
        break;
      }
      case Screen.Org: {
        loadOrgIfNotLoading(simpleStore, screenRequest.forRequest);
        break;
      }
      case Screen.Bereich:
        loadBereichsseiteIfNotLoading(simpleStore, {
          ...screenRequest.forRequest,
          regschl: state.citySearch.selectedOrt.id,
          get_pv_lagen: !state.pvLagen.pvLagenByCode,
          count_pv_lagen:
            !state.pvLagen.countsByRegschlAndCode[
              state.citySearch.selectedOrt.id
            ],
        });
        break;
      case Screen.Lage:
        loadLagenseiteIfNotLoading(simpleStore, {
          ...screenRequest.forRequest,
          regschl: state.citySearch.selectedOrt.id,
          get_pv_lagen: !state.pvLagen.pvLagenByCode,
          count_pv_lagen:
            !state.pvLagen.countsByRegschlAndCode[
              state.citySearch.selectedOrt.id
            ],
        });
        break;
      case Screen.Sublage:
        loadSublagenseiteIfNotLoading(simpleStore, {
          ...screenRequest.forRequest,
          regschl: state.citySearch.selectedOrt.id,
          get_pv_lagen: !state.pvLagen.pvLagenByCode,
          count_pv_lagen:
            !state.pvLagen.countsByRegschlAndCode[
              state.citySearch.selectedOrt.id
            ],
        });
        break;
      case Screen.Startseite: {
        loadStartseiteIfNotLoading(simpleStore);
        break;
      }
      default:
        throw new UnreachableCaseError(screenRequest);
    }
  }
}
