import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { circle, span, svg } from '../../render/html';
import './loading.css';

const SIZE = 44;
const thickness = 3.6;

/** Einzelner Lade-Indikator */
export function loadingIndicator(className?: string): RenderResultWithSsr {
  return loading(className ?? '', SIZE);
}

/** Einzelner Lade-Indikator für die Inline-Nutzung (ist kleiner) */
export function loadingIndicatorInline(
  className?: string,
): RenderResultWithSsr {
  return loading(className ?? '', SIZE / 2);
}

function loading(className: string, size: number) {
  return span(
    {
      class: `loading-div loading-div__${size} ${className}`,
      role: 'progressbar',
    },
    svg(
      {
        class: 'loading-svg',
        viewBox: `${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`,
      },
      circle({
        class: 'loading-svg-circle',
        style: '',
        cx: SIZE,
        cy: SIZE,
        r: (SIZE - thickness) / 2,
        fill: 'none',
        'stroke-width': thickness,
      }),
    ),
  );
}
