import type { SimpleStore } from '../../state/SimpleStore';
import type { AdditionalData } from '../../render/cached-incremental-dom';
import {
  PV_LAGEN_COUNTS_LOADED,
  PV_LAGEN_LOADED,
} from '../../reducer/pvLagenEvents';

export function handleAdditionalDataLoaded(
  simpleStore: SimpleStore,
  regschl: string,
  additionalData: AdditionalData,
) {
  if (additionalData.pvLagenByCode) {
    simpleStore.dispatch(PV_LAGEN_LOADED(additionalData.pvLagenByCode));
  }
  if (additionalData.pvLagenCounts) {
    simpleStore.dispatch(
      PV_LAGEN_COUNTS_LOADED({
        regschl,
        counts: additionalData.pvLagenCounts,
      }),
    );
  }
}
