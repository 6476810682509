import { TypeFilter } from '../../constants';
import { FILTER_TYPE_CHANGED } from '../../reducer/filterEvents';
import { abortSearches } from '../search/searchCommon';
import { loadDataIfNecessary } from '../loadDataIfNecessary';
import type { SimpleStore } from '../../state/SimpleStore';

export function handleTypeChanged(
  simpleStore: SimpleStore,
  boxes: { id: string; label: string; value: TypeFilter }[],
  inputId: string,
): void {
  const target = document.getElementById(inputId);
  const checked = target ? (target as HTMLInputElement).checked : false;
  const typeStates = boxes.map(({ id, value }) => ({
    id,
    value,
    state: id === inputId ? checked : false,
  }));
  const types = typeStates
    .filter((typ) => typ.state)
    .map((typ) => typ.value)
    .sort();
  abortSearches(simpleStore);
  simpleStore.dispatch(FILTER_TYPE_CHANGED(types));
  loadDataIfNecessary(simpleStore);
}
