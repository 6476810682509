import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { section } from '../../render/html';
import { cityField, createDefaultCityHandlers } from '../blocks/citySearch';
import './leistung.css';
import { renderInPageNavigation } from '../blocks/inPageNavigation';
import { selectParamsForCitySearch } from '../read/selectParamsForCitySearch';
import type { SimpleStore } from '../../state/SimpleStore';
import type { ScreenDataLeistung } from '../../action/common/ScreenData';
import type { State } from '../../state/createInitialState';
import { contentLeft, SELECTOR_CONTENT_LEFT } from '../frame/contentLeft';

export function leistungsseite(
  simpleStore: SimpleStore,
  data: ScreenDataLeistung | undefined,
): Partial<Record<string, RenderResultWithSsr>> {
  const state = simpleStore.getState();
  const {
    dropdownState,
    inputValue,
    loading,
    ariaDescription,
    placeholder,
    showFeedbackHint,
    suggestions,
  } = selectParamsForCitySearch(state);
  return {
    '.city-section': section(
      { class: 'city-section' },
      cityField({
        dropdownState,
        inputValue,
        loading,
        handlers: createDefaultCityHandlers({
          simpleStoreForHandlers: simpleStore,
        }),
        ariaDescription,
        placeholder,
        showFeedbackHint,
        suggestions,
      }),
    ),
    [SELECTOR_CONTENT_LEFT]: contentLeft(
      data ? leistungInPageNavigation(simpleStore, data) : undefined,
    ),
  };
}

function leistungInPageNavigation(
  simpleStore: SimpleStore,
  data: ScreenDataLeistung,
): RenderResultWithSsr | undefined {
  const state = simpleStore.getState();
  const navData = prepareDataForNav(state, data);
  return renderInPageNavigation(navData);
}

function prepareDataForNav(state: State, data: ScreenDataLeistung) {
  return data.leistung.inpageNav;
}
