/* eslint-disable no-param-reassign */
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { SELECTED_ORT_HESSEN } from '../../constants';
import type { State } from '../../state/createInitialState';
import {
  removeAbortRequest,
  storeAbortRequest,
} from '../../state/createInitialState';
import { Screen } from '../../view';
import {
  INFORMATION_ERROR,
  INFORMATION_FINISHED,
  INFORMATION_STARTED,
  LEISTUNG_ERROR,
  LEISTUNG_FINISHED,
  LEISTUNG_NOT_FOUND,
  LEISTUNG_SHOWN,
  LEISTUNG_STARTED,
  ORG_ERROR,
  ORG_FINISHED,
  ORG_NOT_FOUND,
  ORG_SHOWN,
  ORG_STARTED,
  STARTSEITE_ERROR,
  STARTSEITE_FINISHED,
  STARTSEITE_SHOWN,
  STARTSEITE_STARTED,
} from './detailEvents';
import { reduceForNewPatch } from '../../reducer/reduceForNewPatch';
import { reduceForNewNotFoundPatch } from '../../reducer/reduceForNewNotFoundPatch';
import { reduceForScreenChange } from '../../reducer/reduceForScreenChange';

export function createDetailReducer(builder: ActionReducerMapBuilder<State>) {
  builder.addCase(INFORMATION_FINISHED, (state, action) => {
    const { forRequest, patch } = action.payload;
    removeAbortRequest(state, 'information');
    reduceForNewPatch(state, {
      patch,
      screenRequest: { forRequest, screen: Screen.Information },
    });
  });
  builder.addCase(INFORMATION_ERROR, (state, action) => {
    removeAbortRequest(state, 'information');
    state.error.information = action.payload;
  });
  builder.addCase(INFORMATION_STARTED, (state, action) => {
    const abortRequest = action.payload;
    storeAbortRequest(state, 'information', abortRequest);
  });
  builder.addCase(LEISTUNG_ERROR, (state, action) => {
    const error = action.payload;
    removeAbortRequest(state, 'leistung');
    state.error.leistung = error;
  });
  builder.addCase(LEISTUNG_FINISHED, (state, action) => {
    const { forRequest, patch } = action.payload;
    removeAbortRequest(state, 'leistung');
    state.leistung.request = { leistung_id: forRequest.leistung_id };
    reduceForNewPatch(state, {
      patch,
      screenRequest: { forRequest, screen: Screen.Leistung },
    });
  });
  builder.addCase(LEISTUNG_NOT_FOUND, (state, action) => {
    const forRequest = action.payload;
    removeAbortRequest(state, 'leistung');
    reduceForNewNotFoundPatch(
      state,
      {
        screen: Screen.Leistung,
        forRequest,
      },
      {
        screen: Screen.Leistung,
        leistung: {
          firstLeika: undefined,
          id: undefined,
          inpageNav: [],
          regschl: undefined,
        },
      },
    );
  });
  builder.addCase(LEISTUNG_SHOWN, (state, action) => {
    const { leistung_id: leistungId, regschl } = action.payload;
    state.screen = Screen.Leistung;
    reduceForScreenChange(state);
    state.leistung.request = { leistung_id: leistungId };
    if (state.citySearch.selectedOrt.id !== regschl) {
      state.citySearch.selectedOrt = regschl
        ? {
            loadNeeded: true,
            id: regschl,
            ort: undefined,
            verband: undefined,
            kreis: undefined,
            bezirk: undefined,
            land: '',
            isFindable: false,
          }
        : SELECTED_ORT_HESSEN;
    }
  });
  builder.addCase(LEISTUNG_STARTED, (state, action) => {
    const abortRequest = action.payload;
    storeAbortRequest(state, 'leistung', abortRequest);
    state.error.leistung = undefined;
  });
  builder.addCase(ORG_ERROR, (state, action) => {
    removeAbortRequest(state, 'organisationseinheit');
    state.error.organisationseinheit = action.payload;
  });
  builder.addCase(ORG_FINISHED, (state, action) => {
    const { patch, forRequest } = action.payload;
    removeAbortRequest(state, 'organisationseinheit');
    reduceForNewPatch(state, {
      patch,
      screenRequest: { screen: Screen.Org, forRequest },
    });
  });
  builder.addCase(ORG_NOT_FOUND, (state, action) => {
    const forRequest = action.payload;
    removeAbortRequest(state, 'organisationseinheit');
    reduceForNewNotFoundPatch(
      state,
      { screen: Screen.Org, forRequest },
      { screen: Screen.Org, org: { inpageNav: [] } },
    );
  });
  builder.addCase(ORG_SHOWN, (state, action) => {
    state.org.request = action.payload;
    state.screen = Screen.Org;
    reduceForScreenChange(state);
  });
  builder.addCase(ORG_STARTED, (state, action) => {
    const abortRequest = action.payload;
    storeAbortRequest(state, 'organisationseinheit', abortRequest);
    state.error.organisationseinheit = undefined;
  });

  builder.addCase(STARTSEITE_FINISHED, (state, action) => {
    const patch = action.payload;
    reduceForNewPatch(state, {
      patch,
      screenRequest: { screen: Screen.Startseite },
    });
  });
  builder.addCase(STARTSEITE_ERROR, (state, action) => {
    state.error.startseite = action.payload;
    removeAbortRequest(state, 'startseite');
  });
  builder.addCase(STARTSEITE_SHOWN, (state) => {
    state.screen = Screen.Startseite;
    reduceForScreenChange(state);
  });
  builder.addCase(STARTSEITE_STARTED, (state, action) => {
    const abortRequest = action.payload;
    storeAbortRequest(state, 'startseite', {
      abortRequest,
      forRequest: undefined,
    });
  });
}
