import {
  APP_PATH_BEREICH_BUERGER,
  APP_PATH_BEREICH_UNTERNEHMEN,
} from '../constants';
import { LAGE_TEXT_BUERGER, LAGE_TEXT_UNTERNEHMEN } from '../texts';
import { UnreachableCaseError } from './UnreachableCaseError';
import { PvLagenByCode } from '../state/createInitialState';

export const BEREICH_LENGTH = 1;
export const LAGE_LENGTH = 3;
export const PV_LAGE_LENGTH = 7;
const PV_LAGE_BUERGER_PREFIX = '1';
const PV_LAGE_UNTERNEHMEN_PREFIX = '2';
export const PV_LAGE_BUERGER = PV_LAGE_BUERGER_PREFIX.padEnd(
  PV_LAGE_LENGTH,
  '0',
);
export const PV_LAGE_UNTERNEHMEN = PV_LAGE_UNTERNEHMEN_PREFIX.padEnd(
  PV_LAGE_LENGTH,
  '0',
);

export const PV_LAGE_SONSTIGE_SUFFIX = 'Sonstige';

export enum PvLagenLevel {
  BEREICH = 'BEREICH',
  LAGE = 'LAGE',
  SUBLAGE = 'SUBLAGE',
}

export function getPvLage(
  pvLagen: PvLagenByCode | undefined,
  pvLagenCode: string,
): { name: string; iconSvgSafeHtml: string | undefined } | undefined {
  if (isSonstigePvLage(pvLagenCode)) {
    return { name: PV_LAGE_SONSTIGE_SUFFIX, iconSvgSafeHtml: undefined };
  } else {
    return pvLagen?.[pvLagenCode];
  }
}

function isSonstigePvLage(pvLagenCode: string): boolean {
  return pvLagenCode.endsWith(PV_LAGE_SONSTIGE_SUFFIX);
}

export function getPvLagenLevel(pvLage: string): PvLagenLevel {
  let significantNumbers;
  if (pvLage.endsWith(PV_LAGE_SONSTIGE_SUFFIX)) {
    // +1, da Sonstige eine Ebene unterhalb des Präfixes liegt
    significantNumbers = pvLage.length - PV_LAGE_SONSTIGE_SUFFIX.length + 1;
  } else {
    significantNumbers = pvLage.search(/0+$/);
  }
  if (significantNumbers < 0 || significantNumbers > LAGE_LENGTH) {
    return PvLagenLevel.SUBLAGE;
  } else if (significantNumbers > BEREICH_LENGTH) {
    return PvLagenLevel.LAGE;
  } else if (significantNumbers > 0) {
    return PvLagenLevel.BEREICH;
  } else {
    throw new Error(`invalid pv-lagen-code: ${pvLage}`);
  }
}

export function getSonstigePvLageAsChild(parentPvLage: undefined | string) {
  if (parentPvLage === undefined) {
    return PV_LAGE_SONSTIGE_SUFFIX;
  } else {
    const level = getPvLagenLevel(parentPvLage);
    switch (level) {
      case PvLagenLevel.BEREICH:
        return (
          parentPvLage.substring(0, BEREICH_LENGTH) + PV_LAGE_SONSTIGE_SUFFIX
        );
      case PvLagenLevel.LAGE:
        return parentPvLage.substring(0, LAGE_LENGTH) + PV_LAGE_SONSTIGE_SUFFIX;
      case PvLagenLevel.SUBLAGE:
        return parentPvLage + PV_LAGE_SONSTIGE_SUFFIX;
      default:
        throw new UnreachableCaseError(level);
    }
  }
}

export function getPvLagenParentCode(pvLage: string): string | undefined {
  const level = getPvLagenLevel(pvLage);
  switch (level) {
    case PvLagenLevel.BEREICH:
      return undefined;
    case PvLagenLevel.LAGE:
      return getBereichCode(pvLage);
    case PvLagenLevel.SUBLAGE:
      return getLageCode(pvLage);
    default:
      throw new UnreachableCaseError(level);
  }
}

export function getAppPathBereich(pvLage: string | undefined): string {
  // TODO: wenn "Sonstiges" unten gelöst wird, hier auch "!pvLage ||" entfernen
  if (!pvLage || pvLage.startsWith(PV_LAGE_BUERGER_PREFIX)) {
    return APP_PATH_BEREICH_BUERGER();
  } else if (pvLage.startsWith(PV_LAGE_UNTERNEHMEN_PREFIX)) {
    return APP_PATH_BEREICH_UNTERNEHMEN();
  } else {
    // TODO: Übergreifenden App-Path implementieren
    throw new Error('Unimplemented');
  }
}

export function getBereichCode(pvLage: string | undefined): string | undefined {
  return (
    (pvLage &&
      pvLage.substring(0, BEREICH_LENGTH).padEnd(PV_LAGE_LENGTH, '0')) ||
    undefined
  );
}

export function getLageCode(pvLage: string | undefined): string | undefined {
  return (
    (pvLage && pvLage.substring(0, LAGE_LENGTH).padEnd(PV_LAGE_LENGTH, '0')) ||
    undefined
  );
}

export function getBereiche() {
  return [
    { code: PV_LAGE_BUERGER, name: LAGE_TEXT_BUERGER },
    { code: PV_LAGE_UNTERNEHMEN, name: LAGE_TEXT_UNTERNEHMEN },
  ].sort((a, b) => a.name.localeCompare(b.name));
}
