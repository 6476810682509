import { DOM_ID_SECTION_SEITENINHALT } from '../../constants';
import type { RenderParam, RenderResultWithSsr } from '../../lib/renderTypes';
import { section } from '../../render/html';

export function sectionSeiteninhalt(
  {
    class: className,
    ariaLabel = 'Seiteninhalt',
    key = DOM_ID_SECTION_SEITENINHALT,
  }: { class: string; ariaLabel?: string; key?: string },
  content: RenderParam,
): RenderResultWithSsr {
  return section(
    {
      key,
      tabindex: -1,
      id: DOM_ID_SECTION_SEITENINHALT,
      'aria-label': ariaLabel,
      class: className,
    },
    content,
  );
}
