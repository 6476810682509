/*
 * Speichert Einstellungen im aktuellen Browser-Context und verteilt Änderungen dieser Einstellungen an andere Browser-Tabs.
 */

type ValueMessage = {
  nfkPrivacyAccepted?: true;
  chatbotPrivacyAccepted?: true;
};

// key für die NFK_PRIVACY_ACCEPTED-Einstellung
const KEY_NFK_PRIVACY_ACCEPTED = 'hzd_vwp_nfk_privacy_accepted';
// key für die CHATBOT_PRIVACY_ACCEPTED-Einstellung
const KEY_CHATBOT_PRIVACY_ACCEPTED = 'hzd_vwp_chatbot_privacy_accepted';
/** ID des Channels, über den die Daten ausgetauscht werden */
const CHANNEL_ID = 'hzd_og_value_changed';
const channel: BroadcastChannel | undefined =
  typeof BroadcastChannel !== 'undefined'
    ? new BroadcastChannel(CHANNEL_ID)
    : undefined;

// aktuelle Werte aus session-cookie lesen, damit bei Navigation oder Reload der Seite der Werte beibehalten wird
let chatbotPrivacyAccepted = false;
let nfkPrivacyAccepted = false;
function init() {
  const docCookies = document.cookie;
  if (docCookies) {
    const cookies = document.cookie.split(';');
    cookies.forEach((cookie) => {
      const [key, value] = cookie.trim().split('=');
      if (key === KEY_CHATBOT_PRIVACY_ACCEPTED && value === String(true)) {
        chatbotPrivacyAccepted = true;
      } else if (key === KEY_NFK_PRIVACY_ACCEPTED && value === String(true)) {
        nfkPrivacyAccepted = true;
      }
    });
  }
}
init();

/** Ist Einwilligung für Chatbot erteilt? */
export function getChatbotPrivacyAccepted(): boolean {
  return chatbotPrivacyAccepted;
}
/** Ist Einwilligung für NFK erteilt? */
export function getNfkPrivacyAccepted(): boolean {
  return nfkPrivacyAccepted;
}

function broadcast(data: ValueMessage) {
  channel?.postMessage(data);
}

/** Setze, dass Einwilligung für Chatbot erteilt wurde */
export function setChatbotPrivacyAccepted() {
  chatbotPrivacyAccepted = true;
  document.cookie = `${KEY_CHATBOT_PRIVACY_ACCEPTED}=${String(
    true,
  )};SameSite=Strict`;
  broadcast({ chatbotPrivacyAccepted: true });
}
/** Setze, dass Einwilligung für NFK erteilt wurde */
export function setNfkPrivacyAccepted() {
  nfkPrivacyAccepted = true;
  document.cookie = `${KEY_NFK_PRIVACY_ACCEPTED}=${String(
    true,
  )};SameSite=Strict`;
  broadcast({ nfkPrivacyAccepted: true });
}

const chatbotCallbacks: (() => void)[] = [];
const nfkCallbacks: (() => void)[] = [];
/**
 * callback wird möglicherweise (d.h. nicht garantiert) aufgerufen, wenn in die Chatbot-Datenschutzhinweise eingewilligt wurde
 */
export function onChatbotPrivacyAccepted(callback: () => void) {
  chatbotCallbacks.push(callback);
}
/**
 * callback wird möglicherweise (d.h. nicht garantiert) aufgerufen, wenn in die NFK-Datenschutzhinweise eingewilligt wurde
 */
export function onNfkPrivacyAccepted(callback: () => void) {
  nfkCallbacks.push(callback);
}

/** Empfange Broadcast-Nachrichten und speichere die mitgeteilten Werte im lokalen Browser-Context */
function handleBroadcastEvent(event: MessageEvent<ValueMessage>) {
  const { data } = event;
  if (data.chatbotPrivacyAccepted) {
    chatbotPrivacyAccepted = true;
    document.cookie = `${KEY_CHATBOT_PRIVACY_ACCEPTED}=${String(
      true,
    )};SameSite=Strict`;
    chatbotCallbacks.forEach((cb) => {
      cb();
    });
  }
  if (data.nfkPrivacyAccepted) {
    nfkPrivacyAccepted = true;
    document.cookie = `${KEY_NFK_PRIVACY_ACCEPTED}=${String(
      true,
    )};SameSite=Strict`;
    nfkCallbacks.forEach((cb) => {
      cb();
    });
  }
}
channel?.addEventListener('message', handleBroadcastEvent);
