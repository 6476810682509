/* eslint-disable no-param-reassign */
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { State } from '../state/createInitialState';
import {
  removeAbortRequest,
  storeAbortRequest,
} from '../state/createInitialState';
import {
  FILTER_PVLAGE_CHANGED,
  FILTER_REGSCHL_FILTER_CHANGED,
  FILTER_TYPE_CHANGED,
  LOAD_BEREICHSSEITE_ERROR,
  LOAD_BEREICHSSEITE_FINISHED,
  LOAD_BEREICHSSEITE_STARTED,
  LOAD_LAGENSEITE_ERROR,
  LOAD_LAGENSEITE_FINISHED,
  LOAD_LAGENSEITE_STARTED,
  LOAD_SUBLAGENSEITE_ERROR,
  LOAD_SUBLAGENSEITE_FINISHED,
  LOAD_SUBLAGENSEITE_STARTED,
} from './filterEvents';
import { reduceForNewPatch } from './reduceForNewPatch';

export function createFilterReducer(builder: ActionReducerMapBuilder<State>) {
  builder.addCase(FILTER_PVLAGE_CHANGED, (state, action) => {
    state.search.request.filter.pvLage = action.payload;
  });
  builder.addCase(FILTER_REGSCHL_FILTER_CHANGED, (state, action) => {
    const { regschlFilterTypes } = action.payload;
    state.search.request.regschlFilterTypes =
      regschlFilterTypes === undefined
        ? undefined
        : [...regschlFilterTypes].sort();
  });
  builder.addCase(FILTER_TYPE_CHANGED, (state, action) => {
    state.search.request.filter.type = action.payload;
  });
  builder.addCase(LOAD_BEREICHSSEITE_ERROR, (state, action) => {
    state.error.bereichsseite = action.payload;
    removeAbortRequest(state, 'bereichsseite');
  });
  builder.addCase(LOAD_BEREICHSSEITE_FINISHED, (state, action) => {
    const { patch, screenRequest } = action.payload;
    removeAbortRequest(state, 'bereichsseite');
    reduceForNewPatch(state, {
      patch,
      screenRequest,
    });
  });
  builder.addCase(LOAD_BEREICHSSEITE_STARTED, (state, action) => {
    const abortRequest = action.payload;
    storeAbortRequest(state, 'bereichsseite', abortRequest);
    state.error.bereichsseite = undefined;
  });
  builder.addCase(LOAD_LAGENSEITE_ERROR, (state, action) => {
    state.error.lagenseite = action.payload;
    removeAbortRequest(state, 'lagenseite');
  });
  builder.addCase(LOAD_LAGENSEITE_FINISHED, (state, action) => {
    const { patch, screenRequest } = action.payload;
    removeAbortRequest(state, 'lagenseite');
    reduceForNewPatch(state, {
      patch,
      screenRequest,
    });
  });
  builder.addCase(LOAD_LAGENSEITE_STARTED, (state, action) => {
    const abortRequest = action.payload;
    storeAbortRequest(state, 'lagenseite', abortRequest);
    state.error.lagenseite = undefined;
  });
  builder.addCase(LOAD_SUBLAGENSEITE_ERROR, (state, action) => {
    state.error.sublagenseite = action.payload;
    removeAbortRequest(state, 'sublagenseite');
  });
  builder.addCase(LOAD_SUBLAGENSEITE_FINISHED, (state, action) => {
    const { patch, screenRequest } = action.payload;
    removeAbortRequest(state, 'sublagenseite');
    reduceForNewPatch(state, {
      patch,
      screenRequest,
    });
  });
  builder.addCase(LOAD_SUBLAGENSEITE_STARTED, (state, action) => {
    const abortRequest = action.payload;
    storeAbortRequest(state, 'sublagenseite', abortRequest);
    state.error.sublagenseite = undefined;
  });
}
