import { VALIDATION_ERRORS_SHOWN } from '../../reducer/events';
import { isValidForSearchingResult } from '../../validation/validateInput';
import { trimSearchTerm } from '../util/trimSearchTerm';
import { loadDataIfNecessary } from '../loadDataIfNecessary';
import type { SimpleStore } from '../../state/SimpleStore';

/**
 * Sucht nach dem Ergebnis, falls die Eingaben
 * valide sind.
 * Aktiviert die Anzeige von Validierungsfehlern.
 *
 * @param simpleStore Zugriff auf dispatch und getState
 */
export function searchFromInput(simpleStore: SimpleStore): void {
  trimSearchTerm(simpleStore);
  const state = simpleStore.getState();
  const { request } = state.search;

  if (
    isValidForSearchingResult({
      searchTerm: request.searchTerm,
    })
  ) {
    // Alle Eingabefelder sind gültig
    loadDataIfNecessary(simpleStore);
  } else {
    // some input is invalid -> show validation errors
    simpleStore.dispatch(VALIDATION_ERRORS_SHOWN());
  }
}
