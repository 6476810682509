import { Dispatch } from 'redux';
import { Flags } from '../../../history/flags';
import { ChatbotScriptSrc } from '../../../constants';
import {
  CHATBOT_LOADING_FAILED,
  CHATBOT_LOADING_FINISHED,
  CHATBOT_LOADING_STARTED,
} from '../../../reducer/chatbotEvents';

const ID_CHATBOT_SCRIPT = 'hzd_vwp_chatbot_script';
export async function addChatbotHeaderIfMissing(
  dispatch: Dispatch,
  flags: Flags,
  chatbotScriptSrc: ChatbotScriptSrc,
): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    // hier nur id in Kleinbuchstaben nutzen, Großbuchstaben werden nicht unterstützt
    const chatbotScriptTagId = chatbotScriptSrc.id ?? ID_CHATBOT_SCRIPT;
    if (!document.getElementById(chatbotScriptTagId)) {
      dispatch(CHATBOT_LOADING_STARTED());
      const script = document.createElement('script');
      script.setAttribute('id', chatbotScriptTagId);
      Object.keys(chatbotScriptSrc).forEach((key) => {
        const value = chatbotScriptSrc[key];
        if (value !== undefined) {
          if (key.toLowerCase() === 'src') {
            let scriptSrc = value;
            if (flags.fail_request && scriptSrc.includes(flags.fail_request)) {
              scriptSrc = 'https://url.invalid/invalid.js';
            }
            script.setAttribute(key, scriptSrc);
          } else if (key.toLowerCase() === 'id') {
            // ignore, was already set
          } else {
            script.setAttribute(key, value);
          }
        }
      });
      script.onload = () => {
        dispatch(CHATBOT_LOADING_FINISHED());
        resolve(true);
      };
      script.onerror = (event) => {
        console.error('Chatbot script could not be loaded', event);
        document.head.removeChild(script);
        dispatch(CHATBOT_LOADING_FAILED());
        resolve(false);
      };
      document.head.appendChild(script);
    } else {
      resolve(true);
    }
  });
}
