import { polyfillRegExp } from '../../polyfills';
import { CITY_SELECTED_FROM_ASK } from './searchEvents';
import escapeForRegexp from '../../view/util/escapeForRegexp';
import type { GebietResult } from './searchTypes';
import { loadDataIfNecessary } from '../loadDataIfNecessary';
import type { SimpleStore } from '../../state/SimpleStore';

const regexpStripLand = / (.*)$/;

export function adaptSearchTerm(
  simpleStore: SimpleStore,
  selectedOrt: GebietResult,
): string {
  const currentSearchTerm = simpleStore.getState().search.request.searchTerm;
  if (selectedOrt.ort) {
    const ortName = selectedOrt.ort.replace(regexpStripLand, '').trim();
    const regexpStripOrt = polyfillRegExp(
      `(^| *)[^ \\p{L}\\p{N}]*${escapeForRegexp(
        ortName,
      )}[^ \\p{L}\\p{N}]*( *|$)`,
      'ui',
    );
    return currentSearchTerm.replace(regexpStripOrt, ' ').trim();
  } else {
    return currentSearchTerm;
  }
}

/**
 * Sucht nach Suchbegriff in bestimmtem Ort.
 *
 * @param simpleStore Zugriff auf dispatch und getState
 * @param event Browser-Event wird verwendet, um preventDefault() aufzurufen
 * @param selectedOrt Aus Rückfrage gewählter Ort
 */
export function searchFromAsk(
  simpleStore: SimpleStore,
  event: Event,
  selectedOrt: GebietResult,
): void {
  event.preventDefault();

  const searchTerm = adaptSearchTerm(simpleStore, selectedOrt);

  simpleStore.dispatch(CITY_SELECTED_FROM_ASK({ searchTerm, selectedOrt }));

  loadDataIfNecessary(simpleStore);
}
