import { createSelector } from 'reselect';
import { deepEquals } from '../../util/deepEquals';
import { requestWithoutTypes } from '../../util/requestWithoutTypes';
import {
  selectSearchData,
  selectSearchLoading,
  selectSelectedOrtId,
} from './selectors';
import { SearchResultType } from '../../action/search/searchTypes';
import { extractSearchRequest } from './extractSearchRequest';

export const extractTypFilterCounts = createSelector(
  extractSearchRequest,
  selectSearchData,
  selectSearchLoading,
  selectSelectedOrtId,
  (
    searchRequest,
    searchData,
    searchLoading,
    selectedOrtId,
  ): {
    counts: {
      leistungen?: number;
      orgeinheiten?: number;
      editorialPages?: number;
    };
    loading: boolean;
  } => {
    const { searchTerm } = searchRequest;
    const filtered = Boolean(searchTerm);
    if (filtered) {
      const counts =
        searchData.data?.type === SearchResultType.RESULT
          ? searchData.data.result.count
          : undefined;
      const dataFor = searchData.forRequest;
      const loading =
        searchLoading &&
        dataFor !== undefined &&
        !deepEquals(
          requestWithoutTypes({
            ...searchRequest,
            regschl: selectedOrtId,
          }),
          requestWithoutTypes(dataFor),
        );
      return { counts: counts ?? {}, loading };
    } else {
      return { counts: {}, loading: false };
    }
  },
);
