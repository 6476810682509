import { Dispatch } from 'redux';
import type { RenderResultWithSsr } from '../../../lib/renderTypes';
import type { ChatbotScriptSrc } from '../../../constants';
import type {
  ChatbotState,
  HtmlCacheEntryData,
} from '../../../state/createInitialState';
import { Flags } from '../../../history/flags';
import { renderChatbotButtonUnloaded } from './renderChatbotButtonUnloaded';
import { renderChatbotButtonLoaded } from './renderChatbotButtonLoaded';
import { addChatbotHeaderIfMissing } from './addChatbotHeaderIfMissing';
import { renderChatbotNotAvailable } from './renderChatbotNotAvailable';
import { renderChatbotAskPrivacyButton } from './renderChatbotAskPrivacyButton';

const SHADOW_SELECTOR = '!SHADOW';

function createHandleChatbotButtonClicked(
  dispatch: Dispatch,
  flags: Flags,
  chatbotScriptSrc: ChatbotScriptSrc,
  chatbotSelectors: string[],
) {
  return async function handleChatbotButtonClicked(this: HTMLElement) {
    const loadSuccess = await addChatbotHeaderIfMissing(
      dispatch,
      flags,
      chatbotScriptSrc,
    );
    if (loadSuccess) {
      if (chatbotSelectors.length) {
        const buttonElement = chatbotSelectors.reduce(
          (
            element: Document | Element | ShadowRoot | undefined | null,
            selector: string,
          ) => {
            if (selector === SHADOW_SELECTOR) {
              return element && 'shadowRoot' in element
                ? element.shadowRoot
                : undefined;
            } else {
              return element?.querySelector(selector);
            }
          },
          document,
        );
        if (
          buttonElement &&
          'click' in buttonElement &&
          typeof buttonElement.click === 'function'
        ) {
          buttonElement.click();
        }
      }
    } else {
      renderChatbotNotAvailable();
    }
  };
}

function checkPrivacySettingsAndRenderChatbotButton(
  dispatch: Dispatch,
  flags: Flags,
  chatbotScriptSrc: ChatbotScriptSrc,
  chatbotState: ChatbotState,
  chatbotSafeHtml: string,
  chatbotSelectors: string[],
): RenderResultWithSsr | undefined {
  if (chatbotState.privacyAccepted) {
    if (chatbotState.loaded) {
      return renderChatbotButtonLoaded(chatbotSafeHtml);
    } else {
      return renderChatbotButtonUnloaded(
        chatbotState,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        createHandleChatbotButtonClicked(
          dispatch,
          flags,
          chatbotScriptSrc,
          chatbotSelectors,
        ),
      );
    }
  } else {
    return renderChatbotAskPrivacyButton(dispatch);
  }
}

export function renderChatbot(
  dispatch: Dispatch,
  flags: Flags,
  cacheEntry: HtmlCacheEntryData,
  chatbotScriptSrc: ChatbotScriptSrc | undefined,
  chatbotState: ChatbotState,
  chatbotSafeHtml: string | undefined,
  chatbotSelectors: string[],
): RenderResultWithSsr | undefined {
  if (
    chatbotScriptSrc &&
    // chatbotSafeHtml &&
    cacheEntry.patch.pageElements.showChatbot
  ) {
    return checkPrivacySettingsAndRenderChatbotButton(
      dispatch,
      flags,
      chatbotScriptSrc,
      chatbotState,
      chatbotSafeHtml ?? '',
      chatbotSelectors,
    );
  } else {
    return undefined;
  }
}
