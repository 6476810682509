// based on https://raw.githubusercontent.com/rosston/incremental-hyperscript/8e8a36c62ff34e217c2fa9a3958ae1fcae28af64/src/incremental-hyperscript.js
// license: Apache 2.0, https://github.com/rosston/incremental-hyperscript/blob/8e8a36c62ff34e217c2fa9a3958ae1fcae28af64/LICENSE.txt

import {
  attr,
  elementClose,
  elementOpenEnd,
  elementOpenStart,
  skip,
  text,
} from 'incremental-dom';
import type {
  EventHandler,
  HyperscriptChild,
  HyperscriptElement,
  HyperscriptProperties,
  RenderResult,
} from './common-render-types';
import type { DomElement } from '../render_srv/renderHtml';
// eslint-disable-next-line @typescript-eslint/no-explicit-any

export const DANGEROUSLY_HTML_CONTENT = 'dangerouslyHtmlContent';
export const SKIP_CONTENT = 'SKIP_CONTENT';
const EVENT_FOCUSOUT = 'onfocusout';

function hasOwnProperty(anObject: object, prop: string) {
  return Object.prototype.hasOwnProperty.call(anObject, prop);
}

function renderChild<T>(child: HyperscriptElement<T>) {
  switch (typeof child) {
    case 'string':
      text(child);
      break;
    case 'function':
      child();
      break;
    case 'undefined':
      // do nothing on undefined
      break;
    default:
      console.error(
        `Unexpected type of child parameter: ${typeof child}`,
        child,
      );
      throw new Error(`Unexpected type of child parameter: ${typeof child}`);
  }
}

export default function h(
  tag: string,
  props: HyperscriptProperties | null,
  firstChild?: HyperscriptChild<DomElement>,
  ...children: HyperscriptElement<DomElement>[]
): RenderResult<DomElement> {
  const nonNullProps = props == null ? {} : props;
  let childrenArray: HyperscriptElement<DomElement>[];
  if (firstChild === undefined) {
    childrenArray = [];
  } else if (Array.isArray(firstChild)) {
    childrenArray = firstChild;
  } else {
    childrenArray = [firstChild, ...children];
  }
  switch (typeof tag) {
    case 'string':
      return function hRender() {
        elementOpenStart(tag, nonNullProps.key);

        let skipContent = false;
        let dangerouslyHtmlContent: undefined | string;
        let eventFocusOut: undefined | EventHandler<FocusEvent>;

        Object.keys(nonNullProps).forEach((propName) => {
          if (hasOwnProperty(nonNullProps, propName)) {
            if (propName === SKIP_CONTENT) {
              skipContent = true;
            } else if (propName === DANGEROUSLY_HTML_CONTENT) {
              dangerouslyHtmlContent = nonNullProps[propName];
            } else if (propName === EVENT_FOCUSOUT) {
              // the property onfocusout isn't triggered, so we must use addEventListener
              eventFocusOut = nonNullProps[propName];
            } else {
              attr(propName, nonNullProps[propName]);
            }
          }
        });

        const domElement = elementOpenEnd();

        // false positive?
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (skipContent) {
          skip();
        } else if (dangerouslyHtmlContent) {
          if (domElement.__cachedInnerHtml !== dangerouslyHtmlContent) {
            domElement.__cachedInnerHtml = dangerouslyHtmlContent;
            domElement.innerHTML = dangerouslyHtmlContent;
          }
          skip();
        } else {
          domElement.__cachedInnerHtml = undefined;
        }

        if (eventFocusOut === undefined) {
          if (domElement.__cachedFocusOut !== undefined) {
            const toRemove = domElement.__cachedFocusOut;
            domElement.__cachedFocusOut = undefined;
            domElement.removeEventListener('focusout', toRemove);
          }
        } else if (domElement.__cachedFocusOut !== eventFocusOut) {
          if (domElement.__cachedFocusOut !== undefined) {
            domElement.removeEventListener(
              'focusout',
              domElement.__cachedFocusOut,
            );
          }
          domElement.__cachedFocusOut = eventFocusOut;
          domElement.addEventListener('focusout', eventFocusOut);
        }

        childrenArray.forEach(renderChild);

        elementClose(tag);

        // Um den Typescript-Check zufriedenzustellen --> Ändern, wenn jemand eine bessere Idee hat
        return undefined;
      };
    /*
    case 'function':
      return tag(props, childrenArray);
       */
    default:
      throw new Error(`Unexpected type of tag parameter: ${typeof tag}`);
  }
}

h.fragment = function hFragment(
  firstChild?: HyperscriptChild<DomElement>,
  ...children: HyperscriptElement<DomElement>[]
): RenderResult<DomElement> {
  // console.log('fragment', props, children);
  let childrenArray: HyperscriptElement<DomElement>[];
  if (firstChild === undefined) {
    childrenArray = [];
  } else if (Array.isArray(firstChild)) {
    childrenArray = firstChild;
  } else {
    childrenArray = [firstChild, ...children];
  }
  return () => {
    childrenArray.forEach(renderChild);
    // Um den Typescript-Check zufriedenzustellen --> Ändern, wenn jemand eine bessere Idee hat
    return undefined;
  };
};
