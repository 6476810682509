/* eslint-disable no-param-reassign */
import type { Draft } from 'immer';
import { getScreenRequest, putPatchNotFound1 } from '../lib/htmlCache';
import type { State } from '../state/createInitialState';
import type { ScreenRequest } from '../action/common/commonRequestTypes';
import { deepEquals } from '../util/deepEquals';
import { resetOdMapState } from './resetOdMapState';
import type { ScreenData } from '../action/common/ScreenData';
import { Screen } from '../view';

export function reduceForNewNotFoundPatch(
  draft: Draft<State>,
  screenRequest: ScreenRequest,
  screenData: ScreenData,
) {
  putPatchNotFound1(draft, {
    patch: {
      data:
        // Workaround für falsche TypeScript-Erkennung
        screenData as { screen: Screen.Startseite },
      pageElements: {
        odMapDomIds: [],
        showChatbot: false,
        drupalTitle: undefined,
        asideHtml: undefined,
        breadcrumbHtml: undefined,
        contentHtml: undefined,
        htmlHead: {},
        navigationHtml: undefined,
      },
    },
    screenRequest: {
      screen:
        // Workaround für falsche TypeScript-Erkennung
        screenData.screen as Screen.Startseite,
    },
  });
  if (deepEquals(getScreenRequest(draft), screenRequest)) {
    resetOdMapState(draft, []);
  }
}
