import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { div } from '../../render/html';
import { loadingIndicator } from './loading';
import './loadingOverlay.css';

/** Lade-Overlay */
export default function loadingOverlay(
  className?: string,
): RenderResultWithSsr {
  return div(
    {
      class: `loading-overlay ${className ?? ''}`,
    },
    loadingIndicator(),
  );
}
