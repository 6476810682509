import type { RenderResultWithSsr } from '../../lib/renderTypes';
import {
  div,
  fieldset,
  form,
  input,
  label,
  legend,
  section,
  span,
} from '../../render/html';
import { cityField, createDefaultCityHandlers } from './citySearch';
import './orgSearch.css';
import { renderResultsContent, renderResultsLoading, results } from './results';
import { renderSearchPageSearchField } from './search';
import { loadDataIfNecessary } from '../../action/loadDataIfNecessary';
import { selectParamsForCitySearch } from '../read/selectParamsForCitySearch';
import { mapBooleanAttributes } from '../../util/mapBooleanAttributes';
import { handleRegschlFilterChanged } from '../../action/filter/handleRegschlFilterChanged';
import {
  FILTER_TITLE,
  REGSCHL_FILTER_TYPE_ORT,
  REGSCHL_FILTER_TYPE_ZUSTAENDIG,
  REGSCHL_LEVEL_DEUTSCHLAND_LABEL,
  LAND_HESSEN,
  ORG_SEARCH_ORG_INPUT_DESCRIPTION,
  REGSCHL_LEVEL_KOMMUNE_LABEL,
  REGSCHL_LEVEL_KREIS_LABEL,
  REGSCHL_LEVEL_BEZIRK_IN_HESSEN_LABEL,
} from '../../texts';
import {
  RegschlFilterType,
  RegschlLevel,
  SearchResultType,
} from '../../action/search/searchTypes';
import { simplePlaceName } from '../util/combinePlaceNames';
import { defoultOrgSearchRegschlFilter } from '../../reducer/resetOrgSearchRegschlFilter';
import { REGSCHL_HESSEN, DOM_ID_DIV_FILTER } from '../../constants';
import type { SimpleStore } from '../../state/SimpleStore';

type FilterData = {
  checked: boolean;
  disabled: boolean;
  label: string;
  value: RegschlLevel | RegschlFilterType;
  count: number | undefined;
};

function EMPTY_FILTER_DATA(type: RegschlLevel, text: string): FilterData {
  return {
    checked: false,
    disabled: true,
    label: text,
    value: type,
    count: undefined,
  };
}

const EMPTY_KOMMUNE = EMPTY_FILTER_DATA(
  RegschlLevel.kommune,
  REGSCHL_LEVEL_KOMMUNE_LABEL,
);
const EMPTY_KREIS = EMPTY_FILTER_DATA(
  RegschlLevel.kreis,
  REGSCHL_LEVEL_KREIS_LABEL,
);
const EMPTY_BEZIRK_HESSEN = EMPTY_FILTER_DATA(
  RegschlLevel.bezirk,
  REGSCHL_LEVEL_BEZIRK_IN_HESSEN_LABEL,
);
const EMPTY_LAND_HESSEN = EMPTY_FILTER_DATA(RegschlLevel.land, LAND_HESSEN);

export default function orgSearch(
  classNames: string,
  simpleStore: SimpleStore,
  orgSearchLabel = 'Stichwortsuche',
): RenderResultWithSsr {
  const state = simpleStore.getState();

  const {
    dropdownState,
    inputValue,
    loading,
    ariaDescription,
    placeholder,
    showFeedbackHint,
    suggestions,
  } = selectParamsForCitySearch(state);

  const { selectedOrt } = state.citySearch;

  const regschlFilterTypes =
    state.search.request.regschlFilterTypes ??
    defoultOrgSearchRegschlFilter(state);
  const orgFilterCounts: Partial<
    Record<RegschlLevel | RegschlFilterType, undefined | number>
  > = state.search.results.data?.orgFilterCounts ?? {};

  let filterData: FilterData[];
  if (state.flags.org_filter === 'dual') {
    const cityName = simplePlaceName(selectedOrt);
    const filterOrt: FilterData = {
      checked: regschlFilterTypes.includes(RegschlFilterType.ort),
      disabled: false,
      label: REGSCHL_FILTER_TYPE_ORT(cityName),
      value: RegschlFilterType.ort,
      count: orgFilterCounts[RegschlFilterType.ort],
    };
    const filterZust: FilterData = {
      checked: regschlFilterTypes.includes(RegschlFilterType.zustaendig),
      disabled: false,
      label: REGSCHL_FILTER_TYPE_ZUSTAENDIG(cityName),
      value: RegschlFilterType.zustaendig,
      count: orgFilterCounts[RegschlFilterType.zustaendig],
    };
    filterData = [filterOrt, filterZust];
  } else {
    const filterKommune: FilterData = selectedOrt.ort
      ? {
          checked: regschlFilterTypes.includes(RegschlLevel.kommune),
          disabled: !orgFilterCounts[RegschlLevel.kommune],
          label: selectedOrt.ort,
          value: RegschlLevel.kommune,
          count: orgFilterCounts[RegschlLevel.kommune],
        }
      : EMPTY_KOMMUNE;
    const filterGemeindeverband: FilterData[] = selectedOrt.verband
      ? [
          {
            checked: regschlFilterTypes.includes(RegschlLevel.gemeindeverband),
            disabled: !orgFilterCounts[RegschlLevel.gemeindeverband],
            label: selectedOrt.verband,
            value: RegschlLevel.gemeindeverband,
            count: orgFilterCounts[RegschlLevel.gemeindeverband],
          },
        ]
      : [];
    const filterKreis: FilterData = selectedOrt.kreis
      ? {
          checked: regschlFilterTypes.includes(RegschlLevel.kreis),
          disabled: !orgFilterCounts[RegschlLevel.kreis],
          label: selectedOrt.kreis,
          value: RegschlLevel.kreis,
          count: orgFilterCounts[RegschlLevel.kreis],
        }
      : EMPTY_KREIS;
    const filterBezirk: FilterData = selectedOrt.bezirk
      ? {
          checked: regschlFilterTypes.includes(RegschlLevel.bezirk),
          disabled: !orgFilterCounts[RegschlLevel.bezirk],
          label: selectedOrt.bezirk,
          value: RegschlLevel.bezirk,
          count: orgFilterCounts[RegschlLevel.bezirk],
        }
      : EMPTY_BEZIRK_HESSEN;
    const filterLand: FilterData = selectedOrt.land
      ? {
          checked: regschlFilterTypes.includes(RegschlLevel.land),
          disabled: !orgFilterCounts[RegschlLevel.land],
          label: selectedOrt.land,
          value: RegschlLevel.land,
          count: orgFilterCounts[RegschlLevel.land],
        }
      : EMPTY_LAND_HESSEN;
    const filterBund: FilterData = {
      checked: regschlFilterTypes.includes(RegschlLevel.bund),
      disabled: !orgFilterCounts[RegschlLevel.bund],
      label: REGSCHL_LEVEL_DEUTSCHLAND_LABEL,
      value: RegschlLevel.bund,
      count: orgFilterCounts[RegschlLevel.bund],
    };

    filterData = [
      filterKommune,
      ...filterGemeindeverband,
      filterKreis,
      filterBezirk,
      filterLand,
      filterBund,
    ];
  }

  const handleChange =
    (regschlFilter: RegschlLevel | RegschlFilterType) => (event: Event) => {
      const { checked } = event.target as HTMLInputElement;
      handleRegschlFilterChanged(simpleStore, regschlFilter, checked);
    };

  let showFilter = true;
  const { results: searchResults } = state.search;
  if (
    // Keine Suche getätigt
    !searchResults.data ||
    // Suche getätigt aber keine Ergebnisse
    (searchResults.data.type === SearchResultType.RESULT &&
      // Keine Ergebnisse erhalten
      !searchResults.data.result.orgeinheitenRows?.length &&
      // Alle vorhandenen Filter durchsuchen
      filterData.every((data) => !data.count)) ||
    // Unklar
    !searchResults.forRequest
  ) {
    showFilter = false;
  }
  return form(
    {
      class: classNames,
      onsubmit: (event: Event) => {
        event.preventDefault();
      },
    },
    section({ 'aria-label': 'Suche', role: 'search' }, [
      renderSearchPageSearchField(
        simpleStore,
        orgSearchLabel,
        ORG_SEARCH_ORG_INPUT_DESCRIPTION,
      ),
      cityField({
        dropdownState,
        inputValue,
        loading,
        handlers: createDefaultCityHandlers({
          simpleStoreForHandlers: simpleStore,
          onCitySelected: () => {
            loadDataIfNecessary(simpleStore);
          },
          onlyHessen: true,
        }),
        ariaDescription,
        placeholder,
        showFeedbackHint,
        suggestions,
      }),
      showFilter
        ? fieldset(
            { class: `d-flex flex-column mb-3 text-break` },
            legend(
              { class: 'vwp-d-contents' },
              div({ class: 'mb-1' }, [FILTER_TITLE]),
            ),
            div(
              {
                class: 'd-flex flex-row flex-wrap gap-25',
                id: DOM_ID_DIV_FILTER,
              },
              [
                ...filterData.map(
                  ({ checked, count, disabled, label: text, value }) =>
                    label(
                      {
                        class: '',
                      },
                      [
                        input({
                          ...mapBooleanAttributes({
                            class: `btn-check`,
                            name: 'filter-regschl-resp',
                            type: 'checkbox',
                            value,
                            onchange: handleChange(value),
                            checked,
                            disabled,
                          }),
                          // wichtig: der Wert, den der Nutzer durch Anklicken ändert, ist nicht das Attribut "checked", sondern die
                          // property "checked". Daher muss hier sowohl das Attribut (siehe oben), als auch die Property geändert werden
                          'prop-checked': checked,
                        }),
                        span(
                          {
                            class: 'btn btn-filter-primary',
                          },
                          [text + (count === undefined ? '' : ` (${count})`)],
                        ),
                      ],
                    ),
                ),
              ],
            ),
          )
        : undefined,
    ]),
  );
}

export function orgResults(
  className: string,
  simpleStore: SimpleStore,
  orgAriaHint: string,
): RenderResultWithSsr {
  const state = simpleStore.getState();

  const renderResult =
    state.search.request.searchTerm ||
    state.citySearch.selectedOrt.id !== REGSCHL_HESSEN;

  const content = renderResult
    ? renderResultsContent(
        simpleStore,
        /* showTitle */ false,
        /* shouldRenderAskList */ false,
        orgAriaHint,
      )
    : [];
  let loading: RenderResultWithSsr[] = [];
  if (state.abortRequest.search) {
    const dataDisplayed = Boolean(content.length);
    loading = renderResultsLoading(dataDisplayed);
  }

  return div(
    { class: 'og-org-results-container' },
    renderResult
      ? results(
          simpleStore,
          /* meta */ undefined,
          /* showTitle */ false,
          content,
          loading,
          className,
        )
      : [],
  );
}
