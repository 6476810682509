import { RenderResultWithSsr } from '../../../lib/renderTypes';
import { button, div, img } from '../../../render/html';
import { mapBooleanAttributes } from '../../../util/mapBooleanAttributes';
import loadingOverlay from '../loadingOverlay';
import { ChatbotState } from '../../../state/createInitialState';
import { chatbotButtonImageData } from './chatbotButtonData';

export const ID_CHATBOT_BUTTON = 'vwp_nfk_chatbot_button';

export function renderChatbotButtonUnloaded(
  chatbotState: ChatbotState,
  onclick: undefined | ((this: HTMLElement) => void),
  buttonSrc?: string,
): RenderResultWithSsr | undefined {
  return div({ class: 'vwp-chatbot-button-wrapper' }, [
    button(
      mapBooleanAttributes({
        id: ID_CHATBOT_BUTTON,
        class: 'vwp-chatbot-button',
        disabled: chatbotState.loading,
        onclick,
      }),
      [
        img({
          class: 'w-100 h-100',
          src:
            buttonSrc ??
            (window.hzd_og_data?.datenschutz.chatbotButtonSrc ||
              chatbotButtonImageData),
        }),
      ],
    ),
    chatbotState.loading ? loadingOverlay('p-3') : undefined,
  ]);
}
