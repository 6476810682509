import type { SearchSuggestion } from '../action/search/searchTypes';
import { SearchSuggestionType } from '../action/search/searchTypes';
import { TypeFilter } from '../constants';
import { TYPE_FILTER_LABEL } from '../texts';
import { UnreachableCaseError } from './UnreachableCaseError';

export function buildSuggestionValue(suggestion: SearchSuggestion): string {
  switch (suggestion.type) {
    case SearchSuggestionType.WORD:
      return suggestion.value;
    case SearchSuggestionType.DIENSTSTELLE:
      return `${TYPE_FILTER_LABEL(TypeFilter.orgeinheit)}: ${suggestion.value}`;
    case SearchSuggestionType.INFO:
      return `${TYPE_FILTER_LABEL(TypeFilter.info)}: ${suggestion.value}`;
    case SearchSuggestionType.LEISTUNG:
      return `${TYPE_FILTER_LABEL(TypeFilter.leistung)}: ${suggestion.value}`;
    default:
      throw new UnreachableCaseError(suggestion.type);
  }
}
