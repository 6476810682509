import omitBy from 'lodash/fp/omitBy';

type NoUndefinedField<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

/*
type blub = NoUndefinedField<{
  a: string;
  b: boolean;
  c: number;
  d: undefined;
  e: {
    f: string;
    g: boolean;
    h: number;
    i: undefined;
  };
}>;

const bla: blub = {
  a: 'string',
  b: true,
  c: 5,
  d: undefined,
  e: {
    f: 'string',
    g: true,
    h: 5,
    i: undefined,
  },
};
 */

export function filterUndefinedOrEmptyAttributes<
  A extends Partial<Record<string, unknown>>,
>(obj: A): NoUndefinedField<A> {
  return omitBy((val) => val === undefined || val === '')(
    obj,
  ) as NoUndefinedField<A>;
}
