import { TypeFilter } from '../../constants';
import { createEvent, TriggeredBy, Volatile } from '../../reducer/createEvent';
import type {
  SelectedOrt,
  GebietResult,
  SearchMoreResult,
  SearchRequest,
  SearchResultFromServer,
  SearchSuggestionFromServer,
  CitySearchRequest,
  Gebiet,
  RegschlSearchRequest,
  SearchMoreRequest,
  SearchSuggestionRequest,
} from './searchTypes';

/** Server Request zur Ortssuche wurde abgebrochen */
export const CITY_SEARCH_ABORTED = createEvent(
  'CITY_SEARCH_ABORTED',
  TriggeredBy.Other,
);
/** Server Request zur Ortssuche hat einen Fehler zurückgeliefert */
export const CITY_SEARCH_ERROR = createEvent<unknown>(
  'CITY_SEARCH_ERROR',
  TriggeredBy.Other,
);
/** Server Request zur Ortssuche hat Daten geliefert */
export const CITY_SEARCH_FINISHED = createEvent<{
  data: Gebiet[];
  forRequest: CitySearchRequest;
}>('CITY_SEARCH_FINISHED', TriggeredBy.Other);
/** Server Request zur Ortssuche hat keine Daten gefunden */
export const CITY_SEARCH_NOT_FOUND = createEvent<CitySearchRequest>(
  'CITY_SEARCH_NOT_FOUND',
  TriggeredBy.Other,
);
/** Ortssuche wurde auf Initialwert zurückgesetzt */
export const CITY_SEARCH_RESET = createEvent(
  'CITY_SEARCH_RESET',
  TriggeredBy.User,
);
/** Ortssuche wurde auf den vorher ausgewählten Ort zurückgesetzt */
export const CITY_SEARCH_RESETTED_TO_SELECTED = createEvent(
  'CITY_SEARCH_RESETTED_TO_SELECTED',
  TriggeredBy.User,
);
/** Server Request zur Ortssuche wurde gestartet */
export const CITY_SEARCH_STARTED = createEvent<{
  abortRequest: () => void;
  forRequest: CitySearchRequest;
}>('CITY_SEARCH_STARTED', TriggeredBy.Other);
/** Ortssuche-Wert wurde geändert */
export const CITY_SEARCH_TERM_TYPED = createEvent<string>(
  'CITY_SEARCH_TERM_TYPED',
  TriggeredBy.User,
  Volatile.True,
);
/** Ort wurde aus Auswahlliste ausgewählt */
export const CITY_SELECTED = createEvent<SelectedOrt | undefined>(
  'CITY_SELECTED',
  TriggeredBy.User,
);
/** Ort wurde nach Rückfrage gewählt */
export const CITY_SELECTED_FROM_ASK = createEvent<{
  searchTerm: string;
  selectedOrt: GebietResult;
}>('CITY_SELECTED_FROM_ASK', TriggeredBy.User);
/** Feedback zur Ortssuche verstecken */
export const CITY_FEEDBACK_HIDDEN = createEvent(
  'CITY_FEEDBACK_HIDDEN',
  TriggeredBy.Other,
);
/** Feedback zur Ortssuche anzeigen */
export const CITY_FEEDBACK_SHOWN = createEvent(
  'CITY_FEEDBACK_SHOWN',
  TriggeredBy.Other,
);

/** Server Request zur Regionalschlüsselsuche wurde abgebrochen */
export const REGSCHL_SEARCH_ABORTED = createEvent(
  'REGSCHL_SEARCH_ABORTED',
  TriggeredBy.Other,
);
/** Server Request zur Regionalschlüsselsuche hat einen Fehler zurückgeliefert */
export const REGSCHL_SEARCH_ERROR = createEvent<unknown>(
  'REGSCHL_SEARCH_ERROR',
  TriggeredBy.Other,
);
/** Server Request zur Regionalschlüsselsuche hat Daten geliefert */
export const REGSCHL_SEARCH_FINISHED = createEvent<{
  data: GebietResult;
  forRequest: RegschlSearchRequest;
}>('REGSCHL_SEARCH_FINISHED', TriggeredBy.Other);
/** Server Request zur Regionalschlüsselsuche wurde gestartet */
export const REGSCHL_SEARCH_STARTED = createEvent<{
  abortRequest: () => void;
  forRequest: RegschlSearchRequest;
}>('REGSCHL_SEARCH_STARTED', TriggeredBy.Other);

/** Suchergebnisse wurden entfernt */
export const SEARCH_CLEARED = createEvent('SEARCH_CLEARED', TriggeredBy.User);
/** Bei der Suche ist ein Fehler aufgetreten */
export const SEARCH_ERROR = createEvent<unknown>(
  'SEARCH_ERROR',
  TriggeredBy.Other,
);
/** Suche wurde erfolgreich beendet */
export const SEARCH_FINISHED = createEvent<{
  forRequest: SearchRequest;
  resultHasFrame: boolean;
  results: SearchResultFromServer;
}>('SEARCH_FINISHED', TriggeredBy.Other);
/** Die Suche wurde gestartet */
export const SEARCH_STARTED = createEvent<{
  abortRequest: () => void;
  forRequest: SearchRequest;
}>('SEARCH_STARTED', TriggeredBy.Other);

/** Suche nach weiteren Einträgen hat einen Fehler zurückgeliefert */
export const SEARCH_MORE_ERROR = createEvent<unknown>(
  'SEARCH_MORE_ERROR',
  TriggeredBy.Other,
);
/** Suche nach weiteren Einträgen wurde erfolgreich beendet */
export const SEARCH_MORE_FINISHED = createEvent<{
  searchType: TypeFilter;
  results: SearchMoreResult;
}>('SEARCH_MORE_FINISHED', TriggeredBy.Other);
/** Suche nach weiteren Einträgen hat keine Ergebnisse gefunden */
export const SEARCH_MORE_NOT_FOUND = createEvent<TypeFilter>(
  'SEARCH_MORE_NOT_FOUND',
  TriggeredBy.Other,
);
/** Suche nach weiteren Einträgen wurde gestartet */
export const SEARCH_MORE_STARTED = createEvent<{
  abortRequest: () => void;
  forRequest: SearchMoreRequest;
}>('SEARCH_MORE_STARTED', TriggeredBy.Other);

/** Die Suche nach Vorschlägen wurde abgebrochen */
export const SEARCH_SUGGESTION_ABORTED = createEvent(
  'SEARCH_SUGGESTION_ABORTED',
  TriggeredBy.Other,
);
/** Die Suche nach Vorschlägen hat einen Fehler zurückgeliefert */
export const SEARCH_SUGGESTION_ERROR = createEvent<unknown>(
  'SEARCH_SUGGESTION_ERROR',
  TriggeredBy.Other,
);
/** Die Suche nach Vorschlägen hat Ergebnisse geliefert */
export const SEARCH_SUGGESTION_FINISHED = createEvent<{
  data: SearchSuggestionFromServer[];
  position: number;
  term: string;
}>('SEARCH_SUGGESTION_FINISHED', TriggeredBy.Other);
/** Die Such nach Vorschlägene hat nichts gefunden */
export const SEARCH_SUGGESTION_NOT_FOUND = createEvent<SearchSuggestionRequest>(
  'SEARCH_SUGGESTION_NOT_FOUND',
  TriggeredBy.Other,
);
/** Die Suche nach Vorschlägen wurde gestartet */
export const SEARCH_SUGGESTION_STARTED = createEvent<{
  abortRequest: () => void;
  forRequest: SearchSuggestionRequest;
}>('SEARCH_SUGGESTION_STARTED', TriggeredBy.Other);

/** Suchergebnisse wurden ausgeblendet */
export const SEARCH_RESULTS_HIDDEN = createEvent(
  'SEARCH_RESULTS_HIDDEN',
  TriggeredBy.Other,
);
/** Suchen wurde abgebrochen */
export const SEARCHES_ABORTED = createEvent(
  'SEARCHES_ABORTED',
  TriggeredBy.Other,
);
/** Der Suchbegriff wurde geändert */
export const SEARCH_TERM_CHANGED = createEvent<string>(
  'SEARCH_TERM_CHANGED',
  TriggeredBy.User,
);
/** Such-Begriff wurde gekürzt */
export const SEARCH_TERM_TRIMMED = createEvent(
  'SEARCH_TERM_TRIMMED',
  TriggeredBy.User,
);
/**
 * Der Suchbegriff wurde durch Eintippen von Buchstaben/Backspace/... geändert; Unterschied zu SEARCH_TERM_CHANGED:
 * SEARCH_TERM_TYPED ist volatile
 */
export const SEARCH_TERM_TYPED = createEvent<string>(
  'SEARCH_TERM_TYPED',
  TriggeredBy.User,
  Volatile.True,
);
