import type { SearchRequest } from '../action/search/searchTypes';

export function requestWithoutOrgFilter(
  request: SearchRequest | undefined,
): SearchRequest | undefined {
  return (
    request && {
      ...request,
      filter: {
        ...request.filter,
        type: [],
      },
      gebietsebene: [],
      regschlSuchTyp: [],
    }
  );
}
