export const sortFunctionGerman: (x: string, y: string) => number =
  getCompareFunction();

function getCompareFunction(): (x: string, y: string) => number {
  if (typeof Intl === 'object') {
    const collator = new Intl.Collator('de', {
      caseFirst: 'upper',
      numeric: true,
    });
    return collator.compare.bind(collator);
  } else if (typeof ''.localeCompare === 'function') {
    return (x: string, y: string) => x.localeCompare(y);
  } else {
    return (x: string, y: string) => {
      if (x === y) {
        return 0;
      } else if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      } else {
        throw new Error(`Cannot compare "${x}" and "${y}"`);
      }
    };
  }
}
