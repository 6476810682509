import { abortSearches } from '../search/searchCommon';
import { FILTER_REGSCHL_FILTER_CHANGED } from '../../reducer/filterEvents';
import { loadDataIfNecessary } from '../loadDataIfNecessary';
import { RegschlFilterType, RegschlLevel } from '../search/searchTypes';
import { defoultOrgSearchRegschlFilter } from '../../reducer/resetOrgSearchRegschlFilter';
import { deepEquals } from '../../util/deepEquals';
import type { SimpleStore } from '../../state/SimpleStore';

export function handleRegschlFilterChanged(
  simpleStore: SimpleStore,
  regschlFilter: RegschlLevel | RegschlFilterType,
  checked: boolean,
) {
  const state = simpleStore.getState();
  const orgFilterFlag = state.flags.org_filter;
  const defoultFilter = defoultOrgSearchRegschlFilter(state);
  const previousRegschlFilterTypes =
    state.search.request.regschlFilterTypes ?? defoultFilter;

  let regschlFilterTypes;

  if (checked) {
    if ([undefined, 'multi'].includes(orgFilterFlag)) {
      regschlFilterTypes = previousRegschlFilterTypes.includes(regschlFilter)
        ? previousRegschlFilterTypes
        : [...previousRegschlFilterTypes, regschlFilter];
    } else {
      regschlFilterTypes = [regschlFilter];
    }
  } else {
    regschlFilterTypes = previousRegschlFilterTypes.filter(
      (value) => value !== regschlFilter,
    );
  }

  if (deepEquals(regschlFilterTypes, defoultFilter)) {
    regschlFilterTypes = undefined;
  }

  abortSearches(simpleStore);
  simpleStore.dispatch(FILTER_REGSCHL_FILTER_CHANGED({ regschlFilterTypes }));
  loadDataIfNecessary(simpleStore);
}
