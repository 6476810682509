import { createSelector } from 'reselect';
import {
  getBereichCode,
  getLageCode,
  getPvLagenLevel,
  PvLagenLevel,
} from '../../util/pvLagenUtil';
import { UnreachableCaseError } from '../../util/UnreachableCaseError';
import type { PvLagenByCode, State } from '../../state/createInitialState';

const selectPvLagenByCode = (state: State): PvLagenByCode =>
  state.pvLagen.pvLagenByCode ?? {};

export const extractPvLagenByParent = createSelector(
  selectPvLagenByCode,
  (pvLagenByCode) => {
    const pvLagen = Object.keys(pvLagenByCode).map((code) => {
      const level = getPvLagenLevel(code);
      let parent;
      switch (level) {
        case PvLagenLevel.BEREICH:
          parent = undefined;
          break;
        case PvLagenLevel.LAGE:
          parent = getBereichCode(code);
          break;
        case PvLagenLevel.SUBLAGE:
          parent = getLageCode(code);
          break;
        default:
          throw new UnreachableCaseError(level);
      }
      return { parent, code };
    });

    const pvLagenByParent = new Map<string, string[]>();
    pvLagen.forEach((lage) => {
      let list = pvLagenByParent.get(lage.parent ?? '');
      if (!list) {
        list = [];
        pvLagenByParent.set(lage.parent ?? '', list);
      }
      list.push(lage.code);
    });
    return pvLagenByParent;
  },
);
