/* eslint-disable no-param-reassign */
import type { AnyAction, Reducer } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import { createDetailReducer } from '../action/detail/detailReducer';
import { createFilterReducer } from './filterReducer';
import { createSearchReducer } from '../action/search/searchReducer';
import type { State } from '../state/createInitialState';
import { deepFreeze } from '../util/freeze';
import { Screen } from '../view';
import {
  APP_UPDATED,
  DROPDOWN_HIDDEN,
  DROPDOWN_SELECTED,
  DROPDOWN_SHOWN,
  ORG_NAVIGATED_BACK,
  SCREEN_CHANGED,
  SCROLLED_TO_ANCHOR,
  SEARCH_WITH_HIERARCHY_SHOWN,
  THEMEN_COLLAPSED,
  VALIDATION_ERRORS_SHOWN,
} from './events';
import { resetOrgSearchRegschlFilter } from './resetOrgSearchRegschlFilter';
import { createMapReducer } from '../map_online_services/mapReducer';
import { reduceForScreenChange } from './reduceForScreenChange';
import { createPvLagenReducer } from './pvLagenReducer';
import { createNfkReducer } from './nfkReducer';
import { createChatbotReducer } from './chatbotReducer';
/* Hinweise:
 *
 * Es wird die funktionale Variante von Lodash verwendet. D.h. der
 * eigentliche Wert, auf den die Funktion angewendet werden soll, wird nicht als erster Parameter angegeben, sondern
 * mit weiteren Klammern am Ende angefügt.
 *
 * Beispiel für map: map(Funktion zum Transformieren)(Quell-Array)
 *
 * Die Funktion "flow" wendet mehrere Funktionen der Reihe nach auf einen Wert (hier normalerweise auf den Zustand) an.
 * Beispiel:
 *       return flow(
 *         set(['result', 'abortRequest'], undefined),
 *         set(['result', 'data'], payload),
 *       )(state);
 * ist das Gleiche wie:
 *       const a = set(['result', 'abortRequest'], undefined)(state);
 *       const b = set(['result', 'data'], payload)(a);
 *       return b;
 *
 */

export default function createRootReducer(initialState: State): Reducer<State> {
  const rootReducer = createReducer(
    () => initialState,
    (builder) => {
      createDetailReducer(builder);
      createFilterReducer(builder);
      createSearchReducer(builder);
      createPvLagenReducer(builder);
      createMapReducer(builder);
      createChatbotReducer(builder);
      createNfkReducer(builder);

      builder.addCase(APP_UPDATED, (state) => {
        state.appWasUpdated = true;
      });
      builder.addCase(DROPDOWN_HIDDEN, (state, action) => {
        const dropdown = action.payload;
        state.dropdowns[dropdown].visible = false;
        state.dropdowns[dropdown].selection = undefined;
      });
      builder.addCase(DROPDOWN_SELECTED, (state, action) => {
        const { dropdown, index } = action.payload;
        state.dropdowns[dropdown].selection = index;
      });
      builder.addCase(DROPDOWN_SHOWN, (state, action) => {
        const dropdown = action.payload;
        state.dropdowns[dropdown].visible = true;
      });
      builder.addCase(ORG_NAVIGATED_BACK, (state) => {
        state.screen = Screen.OrgSuche;
        reduceForScreenChange(state);
        state.org.request = undefined;
      });
      builder.addCase(SCREEN_CHANGED, (state, action) => {
        state.screen = action.payload;
        reduceForScreenChange(state);
        resetOrgSearchRegschlFilter(state);
      });
      builder.addCase(SCROLLED_TO_ANCHOR, (state) => {
        state.scrollToAnchorNeeded = false;
      });
      builder.addCase(SEARCH_WITH_HIERARCHY_SHOWN, (state, action) => {
        const { searchScreen, pvLage } = action.payload;
        state.search.request.filter = {
          pvLage,
          type: state.search.request.filter.type,
        };
        state.search.request.searchTerm = '';
        state.search.results.data = undefined;
        state.screen = searchScreen;
        reduceForScreenChange(state);
      });
      builder.addCase(VALIDATION_ERRORS_SHOWN, (state) => {
        state.search.showValidationErrors = true;
      });
      builder.addCase(THEMEN_COLLAPSED, (state, action) => {
        state.search.collapseThemen = action.payload;
      });
    },
  );

  return (state: undefined | State, action: AnyAction) => {
    if (process.env.NODE_ENV === 'development') {
      const { payload } = action;
      deepFreeze(payload);
    }
    return rootReducer(state, action);
  };
}
