import { createEvent, TriggeredBy } from './createEvent';

/** Das Skript für die Chatbot-Funktion konnte nicht geladen werden */
export const CHATBOT_LOADING_FAILED = createEvent(
  'CHATBOT_LOADING_FAILED',
  TriggeredBy.Other,
);
/** Das Skript für die Chatbot-Funktion wurde erfolgreich geladen */
export const CHATBOT_LOADING_FINISHED = createEvent(
  'CHATBOT_LOADING_FINISHED',
  TriggeredBy.Other,
);
/** Das Skript für die Chatbot-Funktion wird geladen */
export const CHATBOT_LOADING_STARTED = createEvent(
  'CHATBOT_LOADING_STARTED',
  TriggeredBy.Other,
);
/** Die Datenschutzbedingungen für den Chatbot wurden akzeptiert */
export const CHATBOT_PRIVACY_ACCEPTED = createEvent(
  'CHATBOT_PRIVACY_ACCEPTED',
  TriggeredBy.Other,
);
