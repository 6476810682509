import { createEvent, TriggeredBy } from './createEvent';

/** Das Skript für die Feedback-Funktion (NFK) konnte nicht geladen werden */
export const NFK_LOADING_FAILED = createEvent(
  'NFK_LOADING_FAILED',
  TriggeredBy.Other,
);
/** Das Skript für die Feedback-Funktion (NFK) wurde erfolgreich geladen */
export const NFK_LOADING_FINISHED = createEvent(
  'NFK_LOADING_FINISHED',
  TriggeredBy.Other,
);
/** Das Skript für die Feedback-Funktion (NFK) wird geladen */
export const NFK_LOADING_STARTED = createEvent(
  'NFK_LOADING_STARTED',
  TriggeredBy.Other,
);
/** Die Datenschutzbedingungen für NFK wurden akzeptiert */
export const NFK_PRIVACY_ACCEPTED = createEvent(
  'NFK_PRIVACY_ACCEPTED',
  TriggeredBy.Other,
);
