import './render.css';
import { htmlIncrementalDom } from '../../render/html';
import { inputButton2 } from './renderInput2';

export type RenderButtonParams = {
  ariaLabel: string;
  class?: string;
  iconClass: string;
  onclick?: (event: MouseEvent) => void;
  type: 'button' | 'submit';
};

export function inputButton({
  ariaLabel,
  iconClass,
  ...inputParams
}: RenderButtonParams) {
  return inputButton2(htmlIncrementalDom, {
    ariaLabel,
    iconClass,
    ...inputParams,
  });
}
