import {
  clearCity,
  handleCityBlurred,
  handleCityChanged,
  handleCitySet,
} from '../../action/search/handleCityChanged';
import type { Dropdown } from '../../action/search/searchCommon';
import { Dropdowns } from '../../action/search/searchCommon';
import { CITY_FEEDBACK_SHOWN } from '../../action/search/searchEvents';
import type { Gebiet } from '../../action/search/searchTypes';
import type { RenderParam } from '../../lib/renderTypes';
import { SCREEN_CHANGED } from '../../reducer/events';
import { div, label } from '../../render/html';
import {
  CITY_FEEDBACK_INPUT_INVALID,
  CITY_FEEDBACK_LOADING,
  CITY_FEEDBACK_MULTIPLE_MATCHES,
  CITY_FEEDBACK_NOT_FOUND,
  CITY_INPUT_TITLE,
} from '../../texts';
import { isValidForCitySearch } from '../../validation/validateInput';
import { Screen } from '../../view';
import {
  createDefaultHandlersForInputDropdown,
  type HandlersForInputDropdown,
  inputDropdown,
} from '../elements/renderDropdown';
import { inputButton } from '../elements/renderInput';
import combinePlaceNames from '../util/combinePlaceNames';
import onSpecialKey from '../util/onSpecialKey';
import './citySearch.css';
import { createGenerateIdToken } from '../../render/customize-incremental-dom';
import handleInputChangedEvent from '../../action/util/handleInputChangedEvent';
import type { SimpleStore } from '../../state/SimpleStore';

export type RenderCityFieldParams = {
  dropdownState: Dropdown;
  inputValue: string;
  loading: boolean;
  ariaDescription: string;
  placeholder: string;
  showFeedbackHint: boolean;
  suggestions: Gebiet[];
};
export function renderCityField(
  simpleStoreForHandlers: SimpleStore,
  params: RenderCityFieldParams,
) {
  const {
    dropdownState,
    inputValue,
    loading,
    ariaDescription,
    placeholder,
    showFeedbackHint,
    suggestions,
  } = params;
  return cityField({
    dropdownState,
    inputValue,
    loading,
    handlers: createDefaultCityHandlers({
      simpleStoreForHandlers,
      onCitySelected: () => {
        const curState = simpleStoreForHandlers.getState();
        if (curState.search.request.searchTerm) {
          simpleStoreForHandlers.dispatch(SCREEN_CHANGED(Screen.Suche));
        }
      },
    }),
    ariaDescription,
    placeholder,
    showFeedbackHint,
    suggestions,
  });
}

type RenderFeedbackParams = {
  inputValue: string;
  loading: boolean;
  showFeedbackHint: boolean;
  suggestions: Gebiet[];
};
export function renderFeedback(params: RenderFeedbackParams): RenderParam {
  const { inputValue, loading, showFeedbackHint, suggestions } = params;
  if (!showFeedbackHint) {
    return undefined;
  }
  let hint;
  if (!isValidForCitySearch(inputValue)) {
    hint = CITY_FEEDBACK_INPUT_INVALID;
  } else if (loading) {
    hint = CITY_FEEDBACK_LOADING;
  } else if (suggestions.length === 0) {
    hint = CITY_FEEDBACK_NOT_FOUND;
  } else {
    hint = CITY_FEEDBACK_MULTIPLE_MATCHES;
  }
  return hint && div({ class: 'og-city-search-hint' }, hint);
}

type CityFieldHandlers = HandlersForInputDropdown & {
  clearCity: () => void;
  handleCityBlurred: () => void;
  handleCityChanged: (city: string) => void;
  handleCitySet: (city: string) => void;
  showFeedback: () => void;
};

export function createDefaultCityHandlers({
  simpleStoreForHandlers,
  onCitySelected,
  onlyHessen = false,
}: {
  simpleStoreForHandlers: SimpleStore;
  onCitySelected?: () => void;
  onlyHessen?: boolean;
}): CityFieldHandlers {
  return {
    ...createDefaultHandlersForInputDropdown(
      simpleStoreForHandlers,
      Dropdowns.CITY,
    ),
    clearCity: () => {
      clearCity(simpleStoreForHandlers, onCitySelected);
    },
    handleCityBlurred: () => {
      handleCityBlurred(simpleStoreForHandlers, onCitySelected);
    },
    handleCityChanged: (city: string) => {
      handleCityChanged(
        simpleStoreForHandlers,
        city,
        onCitySelected,
        onlyHessen,
      );
    },
    handleCitySet: (city: string) => {
      handleCitySet(simpleStoreForHandlers, city, onCitySelected);
    },
    showFeedback: () => {
      simpleStoreForHandlers.dispatch(CITY_FEEDBACK_SHOWN());
    },
  };
}

export function cityField({
  dropdownState,
  inputValue,
  labelText = CITY_INPUT_TITLE,
  loading,
  handlers,
  ariaDescription,
  placeholder,
  showFeedbackHint,
  suggestions,
}: {
  dropdownState: Dropdown;
  inputValue: string;
  labelText?: string;
  loading: boolean;
  handlers: CityFieldHandlers;
  ariaDescription: string;
  placeholder: string;
  showFeedbackHint: boolean;
  suggestions: Gebiet[];
}) {
  const hintElement = renderFeedback({
    inputValue,
    loading,
    showFeedbackHint,
    suggestions,
  });
  const datalist = suggestions.map((gebiet) => {
    const bezeichnung = combinePlaceNames(gebiet);
    const bezeichnungForDatalist = bezeichnung
      .toLowerCase()
      .startsWith(inputValue.toLowerCase())
      ? bezeichnung
      : `${inputValue} 🠊 ${bezeichnung}`;
    return {
      value: `${bezeichnung}\u2008`,
      datalistLabel: bezeichnungForDatalist,
      customLabel: bezeichnung,
    };
  });

  const inputId = createGenerateIdToken();
  return div(
    {
      class: `d-flex flex-column mb-3 og-city-field-box font-font-roc-grotesk-w05-regular mt-3`,
    },
    [
      label(
        {
          for: inputId,
          class: 'mb-1',
        },
        labelText,
      ),
      div({ class: 'd-flex' }, [
        inputDropdown({
          cssClass: 'og-flex-grow-shrink',
          inputParams: {
            id: inputId,
            type: 'search',
            autocomplete: 'off',
            ariaDescription,
            placeholder,
            value: inputValue,
            spellcheck: false,
            onfocus: (event: FocusEvent) => {
              const target = event.target as HTMLInputElement;
              target.placeholder = '';
            },
            onblur: (event: FocusEvent) => {
              const target = event.target as HTMLInputElement;
              target.placeholder = placeholder;
              handlers.handleCityBlurred();
            },
            oninput: (event: InputEvent) => {
              if (event.target) {
                const city = handleInputChangedEvent(event.target);
                handlers.handleCityChanged(city);
              }
            },
          },
          dropdownState,
          datalist,
          action: (city: string) => {
            handlers.handleCitySet(city);
          },
          preListBlock: hintElement,
          onunhandledkeydown: onSpecialKey(13, (event) => {
            event.preventDefault();
            handlers.showFeedback();
            handlers.showDropdown();
          }),
          handlers,
        }),
        inputButton({
          ariaLabel: 'Eingabe leeren',
          iconClass: 'bi-trash3',
          class: 'text-dark btn btn-outline-primary ms-0',
          onclick: (event: MouseEvent) => {
            event.stopPropagation();
            handlers.clearCity();
          },
          type: 'button',
        }),
      ]),
    ],
  );
}
