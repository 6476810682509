import { SimpleStore } from '../../state/SimpleStore';
import { RenderResultWithSsr } from '../../lib/renderTypes';
import { extractSearchRequest } from './extractSearchRequest';
import { REGSCHL_HESSEN } from '../../constants';
import {
  PvLagenBackEntry,
  PvLagenNavEntry,
  renderPvLagenNavigation,
} from '../blocks/pvLagenNavigation';
import { State } from '../../state/createInitialState';
import { extractPvLagenByParent } from './extractPvLagenByParent';
import { buildUrl } from '../util/navUrls';
import { FILTER_PVLAGE_CHANGED } from '../../reducer/filterEvents';
import { sortFunctionGerman } from '../../util/localeSortFunctions';
import {
  getLageCode,
  getPvLage,
  getPvLagenLevel,
  getPvLagenParentCode,
  getSonstigePvLageAsChild,
  PV_LAGE_SONSTIGE_SUFFIX,
  PvLagenLevel,
} from '../../util/pvLagenUtil';

export function pvLagenNavigation(
  simpleStore: SimpleStore,
  showBackLink: boolean,
  parentPvLage: string | undefined,
  currentPvLagenCode: string | undefined,
): RenderResultWithSsr[] {
  const state = simpleStore.getState();
  const forRequest = extractSearchRequest(state);
  const regschl = forRequest.regschl || REGSCHL_HESSEN;

  const navData = prepareDataForNav({
    simpleStoreForHandlers: simpleStore,
    state,
    parentPvLage,
    currentPvLage: currentPvLagenCode,
    pvLagenCounts: state.pvLagen.countsByRegschlAndCode[regschl] ?? {},
  });
  let backLink: PvLagenBackEntry | undefined;
  const parentOfCurrentPvLage = currentPvLagenCode
    ? getPvLagenParentCode(currentPvLagenCode)
    : undefined;
  if (parentPvLage && parentOfCurrentPvLage && showBackLink) {
    const parent = getPvLage(state.pvLagen.pvLagenByCode, parentPvLage);
    backLink = {
      iconSvgSafeHtml: parent?.iconSvgSafeHtml,
      name: parent?.name ?? parentPvLage,
      url: buildUrl(simpleStore, [
        FILTER_PVLAGE_CHANGED(parentOfCurrentPvLage),
      ]),
    };
  } else {
    backLink = undefined;
  }
  return renderPvLagenNavigation(backLink, navData);
}

export function pvLagenNavigationForSearch(
  simpleStore: SimpleStore,
): RenderResultWithSsr[] {
  const state = simpleStore.getState();

  const requestPvLagenCode = state.search.request.filter.pvLage;
  let currentPvLage: string | undefined;
  let parentPvLage: string | undefined;
  let backToPvLage: string | undefined;
  const pvLagenLevel = requestPvLagenCode
    ? getPvLagenLevel(requestPvLagenCode)
    : undefined;
  if (pvLagenLevel === PvLagenLevel.SUBLAGE) {
    currentPvLage = requestPvLagenCode;
    parentPvLage = getLageCode(requestPvLagenCode);
    backToPvLage = parentPvLage;
  } else {
    currentPvLage = requestPvLagenCode;
    parentPvLage = requestPvLagenCode;
    backToPvLage = currentPvLage
      ? getPvLagenParentCode(currentPvLage)
      : undefined;
  }

  let backLink: PvLagenBackEntry | undefined;
  if (parentPvLage && backToPvLage) {
    const parent = getPvLage(state.pvLagen.pvLagenByCode, parentPvLage);
    backLink = {
      iconSvgSafeHtml: parent?.iconSvgSafeHtml,
      name: parent?.name ?? parentPvLage,
      url: buildUrl(simpleStore, [FILTER_PVLAGE_CHANGED(backToPvLage)]),
    };
  } else {
    backLink = undefined;
  }

  const navData = prepareDataForNav({
    currentPvLage,
    parentPvLage,
    pvLagenCounts: state.search.results.data?.pvLagenCounts ?? {},
    simpleStoreForHandlers: simpleStore,
    state,
  });
  return renderPvLagenNavigation(backLink, navData);
}

function prepareDataForNav({
  currentPvLage,
  parentPvLage,
  pvLagenCounts,
  simpleStoreForHandlers,
  state,
}: {
  currentPvLage: string | undefined;
  parentPvLage: string | undefined;
  pvLagenCounts: Partial<Record<string, number>>;
  simpleStoreForHandlers: SimpleStore;
  state: State;
}): PvLagenNavEntry[] {
  const pvLagenByParent = extractPvLagenByParent(state);
  const lagenCodesForNav = pvLagenByParent.get(parentPvLage ?? '') ?? [];
  const parentPvLageLevel = parentPvLage && getPvLagenLevel(parentPvLage);
  const pvLagen = state.pvLagen.pvLagenByCode;
  let result = lagenCodesForNav
    .map((code) => {
      const lage = getPvLage(pvLagen, code) ?? {
        iconSvgSafeHtml: undefined,
        name: code,
      };
      return {
        count: pvLagenCounts[code],
        current: code === currentPvLage,
        iconSvgSafeHtml: lage.iconSvgSafeHtml,
        name: lage.name,
        url: buildUrl(simpleStoreForHandlers, [FILTER_PVLAGE_CHANGED(code)]),
      };
    })
    .toSorted((a, b) => sortFunctionGerman(a.name, b.name));
  if (
    (parentPvLageLevel === undefined ||
      parentPvLageLevel === PvLagenLevel.BEREICH) &&
    pvLagenCounts[PV_LAGE_SONSTIGE_SUFFIX]
  ) {
    // es wurden Eintröge für PV_LAGE_SONSTIGE_SUFFIX gefunden
    const lageForNav = getSonstigePvLageAsChild(parentPvLage);
    result = [
      ...result,
      {
        count: pvLagenCounts[PV_LAGE_SONSTIGE_SUFFIX],
        current: lageForNav === currentPvLage,
        iconSvgSafeHtml: undefined,
        name: PV_LAGE_SONSTIGE_SUFFIX,
        url: buildUrl(simpleStoreForHandlers, [
          FILTER_PVLAGE_CHANGED(lageForNav),
        ]),
      },
    ];
  }
  return result;
}
