import type { Dispatch } from 'redux';
import {
  DATA_LIST_MARKER,
  SEARCH_DELAY,
  SERVER_URL_SUCHE_ORG_VORSCHLAG,
  SERVER_URL_SUCHE_ORG_VORSCHLAG_AUSWAHL_STATISTIC,
  SERVER_URL_SUCHE_VORSCHLAG,
  SERVER_URL_SUCHE_VORSCHLAG_AUSWAHL_STATISTIC,
  TypeFilter,
} from '../../constants';
import { SCREEN_CHANGED } from '../../reducer/events';
import type { State } from '../../state/createInitialState';
import { deepEquals } from '../../util/deepEquals';
import { logError } from '../../util/logError';
import { UnreachableCaseError } from '../../util/UnreachableCaseError';
import { isValidForSearchingResult } from '../../validation/validateInput';
import { Screen } from '../../view';
import { buildSuggestion } from '../../view/read/extractDropDownOptions';
import { showLeistung } from '../detail/showLeistung';
import { showOrg } from '../detail/showOrg';
import { get, isAbortError, isErrorWithStatus } from '../util/fetch';
import { fetchStatistics } from '../util/fetchStatistics';
import handleInputChangedEvent from '../util/handleInputChangedEvent';
import { clearSearch } from './clearSearch';
import { hideSearchResults } from './hideSearchResults';
import { abortSearches } from './searchCommon';
import {
  SEARCH_SUGGESTION_ABORTED,
  SEARCH_SUGGESTION_ERROR,
  SEARCH_SUGGESTION_FINISHED,
  SEARCH_SUGGESTION_NOT_FOUND,
  SEARCH_SUGGESTION_STARTED,
  SEARCH_TERM_CHANGED,
  SEARCH_TERM_TYPED,
} from './searchEvents';
import { searchFromInput } from './searchFromInput';
import type {
  SearchSuggestion,
  SearchSuggestionFromServer,
  SearchSuggestionRequest,
} from './searchTypes';
import { SearchSuggestionType } from './searchTypes';
import type { SimpleStore } from '../../state/SimpleStore';
import { abortRequestFor } from '../util/abortRequestFor';

/** Behandelt eine Änderung des Suchfelds */
export function handleSearchChanged(
  simpleStore: SimpleStore,
  input: EventTarget | HTMLInputElement | null,
): void {
  if (input) {
    const inputEl = input as HTMLInputElement;
    const cursorPos = inputEl.selectionStart;
    if (inputEl.value) {
      abortSearches(simpleStore);
      const valueWithMarker = handleInputChangedEvent(input);
      const value = valueWithMarker.endsWith(DATA_LIST_MARKER)
        ? valueWithMarker.substring(
            0,
            valueWithMarker.length - DATA_LIST_MARKER.length,
          )
        : valueWithMarker;
      const fromSuggestions =
        value.length === valueWithMarker.length - DATA_LIST_MARKER.length;

      if (fromSuggestions) {
        const suggestionNavigates = suggestionSelected(simpleStore, value);
        if (!suggestionNavigates) {
          simpleStore.dispatch(SEARCH_TERM_CHANGED(value));
          searchFromInput(simpleStore);
        }
        return;
      }
      simpleStore.dispatch(SEARCH_TERM_TYPED(value));
      // Suchergebnisse ausblenden, da sie nicht mehr zum Suchbegriff passen
      hideSearchResults(simpleStore);
      if (cursorPos) {
        // wichtig: dieser Aufruf muss nach "handleBaseInputChanged" geschehen, da searchSuggestions den aktuellen Input aus
        // dem state für die Vorschlagssuche benötigt
        searchSuggestions(simpleStore, cursorPos);
      }
    } else {
      handleSearchCleared(simpleStore);
    }
  }
}

async function sendSuggestionSelectedStatistics(
  state: State,
  suggestion: SearchSuggestion,
): Promise<void> {
  if (suggestion.key) {
    let url: string | undefined;
    switch (state.screen) {
      case Screen.OrgSuche:
        url = SERVER_URL_SUCHE_ORG_VORSCHLAG_AUSWAHL_STATISTIC();
        break;
      case Screen.Startseite:
      case Screen.Bereich:
      case Screen.Lage:
      case Screen.Sublage:
      case Screen.Suche:
        url = SERVER_URL_SUCHE_VORSCHLAG_AUSWAHL_STATISTIC();
        break;
      case Screen.Leistung:
      case Screen.Org:
      case Screen.Information:
        break;
      default:
        throw new UnreachableCaseError(state.screen);
    }
    if (url) {
      switch (suggestion.type) {
        case SearchSuggestionType.LEISTUNG:
        case SearchSuggestionType.INFO:
        case SearchSuggestionType.DIENSTSTELLE:
          await fetchStatistics({
            url,
            data: {
              suche: state.search.request.searchTerm,
              regschl: state.citySearch.selectedOrt.id,
              id: suggestion.key,
              type: suggestion.type,
              title: suggestion.value,
              places:
                suggestion.places.map((place) => place.id).join(',') || '',
            },
          });
          break;
        case SearchSuggestionType.WORD:
          // do nothing
          break;
        default:
          throw new UnreachableCaseError(suggestion.type);
      }
    }
  }
}

function suggestionSelected(simpleStore: SimpleStore, value: string): boolean {
  const state = simpleStore.getState();
  const matchingSuggestion = findMatchingSuggestion(state, value);
  if (matchingSuggestion) {
    if (matchingSuggestion.key) {
      // nur wenn ein key vorhanden ist, prüfe, ob direkt auf das entsprechende Element navigiert werden kann
      // andernfalls kann keine direkte Navigation stattfinden
      const { key } = matchingSuggestion;

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      sendSuggestionSelectedStatistics(state, matchingSuggestion);

      switch (state.screen) {
        case Screen.Leistung:
        case Screen.Org:
        case Screen.Information:
          // auf diesen Seiten gibt es keine Vorschläge
          return false;
        case Screen.OrgSuche:
        case Screen.Startseite:
        case Screen.Bereich:
        case Screen.Lage:
        case Screen.Sublage:
        case Screen.Suche:
          break;
        default:
          throw new UnreachableCaseError(state.screen);
      }

      switch (matchingSuggestion.type) {
        case SearchSuggestionType.LEISTUNG:
          showLeistung(simpleStore, {
            leistung_id: key,
            regschl: state.citySearch.selectedOrt.id,
          });
          return true;
        case SearchSuggestionType.INFO:
          if (!simpleStore.navigate(key)) {
            document.location.assign(key);
          }
          return true;
        case SearchSuggestionType.DIENSTSTELLE:
          showOrg(simpleStore, { org_id: key });
          return true;
        case SearchSuggestionType.WORD:
          // do nothing
          break;
        default:
          throw new UnreachableCaseError(matchingSuggestion.type);
      }
    }
  }
  return false;
}

export function findMatchingSuggestion(
  state: State,
  value: string,
): SearchSuggestion | undefined {
  const suggestions = state.search.suggestionData;
  return suggestions.find((suggestion) =>
    buildSuggestion(value, suggestion).find((option) => option.value === value),
  );
}

export function handleSearchSet(simpleStore: SimpleStore, value: string): void {
  abortSearches(simpleStore);
  hideSearchResults(simpleStore);
  const valueWithoutMarker = value.substring(
    0,
    value.length - DATA_LIST_MARKER.length,
  );
  const suggestionNavigates = suggestionSelected(
    simpleStore,
    valueWithoutMarker,
  );
  if (!suggestionNavigates) {
    simpleStore.dispatch(SEARCH_TERM_CHANGED(valueWithoutMarker));
    searchFromInput(simpleStore);
  }
}

export function handleSearchCleared(simpleStore: SimpleStore): void {
  abortSearches(simpleStore);
  hideSearchResults(simpleStore);
  clearSearch(simpleStore);
}

function abortSearchSuggestion(state: State, dispatch: Dispatch) {
  if (state.abortRequest.searchSuggestion) {
    abortRequestFor(state.abortRequest.searchSuggestion);
    dispatch(SEARCH_SUGGESTION_ABORTED());
  }
}

let suggestionTimer: NodeJS.Timeout;

export function searchSuggestions(
  simpleStore: SimpleStore,
  cursorPos: number,
): void {
  const state = simpleStore.getState();
  const { searchTerm } = state.search.request;

  abortSearchSuggestion(state, simpleStore.dispatch);
  clearTimeout(suggestionTimer);

  if (
    isValidForSearchingResult({
      searchTerm,
    })
  ) {
    // Alle Eingabefelder sind gültig
    let url: string;
    let type: TypeFilter[];
    switch (state.screen) {
      case Screen.OrgSuche:
        url = SERVER_URL_SUCHE_ORG_VORSCHLAG();
        type = [TypeFilter.orgeinheit];
        break;
      case Screen.Startseite:
      case Screen.Bereich:
      case Screen.Lage:
      case Screen.Sublage:
      case Screen.Suche:
        url = SERVER_URL_SUCHE_VORSCHLAG();
        type = state.search.request.filter.type;
        break;
      case Screen.Leistung:
      case Screen.Org:
      case Screen.Information:
        return;
      default:
        throw new UnreachableCaseError(state.screen);
    }

    suggestionTimer = setTimeout(() => {
      const [startPos, term] = getWordAtCursorPos(searchTerm, cursorPos);
      const regschl = state.citySearch.selectedOrt.id;
      const request = {
        suche: searchTerm,
        wort: term !== searchTerm ? term : undefined,
        typ: type,
        regschl,
      };
      if (isValidForSearchingResult({ searchTerm })) {
        const { abortRequest, result } = get<
          SearchSuggestionRequest,
          SearchSuggestionFromServer[]
        >({
          url,
          data: request,
          flags: state.flags,
        });
        abortRequestFor(state.abortRequest.searchSuggestion);
        simpleStore.dispatch(
          SEARCH_SUGGESTION_STARTED({
            abortRequest,
            forRequest: request,
          }),
        );
        result
          .then((data: SearchSuggestionFromServer[]) => {
            const latestRequest =
              simpleStore.getState().abortRequest.searchSuggestion?.forRequest;
            if (deepEquals(latestRequest, request)) {
              simpleStore.dispatch(
                SEARCH_SUGGESTION_FINISHED({ data, position: startPos, term }),
              );
            }
          })
          .catch((e: unknown) => {
            const latestRequest =
              simpleStore.getState().abortRequest.searchSuggestion?.forRequest;
            if (deepEquals(latestRequest, request)) {
              // request war nicht erfolgreich
              if (isAbortError(e)) {
                // Request wurde absichtlich (abortRequest()) abgebrochen --> nichts zu tun
              } else if (isErrorWithStatus(e) && e.status === 404) {
                // keine Daten gefunden
                simpleStore.dispatch(SEARCH_SUGGESTION_NOT_FOUND(request));
              } else {
                // sonstiger Fehler
                logError(e);
                simpleStore.dispatch(SEARCH_SUGGESTION_ERROR(e));
              }
            }
          });
      }
    }, SEARCH_DELAY);
  }
}

const regexStart = /[^\s"+-]*$/;
const regexEnd = /^[^\s"+-]*/;

/**
 * Returns the word at the given position
 * @param text The text where the word is extracted
 * @param cursorPos the position at which to extract the word
 */
export function getWordAtCursorPos(
  text: string,
  cursorPos: number,
): [number, string] {
  const pos = Math.max(0, Math.min(text.length, cursorPos));
  const hasLeft = text.substring(0, pos).match(regexStart);
  const hasRight = text.substring(pos).match(regexEnd);

  if (!hasLeft) {
    throw new Error('Problem with regex for hasLeft -> match returned null');
  }
  if (!hasRight) {
    throw new Error('Problem with regex for hasRight -> match returned null');
  }

  const word = hasLeft[0] + hasRight[0];
  const startPos = hasLeft.index;
  if (startPos === undefined) {
    throw new Error('Problem with regex for hasLeft -> index is undefined');
  }

  return [startPos, word];
}

export function handleStartpageSearchChanged(
  simpleStore: SimpleStore,
  input: EventTarget | HTMLInputElement | null,
): void {
  if (input) {
    const inputEl = input as HTMLInputElement;
    const cursorPos = inputEl.selectionStart;
    abortSearches(simpleStore);
    if (inputEl.value && cursorPos) {
      const value = handleInputChangedEvent(input);
      simpleStore.dispatch(SEARCH_TERM_TYPED(value));
      searchSuggestions(simpleStore, cursorPos);
    } else if (!inputEl.value) {
      clearSearch(simpleStore);
    }
  }
}

export async function handleStartpageSearchSet(
  simpleStore: SimpleStore,
  value: string,
) {
  abortSearches(simpleStore);
  hideSearchResults(simpleStore);
  const valueWithoutMarker = value.substring(
    0,
    value.length - DATA_LIST_MARKER.length,
  );
  const state = simpleStore.getState();
  const matchingSuggestion = findMatchingSuggestion(state, valueWithoutMarker);
  if (matchingSuggestion) {
    if (matchingSuggestion.key) {
      // nur wenn ein key vorhanden ist, prüfe, ob direkt auf das entsprechende Element navigiert werden kann
      // andernfalls kann keine direkte Navigation stattfinden
      const { key } = matchingSuggestion;

      // warte auf das Versenden der Suggestion, da sonst bei einer Navigation das Senden abgebrochen werden könnte
      await sendSuggestionSelectedStatistics(state, matchingSuggestion);

      switch (matchingSuggestion.type) {
        case SearchSuggestionType.LEISTUNG:
          showLeistung(simpleStore, {
            leistung_id: key,
            regschl: state.citySearch.selectedOrt.id,
          });
          break;
        case SearchSuggestionType.INFO:
          window.location.assign(key);
          break;
        case SearchSuggestionType.DIENSTSTELLE:
          showOrg(simpleStore, { org_id: key });
          break;
        case SearchSuggestionType.WORD:
          // never contains keys
          break;
        default:
          throw new UnreachableCaseError(matchingSuggestion.type);
      }
    } else {
      simpleStore.dispatch(SEARCH_TERM_CHANGED(value));
      simpleStore.dispatch(SCREEN_CHANGED(Screen.Suche));
      searchFromInput(simpleStore);
    }
  }
}
