import type { MetricType } from 'web-vitals';

export function reportWebVitals(
  onPerfEntry?: (type: string) => (metric: MetricType) => void,
): void {
  if (onPerfEntry) {
    import('web-vitals')
      .then(({ onCLS, onFCP, onINP, onLCP, onTTFB }) => {
        onTTFB(onPerfEntry('onTTFB'));
        onCLS(onPerfEntry('onCLS'));
        onFCP(onPerfEntry('onFCP'));
        onLCP(onPerfEntry('onLCP'));
        onINP(onPerfEntry('onINP'));
      })
      .catch(() => {
        // ignore errors loading web-vitals
      });
  }
}
