import { RenderResultWithSsr } from '../../../lib/renderTypes';
import { button, div, h2 } from '../../../render/html';
import { BUTTON_CLOSE } from '../../../texts';
import { DANGEROUSLY_HTML_CONTENT } from '../../../render/incremental-hyperscript';

export const ID_ASK_PRIVACY_MODAL = 'hzd_vwp_ask_privacy_modal';
const ID_ASK_PRIVACY_TITLE = 'hzd_vwp_chatbot_ask_privacy_title';

/**
 * Rendert ein modales Overlay
 *
 * @param {string} title
 * @param {string} contentSafeHtml Inhalt des Overlays als HTML-String
 * @param {RenderResultWithSsr[]} buttons Render-Funktion für die Buttons
 * @returns {{(): (undefined | Tag | TextContent)}} Render-Funktion
 */
export function renderModal(
  title: string,
  contentSafeHtml: string,
  buttons: RenderResultWithSsr[],
): RenderResultWithSsr {
  return div(
    {
      id: ID_ASK_PRIVACY_MODAL,
      class: 'modal fade',
      // Workaround für Bug in Safari, wird sonst beim Chatbot so gerendert, als
      // wären diese Werte nicht vorhanden (kommen aber eigentlich aus der
      // modal-Klasse)
      style: 'z-index: 1055; position: fixed',
      tabindex: '-1',
      'aria-labelledby': ID_ASK_PRIVACY_TITLE,
    },
    [
      div({ class: 'modal-dialog' }, [
        div({ class: 'modal-content' }, [
          div({ class: 'modal-header' }, [
            h2({ class: 'modal-title', id: ID_ASK_PRIVACY_TITLE }, [title]),
            button({
              type: 'button',
              class: 'btn-close',
              'data-bs-dismiss': 'modal',
              'aria-label': BUTTON_CLOSE,
            }),
          ]),
          div({
            class: 'modal-body font-font-roc-grotesk-w05-regular',
            [DANGEROUSLY_HTML_CONTENT]: contentSafeHtml,
          }),
          div({ class: 'modal-footer' }, buttons),
        ]),
      ]),
    ],
  );
}
