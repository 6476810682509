import { createEvent, TriggeredBy } from '../../reducer/createEvent';
import type {
  HtmlPatchInformation,
  HtmlPatchOrg,
  HtmlPatchStartseite,
  HtmlPatchLeistung,
} from '../htmlTypes';
import type { LeistungRequestWithRegschl, OrgRequest } from './detailTypes';
import type { InformationRequest } from './InformationRequest';

/** Detaildaten für Informationsseite wurden geladen */
export const INFORMATION_FINISHED = createEvent<{
  forRequest: InformationRequest;
  patch: HtmlPatchInformation;
}>('INFORMATION_FINISHED', TriggeredBy.Other);
/** Beim Laden der Detaildaten für Informationsseite wurde ein Fehler zurückgeliefert */
export const INFORMATION_ERROR = createEvent<unknown>(
  'INFORMATION_ERROR',
  TriggeredBy.Other,
);
/** Laden der Detaildaten für Informationsseite wurde gestarted */
export const INFORMATION_STARTED = createEvent<{
  abortRequest: () => void;
  forRequest: InformationRequest;
}>('INFORMATION_STARTED', TriggeredBy.Other);

/** Beim Laden der Detaildaten für Leistung wurde ein Fehler zurückgeliefert */
export const LEISTUNG_ERROR = createEvent<unknown>(
  'LEISTUNG_ERROR',
  TriggeredBy.Other,
);
/** Detaildaten für Leistung wurden geladen */
export const LEISTUNG_FINISHED = createEvent<{
  forRequest: LeistungRequestWithRegschl;
  patch: HtmlPatchLeistung;
}>('LEISTUNG_FINISHED', TriggeredBy.Other);
/** Es wurden keine Detaildaten für Leistung gefunden */
export const LEISTUNG_NOT_FOUND = createEvent<LeistungRequestWithRegschl>(
  'LEISTUNG_NOT_FOUND',
  TriggeredBy.Other,
);
/** Detailansicht für Leistung wurde dargestellt */
export const LEISTUNG_SHOWN = createEvent<LeistungRequestWithRegschl>(
  'LEISTUNG_SHOWN',
  TriggeredBy.User,
);
/** Laden der Detaildaten für Leistung wurde gestarted */
export const LEISTUNG_STARTED = createEvent<{
  abortRequest: () => void;
  forRequest: LeistungRequestWithRegschl;
}>('LEISTUNG_STARTED', TriggeredBy.Other);

/** Detaildaten für Org wurden geladen */
export const ORG_FINISHED = createEvent<{
  forRequest: OrgRequest;
  patch: HtmlPatchOrg;
}>('ORG_FINISHED', TriggeredBy.Other);
/** Es wurden keine Detaildaten für Org gefunden */
export const ORG_NOT_FOUND = createEvent<OrgRequest>(
  'ORG_NOT_FOUND',
  TriggeredBy.Other,
);
/** Beim Laden der Detaildaten für Org wurde ein Fehler zurückgeliefert */
export const ORG_ERROR = createEvent<unknown>('ORG_ERROR', TriggeredBy.Other);
/** Detailansicht für Org wurde dargestellt */
export const ORG_SHOWN = createEvent<OrgRequest>('ORG_SHOWN', TriggeredBy.User);
/** Laden der Detaildaten für Org wurde gestarted */
export const ORG_STARTED = createEvent<{
  abortRequest: () => void;
  forRequest: OrgRequest;
}>('ORG_STARTED', TriggeredBy.Other);

/** Detaildaten für Startseite wurden geladen */
export const STARTSEITE_FINISHED = createEvent<HtmlPatchStartseite>(
  'STARTSEITE_FINISHED',
  TriggeredBy.Other,
);
/** Beim Laden der Detaildaten für Startseite wurde ein Fehler zurückgeliefert */
export const STARTSEITE_ERROR = createEvent<unknown>(
  'STARTSEITE_ERROR',
  TriggeredBy.Other,
);
/** Detailansicht für Startseite wurde dargestellt */
export const STARTSEITE_SHOWN = createEvent(
  'STARTSEITE_SHOWN',
  TriggeredBy.User,
);
/** Laden der Detaildaten für Startseite wurde gestarted */
export const STARTSEITE_STARTED = createEvent<() => void>(
  'STARTSEITE_STARTED',
  TriggeredBy.Other,
);
