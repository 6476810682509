import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { div, h1, i, p, span } from '../../render/html';
import orgSearch, { orgResults } from '../blocks/orgSearch';
import type { SimpleStore } from '../../state/SimpleStore';
import {
  ORG_SEARCH_EXPLANATION,
  ORG_SEARCH_FIELD_LABEL,
  ORG_SEARCH_FILTER_EXPLANATION,
  ORG_SEARCH_RESULT_EXPLANATION,
  ORG_SEARCH_TITLE,
  SEARCH_HAS_CONTACT,
} from '../../texts';
import h from '../../render/incremental-hyperscript';
import { share } from '../share';

export default function mainContent(
  simpleStore: SimpleStore,
): RenderResultWithSsr {
  const state = simpleStore.getState();
  return h.fragment([
    share(state.flags.share_generic === 'show', {
      title: document.title,
      url: String(document.location),
    }),
    div(
      { key: 'dynamic' },
      h1({ class: 'd-flex column-gap-2 text-break' }, [
        i(
          {
            class: `bi bi-buildings icon`,
          },
          span(
            {
              class: 'visually-hidden',
            },
            [SEARCH_HAS_CONTACT],
          ),
        ),
        ORG_SEARCH_TITLE,
      ]),
      p(
        { class: 'font-font-roc-grotesk-w05-regular' },
        ORG_SEARCH_EXPLANATION,
        ' ',
        ORG_SEARCH_FILTER_EXPLANATION,
      ),
      orgSearch('og-org-search', simpleStore, ORG_SEARCH_FIELD_LABEL),
      orgResults('', simpleStore, ORG_SEARCH_RESULT_EXPLANATION),
    ),
  ]);
}
