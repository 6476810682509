import { SEARCH_TERM_CHANGED } from '../../../action/search/searchEvents';
import { searchFromDidYouMean } from '../../../action/search/searchFromDidYouMean';
import type {
  SearchFilterType,
  SpellcheckSuggestion,
} from '../../../action/search/searchTypes';
import type {
  RenderElement,
  RenderParam,
  RenderResultWithSsr,
} from '../../../lib/renderTypes';
import { polyfillRegExp } from '../../../polyfills';
import { li, p, span, ul } from '../../../render/html';
import type { State } from '../../../state/createInitialState';
import { linkInternStandalone } from '../../elements/linkInternInline';
import escapeForRegexp from '../../util/escapeForRegexp';
import { buildUrl } from '../../util/navUrls';
import type { SimpleStore } from '../../../state/SimpleStore';

function getWordBoundaryRegExp(word: string) {
  return polyfillRegExp(
    `(^|[^\\p{L}\\p{N}])${escapeForRegexp(word)}([^\\p{L}\\p{N}]|$)`,
    `ug`,
  );
}

function getWordsBoundaryRegExp(words: string[]) {
  return polyfillRegExp(
    `(^|[^\\p{L}\\p{N}])(?:(?:${words
      .map((word) => escapeForRegexp(word))
      .join(')|(?:')}))([^\\p{L}\\p{N}]|$)`,
    `ug`,
  );
}

function renderDidYouMean({
  simpleStore,
  didYouMean,
  searchTerm,
}: {
  simpleStore: SimpleStore;
  didYouMean: SpellcheckSuggestion[];
  filter: SearchFilterType;
  searchTerm: string;
  regschl?: string;
}): RenderParam {
  const indexAndSuggestionList: [number, RenderElement][] = didYouMean.map(
    ({ words, suggestion }: SpellcheckSuggestion) => {
      if (words.length) {
        const [firstWord, ...restWords] = words;
        const wordRegExp = getWordBoundaryRegExp(firstWord);
        const restRegExp = getWordsBoundaryRegExp(restWords);
        const newSearchTerm = searchTerm.replace(
          wordRegExp,
          (match, boundary1: string, boundary2: string) =>
            boundary1 + suggestion + boundary2,
        );
        const textItemsUnchanged = searchTerm.split(wordRegExp);

        const [
          textItemsUnchangedHead1,
          textItemsUnchangedHead2,
          ...textItemsUnchangedTail
        ] = textItemsUnchanged;
        const textItems = [
          ...textItemsUnchangedHead1.split(restRegExp),
          textItemsUnchangedHead2,
          span({ class: 'a-bold' }, suggestion),
          ...textItemsUnchangedTail.flatMap((value) => value.split(restRegExp)),
        ];
        return [
          searchTerm.search(wordRegExp),
          li({ class: 'text' }, [
            linkInternStandalone(
              {
                href: buildUrl(simpleStore, [
                  SEARCH_TERM_CHANGED(newSearchTerm),
                ]),
                onclick: (event: MouseEvent) => {
                  searchFromDidYouMean(simpleStore, event, newSearchTerm);
                },
              },
              textItems,
            ),
          ]),
        ];
      } else {
        return [0, undefined];
      }
    },
  );
  return indexAndSuggestionList
    .filter((value) => value[1] !== undefined)
    .sort(([indexa], [indexb]) => -(indexa - indexb))
    .map((value) => value[1]);
}

export function askDidYouMean({
  simpleStore,
  state,
}: {
  simpleStore: SimpleStore;
  state: State;
}): RenderResultWithSsr[] {
  const didYouMean = state.search.results.data?.didYouMean;
  const dataFor = state.search.results.forRequest;
  if (!didYouMean || !didYouMean.length || !dataFor) {
    return [];
  } else {
    const { filter, searchTerm } = dataFor;
    const regschl = state.citySearch.selectedOrt.id;
    const items = renderDidYouMean({
      simpleStore,
      didYouMean,
      filter,
      searchTerm,
      regschl,
    });
    return [
      p({ class: 'paragraph' }, 'Möchten Sie nach folgendem suchen?'),
      ul({}, items),
    ];
  }
}
