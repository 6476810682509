import type { ActionCreatorWithPreparedPayload } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';

/** Wurde der Event direkt vom User oder vom Code ausgelöst? */
export enum TriggeredBy {
  User = 'user',
  Other = 'other',
}

/**
 * Ist dies ein „unwichtiger“ Event, wie z.B. ein Tastendruck, der nicht einzeln
 * in der Browser-URL-Historie behalten werden soll?
 */
export enum Volatile {
  False = 0,
  True = 1,
}

export type HzdEventMeta = {
  meta: {
    triggeredBy: TriggeredBy;
    volatile: Volatile;
  };
  type: string;
};

/**
 * Erzeugt ein Redux-Event („Action“)
 */
export function createEvent<A = void, T extends string = string>(
  name: T,
  triggeredBy: TriggeredBy,
  volatile = Volatile.False,
): ActionCreatorWithPreparedPayload<
  [A],
  A,
  T,
  never,
  { triggeredBy: TriggeredBy; volatile: Volatile }
> {
  return createAction(name, (payload: A) => ({
    payload,
    meta: { triggeredBy, volatile },
  }));
}
