/* eslint-disable no-inner-declarations */
/* Hauptdatei.
 *
 * Enthält Polyfills und konfiguriert Bibliotheken.
 *
 * Startet die App
 */

// polyfills for IE
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
// import 'setimmediate';

// imports
import 'setimmediate';
import type { MetricType } from 'web-vitals';
import app from './app';
import { SW_URL } from './constants';
import './css_settings.css';
import { reportWebVitals } from './lib/reportWebVitals';
import { activatePolyFills } from './polyfills';
import { customizeIncrementalDom } from './render/customize-incremental-dom';
import * as serviceWorker from './serviceWorker';
import { createResolvablePromise } from './util/createResolvablePromise';

customizeIncrementalDom();

// initialize application
const appUpdatedPromise = createResolvablePromise();

async function startApp() {
  await activatePolyFills();

  app(appUpdatedPromise.promise);
}

function handleServiceWorkerUpdated(registration: ServiceWorkerRegistration) {
  const { waiting } = registration;
  if (waiting) {
    waiting.postMessage({ type: 'SKIP_WAITING' });
  }
  appUpdatedPromise.resolve();
}

try {
  serviceWorker.register({
    onUpdate: handleServiceWorkerUpdated,
    url: SW_URL(),
  });
} catch (error) {
  console.error(error);
}

if (import.meta.env.DEV) {
  if (window.hzd_og_init_defer) {
    window.hzd_og_init_start_app = startApp;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    startApp();
  }
} else {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  startApp();
}

function report(type: string) {
  return (metric: MetricType) => {
    console.log(type, metric);
  };
}
reportWebVitals(report);
