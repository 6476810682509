/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { State } from '../state/createInitialState';
import {
  CHATBOT_LOADING_FAILED,
  CHATBOT_LOADING_FINISHED,
  CHATBOT_LOADING_STARTED,
  CHATBOT_PRIVACY_ACCEPTED,
} from './chatbotEvents';

export function createChatbotReducer(builder: ActionReducerMapBuilder<State>) {
  builder.addCase(CHATBOT_LOADING_FAILED, (state) => {
    state.chatbot.loading = false;
  });
  builder.addCase(CHATBOT_LOADING_FINISHED, (state) => {
    state.chatbot.loaded = true;
    state.chatbot.loading = false;
  });
  builder.addCase(CHATBOT_LOADING_STARTED, (state) => {
    state.chatbot.loading = true;
  });
  builder.addCase(CHATBOT_PRIVACY_ACCEPTED, (state) => {
    state.chatbot.privacyAccepted = true;
  });
}
