import lodashMerge from 'lodash/merge';
import type { HtmlString } from '../action/htmlTypes';
import { HtmlHeadElement } from '../action/htmlTypes';
import {
  SERVER_CANONICAL_PREFIX,
  SERVER_ROOT_PUBLIC,
  SERVER_SETTINGS,
} from '../constants';
import { getPatchToShow } from '../lib/htmlCache';
import type {
  RenderFunctionType,
  RenderResultWithSsr,
} from '../lib/renderTypes';
import type { HtmlOrRender } from '../render/cached-incremental-dom';
import {
  frameSelector,
  patchInitialHtml,
} from '../render/cached-incremental-dom';
import { div, link, meta } from '../render/html';
import { DANGEROUSLY_HTML_CONTENT } from '../render/incremental-hyperscript';
import { TITLE_FALLBACK } from '../texts';
import { filterUndefinedOrEmptyAttributes } from '../util/filterUndefinedOrEmptyAttributes';
import { UnreachableCaseError } from '../util/UnreachableCaseError';
import { Screen } from '../view';
import { bereichsseite } from './screen/bereichsseite';
import { lagenseite } from './screen/lagenseite';
import { searchResultsNavigation } from './blocks/search';
import { patchShare } from './share';
import { sublagenseite } from './screen/sublagenseite';
import './main.css';
import orgSearch from './screen/orgSuche';
import { startseite } from './screen/startseite';
import search from './screen/suche';
import { renderFeedback } from './blocks/nfk/renderFeedback';
import type { SimpleStore } from '../state/SimpleStore';
import { leistungsseite } from './screen/leistung';
import { contentLeft } from './frame/contentLeft';
import { renderChatbot } from './blocks/chatbot/renderChatbot';

type HtmlPatchToRender = {
  asideHtml?: HtmlOrRender;
  breadcrumbHtml?: HtmlOrRender;
  chatbotHtml?: HtmlOrRender;
  contentHtml?: HtmlOrRender;
  feedbackComponentHtml?: HtmlOrRender;
  footerHtml?: HtmlOrRender;
  navigationHtml?: HtmlOrRender;
  htmlHead: {
    [prop in HtmlHeadElement]?: string;
  };
};

function htmlOrRender(
  renderFn: RenderFunctionType,
  properties: Partial<Record<string, string | undefined>>,
  htmlOrRenderValue?: HtmlOrRender,
) {
  if (htmlOrRenderValue && 'safeHtml' in htmlOrRenderValue) {
    return renderFn({
      ...filterUndefinedOrEmptyAttributes(properties),
      [DANGEROUSLY_HTML_CONTENT]: htmlOrRenderValue.safeHtml,
    });
  } else {
    return renderFn(
      filterUndefinedOrEmptyAttributes(properties),
      htmlOrRenderValue,
    );
  }
}

function patchRobotsHeader(robots: string | undefined): void {
  const currentElement = (document.querySelector(frameSelector.robots) ??
    undefined) as HTMLMetaElement | undefined;
  const currentValue = currentElement?.content;
  if (robots === undefined) {
    if (currentElement !== undefined) {
      currentElement.remove();
    }
  } else if (currentValue !== robots) {
    let newElement: HTMLMetaElement | undefined;
    if (currentElement === undefined) {
      newElement = document.createElement('meta');
      newElement.name = 'robots';
      document.head.appendChild(newElement);
    } else {
      newElement = currentElement;
    }
    newElement.content = robots;
  }
}

export default function renderMain(simpleStore: SimpleStore) {
  const state = simpleStore.getState();
  const { htmlFrame, screen } = state;
  const { matches, cacheEntry } = getPatchToShow(state);
  const loading = !matches;
  let outerElementsToPatch: HtmlPatchToRender;
  let innerMappings: Partial<Record<string, RenderResultWithSsr>> = {};
  const patch = cacheEntry?.patch ?? {
    data: { screen },
    pageElements: undefined,
  };
  const isStartPage = patch.data.screen === Screen.Startseite;
  const defaults: HtmlPatchToRender = {
    ...lodashMerge(
      {},
      {
        htmlHead: {
          [HtmlHeadElement.TITLE]: TITLE_FALLBACK,
        },
      },
      patch.pageElements ?? {},
    ),
    chatbotHtml:
      cacheEntry &&
      renderChatbot(
        simpleStore.dispatch,
        state.flags,
        cacheEntry,
        SERVER_SETTINGS().chatbotScriptSrc,
        state.chatbot,
        SERVER_SETTINGS().chatbotBodySafeHtml,
        SERVER_SETTINGS().chatbotSelectors ?? [],
      ),
    feedbackComponentHtml:
      cacheEntry &&
      renderFeedback(
        simpleStore.dispatch,
        state.flags,
        cacheEntry,
        SERVER_SETTINGS().nfkScriptSrc,
        SERVER_SETTINGS().nfkSettings,
        state.nfk,
      ),
    footerHtml: htmlFrame.footer,
  };
  let navigationIsFixed: boolean;
  switch (patch.data.screen) {
    case Screen.Suche: {
      const { searchTerm } = state.search.request;
      const drupalTitle = patch.pageElements?.drupalTitle;
      const title =
        Boolean(searchTerm) && drupalTitle !== undefined
          ? `${searchTerm} - ${drupalTitle}`
          : (drupalTitle ?? searchTerm);
      outerElementsToPatch = lodashMerge({}, defaults, {
        contentHtml: search(simpleStore),
        htmlHead: { [HtmlHeadElement.TITLE]: title },
        navigationHtml: contentLeft(searchResultsNavigation(simpleStore)),
      });
      navigationIsFixed = false;
      break;
    }
    case Screen.Bereich:
      outerElementsToPatch = defaults;
      innerMappings = bereichsseite(simpleStore);
      navigationIsFixed = false;
      break;
    case Screen.Lage:
      outerElementsToPatch = defaults;
      innerMappings = lagenseite(simpleStore);
      navigationIsFixed = false;
      break;
    case Screen.Sublage:
      outerElementsToPatch = defaults;
      innerMappings = sublagenseite(simpleStore);
      navigationIsFixed = false;
      break;
    case Screen.Leistung:
      outerElementsToPatch = defaults;
      innerMappings = leistungsseite(
        simpleStore,
        patch.pageElements ? patch.data : undefined,
      );
      navigationIsFixed = true;
      break;
    case Screen.Org:
      outerElementsToPatch = defaults;
      navigationIsFixed = true;
      break;
    case Screen.OrgSuche: {
      const { searchTerm } = state.search.request;
      const drupalTitle = patch.pageElements?.drupalTitle;
      const title =
        Boolean(searchTerm) && drupalTitle !== undefined
          ? `${searchTerm} - ${drupalTitle}`
          : (drupalTitle ?? searchTerm);
      outerElementsToPatch = lodashMerge({}, defaults, {
        contentHtml: orgSearch(simpleStore),
        htmlHead: { [HtmlHeadElement.TITLE]: title },
      });
      navigationIsFixed = false;
      break;
    }
    case Screen.Startseite:
      outerElementsToPatch = defaults;
      innerMappings = startseite(
        simpleStore,
        patch.pageElements?.drupalTitle ?? '',
      );
      navigationIsFixed = false;
      break;
    case Screen.Information:
      outerElementsToPatch = defaults;
      navigationIsFixed = false;
      break;
    default:
      throw new UnreachableCaseError(patch.data);
  }

  const headMappings = {
    [frameSelector.meta_description]: htmlOrRender(meta, {
      name: 'description',
      content: outerElementsToPatch.htmlHead[HtmlHeadElement.META_DESCRIPTION],
    }),
    [frameSelector.meta_keywords]: htmlOrRender(meta, {
      name: 'keywords',
      content: outerElementsToPatch.htmlHead[HtmlHeadElement.META_KEYWORDS],
    }),
    [frameSelector.canonical_link]: htmlOrRender(link, {
      rel: 'canonical',
      href:
        outerElementsToPatch.htmlHead[HtmlHeadElement.CANONICAL_LINK] ??
        SERVER_CANONICAL_PREFIX() + SERVER_ROOT_PUBLIC,
    }),
    [frameSelector.geo_placename]: htmlOrRender(meta, {
      name: 'geo.placename',
      content: outerElementsToPatch.htmlHead[HtmlHeadElement.GEO_PLACENAME],
    }),
    [frameSelector.geo_position]: htmlOrRender(meta, {
      name: 'geo.position',
      content: outerElementsToPatch.htmlHead[HtmlHeadElement.GEO_POSITION],
    }),
    [frameSelector.geo_region]: htmlOrRender(meta, {
      name: 'geo.region',
      content: outerElementsToPatch.htmlHead[HtmlHeadElement.GEO_REGION],
    }),
    [frameSelector.icbm]: htmlOrRender(meta, {
      name: 'ICBM',
      content: outerElementsToPatch.htmlHead[HtmlHeadElement.ICBM],
    }),
  };
  const frameMappings = {
    [frameSelector.aside]: outerElementsToPatch.asideHtml,
    [frameSelector.breadcrumbFooter]: div(
      { class: 'container-fluid mb-6' },
      div({ class: 'border-top border-primary-brightest mb-4' }),
      div({
        [DANGEROUSLY_HTML_CONTENT]: (
          outerElementsToPatch.breadcrumbHtml as HtmlString | undefined
        )?.safeHtml,
      }),
    ),
    [frameSelector.breadcrumbHeader]: outerElementsToPatch.breadcrumbHtml,
    [frameSelector.chatbot]: outerElementsToPatch.chatbotHtml,
    [frameSelector.content]: outerElementsToPatch.contentHtml,
    [frameSelector.feedback]: outerElementsToPatch.feedbackComponentHtml,
    [frameSelector.footer]: outerElementsToPatch.footerHtml,
    [frameSelector.navigation]: outerElementsToPatch.navigationHtml,
  };

  const focusId = document.activeElement?.id;

  patchInitialHtml(headMappings, frameMappings, innerMappings, loading);
  patchRobotsHeader(outerElementsToPatch.htmlHead[HtmlHeadElement.ROBOTS]);
  if (document.title !== outerElementsToPatch.htmlHead[HtmlHeadElement.TITLE]) {
    document.title = outerElementsToPatch.htmlHead[HtmlHeadElement.TITLE] || '';
  }

  // CSS-Klasse für Header setzen
  const bodyElem = document.body;
  const cssIsSet = bodyElem.classList.contains('is-frontpage');
  if (cssIsSet !== isStartPage) {
    bodyElem.classList.toggle('is-frontpage');
  }

  const classes = ['vwp-sticky-side', 'mb-0'];
  const navigationElement = document.querySelectorAll(frameSelector.navigation);
  Object.values(navigationElement).forEach((element) => {
    if (element instanceof HTMLElement) {
      classes.forEach((classname) => {
        if (navigationIsFixed !== element.classList.contains(classname)) {
          element.classList.toggle(classname);
        }
      });
    }
  });

  // abhängig von document.title, daher als letztes patchen
  patchShare(state.flags.share_generic === 'show');

  // Fokus wieder setzen, falls durch das Patchen des DOMs verloren gegangen
  if (focusId && document.activeElement?.id !== focusId) {
    document.getElementById(focusId)?.focus();
  }
}
