import {
  SELECTED_ORT_HESSEN,
  SERVER_URL_SUCHE_ORT_AUSWAHL_STATISTIC,
  SERVER_URL_SUCHE_ORT_VORSCHLAG_AUSWAHL_STATISTIC,
} from '../../constants';
import {
  CITY_FEEDBACK_HIDDEN,
  CITY_SEARCH_RESET,
  CITY_SEARCH_RESETTED_TO_SELECTED,
  CITY_SEARCH_TERM_TYPED,
  CITY_SELECTED,
} from './searchEvents';
import { DROPDOWN_HIDDEN } from '../../reducer/events';
import { deepEquals } from '../../util/deepEquals';
import { filterUndefinedOrEmptyAttributes } from '../../util/filterUndefinedOrEmptyAttributes';
import { isValidForCitySearch } from '../../validation/validateInput';
import combinePlaceNames, {
  combinePlaceNamesForInput,
} from '../../view/util/combinePlaceNames';
import { loadDataIfNecessary } from '../loadDataIfNecessary';
import { fetchStatistics } from '../util/fetchStatistics';
import { searchCity } from './citySearch';
import { hideSearchResults } from './hideSearchResults';
import { abortSearches, Dropdowns } from './searchCommon';
import type { Gebiet, SelectedOrt } from './searchTypes';
import type { SimpleStore } from '../../state/SimpleStore';

export function handleCityChanged(
  simpleStore: SimpleStore,
  city: string,
  onCitySelected: (() => void) | undefined,
  onlyHessen: boolean,
): void {
  abortSearches(simpleStore);
  const suggestion = getSuggestion(
    simpleStore.getState().citySearch.suggestions.data,
    city,
  );
  simpleStore.dispatch(CITY_FEEDBACK_HIDDEN());
  if (suggestion && city.endsWith('\u2008')) {
    searchWithCity(simpleStore, suggestion, onCitySelected);
  } else {
    simpleStore.dispatch(CITY_SEARCH_TERM_TYPED(city));
    hideSearchResults(simpleStore);
    if (isValidForCitySearch(city)) {
      searchCity(simpleStore, {
        ort: simpleStore.getState().citySearch.input.searchTerm,
        onlyHessen,
      });
    }
  }
}

export function handleCitySet(
  simpleStore: SimpleStore,
  value: string,
  onCitySelected: (() => void) | undefined,
): void {
  abortSearches(simpleStore);
  const suggestion = getSuggestion(
    simpleStore.getState().citySearch.suggestions.data,
    value,
  );
  simpleStore.dispatch(DROPDOWN_HIDDEN(Dropdowns.CITY));
  simpleStore.dispatch(CITY_FEEDBACK_HIDDEN());
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  fetchStatistics({
    url: SERVER_URL_SUCHE_ORT_VORSCHLAG_AUSWAHL_STATISTIC(),
    data: filterUndefinedOrEmptyAttributes({
      suche: simpleStore.getState().citySearch.input.searchTerm,
      id: suggestion?.id,
      ort: suggestion?.ort,
      land: suggestion?.land,
    }),
  });
  searchWithCity(simpleStore, suggestion, onCitySelected);
}

const regexCityWithoutParenthesis = /^[^(]+/;

export function getSuggestion(suggestions: Gebiet[], city: string) {
  const matches = suggestions.filter((entry) => {
    if (
      combinePlaceNames(entry).toLowerCase() === city.toLowerCase().trim() ||
      // falls mit voller Übereinstimmung inklusive Land keine Einträge gefunden wurden, suche mit einfacher
      // Übereinstimmung ohne Land
      (entry.ort && entry.ort.toLowerCase() === city.toLowerCase().trim())
    )
      return true;
    // falls mit einer Übereinstimmung ohne Land keine Einträge gefunden wurden, suche ohne Zusätze in
    // Bezeichnung (wie " (Hessen)" bei Langen)
    if (entry.ort) {
      const regexArray = entry.ort.match(regexCityWithoutParenthesis);
      return (
        regexArray !== null &&
        regexArray[0].toLowerCase().trim() === city.toLowerCase().trim()
      );
    } else {
      return false;
    }
  });

  if (matches.length)
    if (
      matches.length === 1 ||
      // wenn ein Eintrag aus der Drop-Down-Liste ausgewählt wurde, muss auf jeden Fall ein Eintrag gewählt werden, auch
      // wenn dieser nicht eindeutig ist; alles andere würden den Nutzer noch mehr verwirren
      city.endsWith('\u2008')
    )
      return matches[0];
  return undefined;
}

function searchWithCity(
  simpleStore: SimpleStore,
  suggestedCity: Gebiet | undefined,
  onCitySelected: (() => void) | undefined,
) {
  const selectedOrt: SelectedOrt = suggestedCity
    ? {
        loadNeeded: false,
        id: suggestedCity.id,
        ort: suggestedCity.ort,
        verband: suggestedCity.verband,
        kreis: suggestedCity.kreis,
        bezirk: suggestedCity.bezirk,
        land: suggestedCity.land,
        isFindable: true,
      }
    : SELECTED_ORT_HESSEN;
  const state = simpleStore.getState();
  if (
    !deepEquals(state.citySearch.selectedOrt, selectedOrt) ||
    // falls der Nutzer den Begriff geändert hatte, dieser aber zum gleichen Lookup-Wert passt (z.B. wenn der Nutzer
    // zusätzliche Leerzeichen hinzugefügt oder die Groß-/Kleinschreibung geändert hat
    // --> auch dann sollte die Eingabe wieder auf den eigentlichen Ortsnamen gesetzt werden
    state.citySearch.input.searchTerm !== combinePlaceNamesForInput(selectedOrt)
  ) {
    abortSearches(simpleStore);
    simpleStore.dispatch(CITY_SELECTED(selectedOrt));
    if (onCitySelected) onCitySelected();
  }
  // es muss immer geprüft werden, ob Daten geladen werden müssen, da es sein kann, dass noch keine Daten vorhanden sind
  loadDataIfNecessary(simpleStore);
}

export function handleCityBlurred(
  simpleStore: SimpleStore,
  onCitySelected: (() => void) | undefined,
): void {
  simpleStore.dispatch(CITY_FEEDBACK_HIDDEN());
  const state = simpleStore.getState();
  const city = state.citySearch.input.searchTerm;
  if (!city) {
    if (state.citySearch.selectedOrt.id !== SELECTED_ORT_HESSEN.id) {
      if (state.citySearch.selectedOrt.isFindable) {
        // wenn ein Ort gesucht wurde, setze ihn zurück
        clearCity(simpleStore, onCitySelected);
      } else {
        // wenn aktuell ein nicht-findbarer Ort angezeigt wird, ist keine Änderung nötig, da in diesem Fall value leer bleibt
      }
    }
  } else {
    const result =
      getSuggestion(state.citySearch.suggestions.data, city) || undefined;
    if (!result) {
      abortSearches(simpleStore);
      simpleStore.dispatch(CITY_SEARCH_RESETTED_TO_SELECTED());
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchStatistics({
        url: SERVER_URL_SUCHE_ORT_AUSWAHL_STATISTIC(),
        data: {
          suche: city,
          ort: result.ort || '',
          id: result.id,
          land: result.land,
        },
      });
      searchWithCity(simpleStore, result, onCitySelected);
    }
  }
}

export function clearCity(
  simpleStore: SimpleStore,
  onCitySelected: (() => void) | undefined,
): void {
  abortSearches(simpleStore);
  simpleStore.dispatch(CITY_SEARCH_RESET());
  hideSearchResults(simpleStore);
  searchWithCity(simpleStore, undefined, onCitySelected);
}
