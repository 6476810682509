import { logError } from '../../util/logError';
import { scrollToAnchor } from '../../util/scrollIntoView';
import { isAbortError, postNoRedirect } from '../util/fetch';
import {
  INFORMATION_ERROR,
  INFORMATION_FINISHED,
  INFORMATION_STARTED,
} from './detailEvents';
import type { InformationRequestParams } from './detailTypes';
import { deepEquals } from '../../util/deepEquals';
import type { InformationRequest } from './InformationRequest';
import type { SimpleStore } from '../../state/SimpleStore';
import type { HtmlPageInformation } from '../htmlTypes';

type InformationResponse = HtmlPageInformation | { asset: true };

export function loadInformationIfNotLoading(
  simpleStore: SimpleStore,
  forRequest: InformationRequest,
): void {
  const state = simpleStore.getState();
  if (deepEquals(forRequest, state.abortRequest.information?.forRequest)) {
    return;
  }

  const { abortRequest, result } = postNoRedirect<
    InformationRequestParams,
    InformationResponse
  >({
    url: forRequest.path + (forRequest.search ? `?${forRequest.search}` : ''),
    data: { infopage: true },
    flags: state.flags,
  });

  simpleStore.dispatch(INFORMATION_STARTED({ abortRequest, forRequest }));
  result
    .then((results: InformationResponse) => {
      if ('asset' in results) {
        const loc = document.location.toString();
        window.history.back();
        document.location.assign(loc);
      } else {
        simpleStore.dispatch(
          INFORMATION_FINISHED({ forRequest, patch: results.page }),
        );
        scrollToAnchor(simpleStore);
      }
    })
    .catch((e: unknown) => {
      // request war nicht erfolgreich
      if (isAbortError(e)) {
        // Request wurde absichtlich (abortRequest()) abgebrochen --> nichts zu tun
      } else {
        // sonstiger Fehler
        logError(e);
        simpleStore.dispatch(INFORMATION_ERROR(e));
      }
    });
}
