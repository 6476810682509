import type {
  SearchResultEditorial,
  SearchResultLeistung,
  SearchResultOrgeinheit,
} from '../../../action/search/searchTypes';

export const wordsSingleMultiMap = {
  Leistung: {
    wordSingle: 'Leistung',
    wordMulti: 'Leistungen',
  },
  Dienststelle: {
    wordSingle: 'Behörde',
    wordMulti: 'Behörden',
  },
  Information: {
    wordSingle: 'Infoseite',
    wordMulti: 'Infoseiten',
  },
};

export type CountAndWord = {
  count: number;
  wordSingle: string;
  wordMulti: string;
};

export enum ResultRowType {
  editorial = 'editorial',
  leistung = 'leistung',
  orgeinheit = 'orgeinheit',
}

type ResultRowEditorial = SearchResultEditorial & {
  type: ResultRowType.editorial;
};
type ResultRowLeistung = SearchResultLeistung & {
  type: ResultRowType.leistung;
};
type ResultRowOrgeinheit = SearchResultOrgeinheit & {
  type: ResultRowType.orgeinheit;
};
export type ResultRow =
  | ResultRowEditorial
  | ResultRowLeistung
  | ResultRowOrgeinheit;

export type ListElement = {
  doc: { score: number };
};
