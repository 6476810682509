import { NfkSettings } from '../../../constants';
import { NfkState } from '../../../state/createInitialState';
import { RenderResultWithSsr } from '../../../lib/renderTypes';
import { button, span } from '../../../render/html';
import { FEEDBACK_BUTTON_LABEL } from '../../../texts';
import { filterUndefinedArrayEntries } from '../../../util/filterUndefinedArrayEntries';
import { mapBooleanAttributes } from '../../../util/mapBooleanAttributes';
import loadingOverlay from '../loadingOverlay';

export const ID_FEEDBACK_BUTTON = 'vwp_nfk_feedback_button';

/** Der „echte“ Feedback-Button (wenn Einwilligung erteilt wurde) */
export function renderFeedbackButton(
  leistung: {
    id?: string;
    regschl?: string;
    firstLeika?: string;
  },
  nfkSettings: NfkSettings,
  nfkState: NfkState,
  onclick: undefined | ((this: HTMLElement) => void),
): RenderResultWithSsr[] | undefined {
  return filterUndefinedArrayEntries([
    button(
      mapBooleanAttributes({
        id: ID_FEEDBACK_BUTTON,
        class: 'btn btn-primary shadow',
        disabled: nfkState.loading,
        'data-context': JSON.stringify({
          issueType: 'Leika-Leistung',
          issue: leistung.firstLeika ?? leistung.id,
          region: leistung.regschl,
          ...nfkSettings,
        }),
        onclick,
      }),
      span({ class: 'bi bi-send pe-2' }),
      FEEDBACK_BUTTON_LABEL,
    ),
    nfkState.loading ? loadingOverlay('p-3') : undefined,
  ]);
}
