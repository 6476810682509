/* eslint-disable no-param-reassign */
import type { Draft } from 'immer';
import { getScreenRequest, putPatch1 } from '../lib/htmlCache';
import type { State, HtmlCacheEntryData } from '../state/createInitialState';
import { deepEquals } from '../util/deepEquals';
import { resetOdMapState } from './resetOdMapState';

export function reduceForNewPatch(
  draft: Draft<State>,
  entryData: HtmlCacheEntryData,
) {
  putPatch1(draft, entryData);
  if (deepEquals(getScreenRequest(draft), entryData.screenRequest)) {
    resetOdMapState(draft, entryData.patch.pageElements.odMapDomIds);
  }
}
