import type { RenderParam, RenderResultWithSsr } from '../../lib/renderTypes';
import { a, i, span } from '../../render/html';

type Params = {
  class?: string;
  href: string;
  onclick?: (event: MouseEvent) => void;
  target?: string;
};

export function linkInternInline(
  params: Params,
  content: RenderParam,
): RenderResultWithSsr {
  return linkIntern(
    { ...params, class: `${params.class ?? ''} og-link--inline` },
    content,
  );
}

export function linkInternStandalone(
  params: Params,
  content: RenderParam,
): RenderResultWithSsr {
  return linkIntern(
    { ...params, class: `${params.class ?? ''} og-link--standalone` },
    content,
  );
}

function linkIntern(
  {
    class: className = '',
    // eslint-disable-next-line no-script-url
    href = 'javascript:void 0',
    onclick,
    target,
  }: Params,
  content: RenderParam,
): RenderResultWithSsr {
  return a(
    {
      class: `link-primary ${className}`,
      href,
      onclick,
      target,
    },
    [
      i({
        class: 'bi bi-chevron-right link-icon icon me-1',
      }),
      span({}, content),
    ],
  );
}
