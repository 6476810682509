import { SEARCH_TERM_CHANGED } from './searchEvents';
import { loadDataIfNecessary } from '../loadDataIfNecessary';
import type { SimpleStore } from '../../state/SimpleStore';

/**
 * Sucht nach Suchbegriff in bestimmtem Ort.
 *
 * @param simpleStore Zugriff auf dispatch und getState
 * @param event Browser-Event wird verwendet, um preventDefault() aufzurufen
 * @param searchTerm Aus Rückfrage gewählter Suchtext
 */
export function searchFromDidYouMean(
  simpleStore: SimpleStore,
  event: Event,
  searchTerm: string,
): void {
  event.preventDefault();

  simpleStore.dispatch(SEARCH_TERM_CHANGED(searchTerm));

  loadDataIfNecessary(simpleStore);
}
