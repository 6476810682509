import type {
  ScreenData,
  ScreenDataLeistung,
  ScreenDataOrg,
} from './common/ScreenData';
import { Screen } from '../view';
import type { AdditionalData } from '../render/cached-incremental-dom';

export type HtmlString = { safeHtml: string };

export enum HtmlHeadElement {
  TITLE = 'TITLE',
  META_DESCRIPTION = 'META_DESCRIPTION',
  META_KEYWORDS = 'META_KEYWORDS',
  CANONICAL_LINK = 'CANONICAL_LINK',
  GEO_REGION = 'GEO_REGION',
  GEO_PLACENAME = 'GEO_PLACENAME',
  GEO_POSITION = 'GEO_POSITION',
  ICBM = 'ICBM',
  ROBOTS = 'ROBOTS',
}

export type HtmlPageBereich = {
  page: HtmlPatchBereich;
  additionalData: AdditionalData;
};
export type HtmlPageInformation = {
  page: HtmlPatchInformation;
  additionalData: never;
};
export type HtmlPageLage = {
  page: HtmlPatchLage;
  additionalData: AdditionalData;
};
export type HtmlPageLeistung = {
  page: HtmlPatchLeistung;
  additionalData: never;
};
export type HtmlPageOrg = {
  page: HtmlPatchOrg;
  additionalData: never;
};
export type HtmlPageStartseite = {
  page: HtmlPatchStartseite;
  additionalData: never;
};
export type HtmlPageSublage = {
  page: HtmlPatchSublage;
  additionalData: AdditionalData;
};

export type HtmlPatch = HtmlPatchType<ScreenData>;

type HtmlPatchType<T> = {
  data: T;
  pageElements: PageElements;
};

export type PageElements = {
  asideHtml?: HtmlString;
  breadcrumbHtml?: HtmlString;
  contentHtml?: HtmlString;
  drupalTitle?: string;
  htmlHead: { [prop in HtmlHeadElement]?: string };
  navigationHtml?: HtmlString;
  odMapDomIds: string[];
  showChatbot: boolean;
};

export type HtmlPatchBereich = HtmlPatchType<{ screen: Screen.Bereich }>;
export type HtmlPatchInformation = HtmlPatchType<{
  screen: Screen.Information;
}>;
export type HtmlPatchLage = HtmlPatchType<{ screen: Screen.Lage }>;
export type HtmlPatchLeistung = HtmlPatchType<ScreenDataLeistung>;
export type HtmlPatchOrg = HtmlPatchType<ScreenDataOrg>;
export type HtmlPatchOrgSuche = HtmlPatchType<{ screen: Screen.OrgSuche }>;
export type HtmlPatchStartseite = HtmlPatchType<{ screen: Screen.Startseite }>;
export type HtmlPatchSublage = HtmlPatchType<{ screen: Screen.Sublage }>;
export type HtmlPatchSuche = HtmlPatchType<{ screen: Screen.Suche }>;

export type PageFrame = {
  footer: HtmlString;
};
