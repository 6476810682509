export function createResolvablePromise(): {
  promise: Promise<void>;
  reject: () => void;
  resolve: () => void;
} {
  let rejectFunc: () => void;
  let resolveFunc: () => void;

  const promise = new Promise<void>((resolveParam, rejectParam) => {
    rejectFunc = rejectParam;
    resolveFunc = resolveParam;
  });

  // rejectFunc und resolveFunc sind hier bereits definiert, da der
  // Promise-executor an dieser Stelle bereits aufgerufen wurde. Zitat aus mdn:
  // The executor is called synchronously (as soon as the Promise is constructed)
  return {
    promise,
    reject() {
      rejectFunc();
    },
    resolve() {
      resolveFunc();
    },
  };
}
