import { createEvent, TriggeredBy } from './createEvent';
import type { PvLagenByCode, PvLagenCounts } from '../state/createInitialState';

/** PV-Lagen wurden geladen */
export const PV_LAGEN_LOADED = createEvent<PvLagenByCode>(
  'PV_LAGEN_LOADED',
  TriggeredBy.Other,
);
/** PV-Lagen-Counts wurden geladen */
export const PV_LAGEN_COUNTS_LOADED = createEvent<{
  regschl: string;
  counts: PvLagenCounts;
}>('PV_LAGEN_COUNTS_LOADED', TriggeredBy.Other);
