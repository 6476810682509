import { FRONTEND_ORIGIN, TypeFilter } from './constants';
import { UnreachableCaseError } from './util/UnreachableCaseError';

export const BACK = 'Zurück';
export const BUTTON_ABORT = 'Abbrechen';
export const BUTTON_CLOSE = 'Schließen';
export const CHATBOT_NOT_AVAILABLE_HEADER =
  'Chatbot-Funktion aktuell nicht verfügbar';
export const CHATBOT_NOT_AVAILABLE_TEXT =
  'Leider steht die Chatbot-Funktion aktuell nicht zur Verfügung, bitte versuchen Sie es später erneut';
export const CITY_FEEDBACK_INPUT_INVALID =
  'Bitte geben Sie einen gültigen Suchbegriff ein';
export const CITY_FEEDBACK_LOADING = 'Der eingegebene Ort wird gesucht...';
export const CITY_FEEDBACK_MULTIPLE_MATCHES =
  'Bitte wählen Sie einen der vorgeschlagenen Orte aus:';
export const CITY_FEEDBACK_NOT_FOUND =
  'Der eingegebene Ort ergab keine Treffer';
export const CITY_INPUT_DESCRIPTION =
  'Geben Sie einen Ort oder eine Postleitzahl ein';
export const CITY_INPUT_DESCRIPTION_FOR_MAP = 'Geben Sie einen Ort ein';
export const CITY_INPUT_TITLE = 'Stadt\u00A0/ Ort\u00A0/ PLZ';
export const CITY_INPUT_TITLE_FOR_MAP =
  'Gemeinde\u00A0/ Stadt\u00A0/ Landkreis';

export const CLEAR_INPUT_BUTTON_ARIA = 'Eingabe leeren';

export const DATENSCHUTZ_FEHLER_TITLE = 'Fehler';
export const DATENSCHUTZ_FEHLER_TEXT_SAFE_HTML =
  'Leider ist ein Fehler aufgetreten';

export const DRUPAL_MAILTO_SUBJECT =
  'Artikel-Empfehlung aus dem Verwaltungsportal Hessen';
export const DRUPAL_MAILTO_BODY = (
  documentTitle: string,
  link: string,
): string =>
  `Diese E-Mail wurde Ihnen über ein Formular auf ${FRONTEND_ORIGIN()} gesandt.` +
  `\nDer Absender empfiehlt Ihnen folgenden Artikel:` +
  `\n\n${documentTitle}` +
  `\nSie finden den Artikel hier: ${link}` +
  `\n\nWir weisen darauf hin, dass diese Absenderangabe nicht geprüft oder verifiziert ist. Sollten Sie Zweifel an der Authentizität des Absenders haben, ignorieren Sie diese E-Mail bitte. Der Betreiber von ${FRONTEND_ORIGIN()} übernimmt keine Verantwortung für den Inhalt der Nachricht.`;
// TODO: Tweet-Text
export const DRUPAL_TWEET_TEXT = (documentTitle: string): string =>
  documentTitle;

export const ERROR_BEREICHSSEITE =
  'Der gewählte Bereich konnte nicht geladen werden. Bitte überprüfen Sie ihre Auswahl oder versuchen Sie es später erneut.';
export const ERROR_CITY_SEARCH =
  'Der gesuchte Ort konnte nicht gefunden werden.';
export const ERROR_LAGENSEITE =
  'Die gewählte Lage konnte nicht geladen werden. Bitte überprüfen Sie ihre Auswahl oder versuchen Sie es später erneut.';
export const ERROR_SEARCH =
  'Es ist leider ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben oder versuchen Sie es später erneut.';
export const ERROR_ORG =
  'Fehler beim Laden der Organisationseinheit. Bitte überprüfen Sie ihre Auswahl oder versuchen Sie es später erneut.';
export const NOT_FOUND_ORG =
  'Behörde wurde nicht gefunden. Bitte überprüfen Sie ihre Auswahl oder versuchen Sie es später erneut.';
export const ERROR_REGSCHL = 'Der gesuchte Ort konnte nicht gefunden werden.';
export const ERROR_SUBLAGENSEITE =
  'Die gewählte Sublage konnte nicht geladen werden. Bitte überprüfen Sie ihre Auswahl oder versuchen Sie es später erneut.';

export const FEEDBACK_BUTTON_LABEL = 'Feedback senden';

export const FILTER_TITLE = 'Filter';

export const LAGE_TEXT_BUERGER = 'Bürgerinnen | Bürger';
export const LAGE_TEXT_UNTERNEHMEN = 'Unternehmen';

export const LEISTUNG_ERROR =
  'Fehler beim Laden der Leistungsbeschreibung. Bitte überprüfen Sie ihre Auswahl oder versuchen Sie es später erneut.';
export const LEISTUNG_NOT_FOUND =
  'Leistung wurde nicht gefunden. Bitte überprüfen Sie ihre Auswahl oder versuchen Sie es später erneut.';

export const LEISTUNG_ORT_NO_ONLINEDIENST =
  'Für diesen Ort gibt es aktuell keinen Online-Dienst.';

export const MAP_ONLINEDIENSTE_NOTE =
  'Wählen Sie eine Gemeinde, eine Stadt oder einen Landkreis aus der Karte aus oder verwenden Sie die Suche. Über die Breadcrumb gelangen Sie zur vorherigen Ebene zurück.';
export const MAP_ONLINEDIENSTE_TITLE = 'Online-Dienste in Hessen';
export const MAP_ONLINEDIENSTE_ZOOM_IN = 'Hereinzoomen';
export const MAP_ONLINEDIENSTE_ZOOM_OUT = 'Herauszoomen';
export const MAP_ONLINEDIENSTE_ZOOM_RESET = 'Ganz Hessen';
export const MAP_ONLINEDIENSTE_WERDEN_BERECHNET =
  'Die Anzahl der Onlinedienste wird momentan berechnet. Sobald die Berechnung abgeschlossen ist, sehen Sie hier die Anzahl der verfügbaren Onlinedienste.';

export function MAP_ONLINEDIENSTE_VERFUEGBAR(
  count: number,
  showVerfuegbar: boolean,
) {
  const suffix = showVerfuegbar ? ' verfügbar' : '';
  if (count === 1) {
    return `1 Online-Dienst${suffix}`;
  } else {
    return `${count} Online-Dienste${suffix}`;
  }
}
export const MAP_WERDEN_BEREITGESTELLT_VON =
  'Diese Online-Dienste werden bereitgestellt von:';
export const NFK_NOT_AVAILABLE_HEADER =
  'Feedback-Funktion aktuell nicht verfügbar';
export const NFK_NOT_AVAILABLE_TEXT =
  'Leider steht die Feedback-Funktion aktuell nicht zur Verfügung, bitte versuchen Sie es später erneut';
export const NOT_FOUND_SEARCH =
  'Keine Ergebnisse gefunden. Bitte überprüfen Sie Ihre Eingaben.';

export const ORG_SEARCH_EXPLANATION =
  'Die Behördensuche hilft Ihnen Behörden zu finden, die für eine Gemeinde' +
  ' und für Themen zuständig sind.';
export const ORG_SEARCH_FIELD_LABEL = 'Behörde\u00A0/ Stichwort';
export const ORG_SEARCH_FILTER_EXPLANATION =
  'Zuständigkeiten können auf den verschiedenen' +
  ' Verwaltungsebenen liegen. Mit dem Filter können Sie Ihre Suche auf die' +
  ' verschiedenen Verwaltungsebenen erweitern oder einschränken.';
export const ORG_SEARCH_ORG_INPUT_DESCRIPTION =
  'Geben Sie eine Behörde oder ein Stichwort ein ';
export const ORG_SEARCH_RESULT_EXPLANATION =
  'zum Nachfiltern oder Erweitern der Suchergebnisse können die Filter weiter oben verwendet werden';
export const ORG_SEARCH_TITLE = 'Behördensuche';
export const PRIVACY_ACCEPT = 'Ja, ich stimme zu';
export const PRIVACY_REJECT = 'Nein, ich stimme nicht zu';
export const PRIVACY_REJECTED_HEADER = 'Funktion nicht möglich';
export const PRIVACY_REJECTED_SAFE_HTML = `
<p>Sie sind mit den Datenschutzhinweisen nicht einverstanden.</p>
<p>Leider kann die Funktion dann nicht verwendet werden.</p>
`;
export const REGSCHL_BUND = '000000000000';

export const REGSCHL_LEVEL_KOMMUNE_LABEL = 'Ort';
export const REGSCHL_LEVEL_GEMEINDEVERBAND_AS_PREFIX = 'Gemeindeverband';
export const REGSCHL_LEVEL_KREIS_AS_PREFIX = 'Landkreis';
export const REGSCHL_LEVEL_KREIS_LABEL = 'Kreis';
export const REGSCHL_LEVEL_BEZIRK_IN_HESSEN_LABEL = 'Regierungsbezirk';
export const LAND_HESSEN = 'Hessen';
export const LAND_HESSEN_LONG = 'Land Hessen';
export const REGSCHL_LEVEL_BUND_LABEL = 'Bund';
export const REGSCHL_LEVEL_DEUTSCHLAND_LABEL = 'Deutschland';

export const REGSCHL_FILTER_TYPE_ORT = (ort: string) => `Behörden in ${ort}`;
export const REGSCHL_FILTER_TYPE_ZUSTAENDIG = (ort: string) =>
  `Behörden zuständig für ${ort}`;

export const SEARCH_BUTTON_ARIA = 'Suchen';

export const SEARCH_FIELD_LABEL =
  'Verwaltungsleistung\u00A0/ Behörde\u00A0/ Stichwort';

export const SEARCH_FIELD_PLACEHOLDER_BUERGER = `Suche im Bereich ${LAGE_TEXT_BUERGER}`;
export const SEARCH_FIELD_PLACEHOLDER_UNTERNEHMEN = `Suche im Bereich ${LAGE_TEXT_UNTERNEHMEN}`;

export const SEARCH_NO_RESULTS = 'Keine Ergebnisse';
export const SEARCH_HAS_ONLINE_SERVICE = 'Onlinedienst verfügbar';
export const SEARCH_HAS_CONTACT = 'Ansprechpartner verfügbar';
export const SEARCH_PLACE_REQUIRED = 'Ortsangabe notwendig';
export const SEARCH_HAS_LEICHTE_SPRACHE = 'Leichte Sprache verfügbar';

export const TITLE_FALLBACK = 'Verwaltungsportal Hessen';

export function TYPE_FILTER_REMOVED(types: TypeFilter[]): string {
  return `Für den Filter "${types
    .map(TYPE_FILTER_LABEL)
    .join(
      ', ',
    )}" wurden keine Suchergebnisse gefunden, daher wurde ohne Filter gesucht.`;
}

export function TYPE_FILTER_LABEL(type: TypeFilter): string {
  switch (type) {
    case TypeFilter.info:
      return 'Information';
    case TypeFilter.leistung:
      return 'Leistung';
    case TypeFilter.orgeinheit:
      return 'Behörde';
    default:
      throw new UnreachableCaseError(type);
  }
}

export const WARNING_ELEMENT_INVALID =
  'Der Gültigkeitszeitraum dieses Elements ist überschritten oder liegt in der Zukunft. Es ist daher nur für technische Nutzer sichtbar!';
export const WARNING_SECTION_INVALID =
  'Section ist ungültig und nur für technische Nutzer sichbar';
export const WARNING_PART_INVALID =
  'Part ist ungültig und nur für technische Nutzer sichbar';
