import lodashMap from 'lodash/fp/map';
import lodashMapValues from 'lodash/fp/mapValues';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const map: <S, D>(map: (val: S) => D) => (arr: S[]) => D[] = lodashMap
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  .convert({ cap: false });
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const mapValues: <T extends string, R, S>(
  map: (val: R, key: T) => S,
) => (obj: Record<T, R>) => Record<T, S> = lodashMapValues
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  .convert({
    cap: false,
  });
