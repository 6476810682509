import { CITY_SELECTED_FROM_ASK } from '../../../action/search/searchEvents';
import {
  adaptSearchTerm,
  searchFromAsk,
} from '../../../action/search/searchFromAsk';
import type {
  SearchResultAskItemType,
  SearchResultAskListType,
} from '../../../action/search/searchTypes';
import { map } from '../../../lib/lodash';
import type { RenderResultWithSsr } from '../../../lib/renderTypes';
import { li, p, span, ul } from '../../../render/html';
import type { State } from '../../../state/createInitialState';
import { linkInternStandalone } from '../../elements/linkInternInline';
import combinePlaceNames from '../../util/combinePlaceNames';
import { buildUrl } from '../../util/navUrls';
import type { SimpleStore } from '../../../state/SimpleStore';

export function askSearchWhere({
  simpleStore,
  state,
  shouldRenderAskList,
}: {
  simpleStore: SimpleStore;
  state: State;
  shouldRenderAskList: boolean;
}): RenderResultWithSsr[] {
  const askList = state.search.results.data?.askList;
  const dataFor = state.search.results.forRequest;
  if (!askList || !askList.length || !dataFor || !shouldRenderAskList) {
    return [];
  } else {
    const items = renderAskList({
      askList,
      simpleStore,
    });
    return [
      p({ class: 'paragraph' }, 'Wo möchten Sie suchen? Bitte wählen Sie:'),
      ul({}, items),
    ];
  }
}

function renderAskList({
  askList,
  simpleStore,
}: {
  askList: SearchResultAskListType;
  simpleStore: SimpleStore;
}): RenderResultWithSsr[] {
  return map((askItem: SearchResultAskItemType) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { selected: _selected, ...selectedOrt } = askItem;
    return li({ class: 'text' }, [
      askItem.selected
        ? span({ class: 'a-bold' }, `> ${combinePlaceNames(askItem)}`)
        : linkInternStandalone(
            {
              href: buildUrl(simpleStore, [
                CITY_SELECTED_FROM_ASK({
                  searchTerm: adaptSearchTerm(simpleStore, selectedOrt),
                  selectedOrt,
                }),
              ]),
              onclick: (event: MouseEvent) => {
                searchFromAsk(simpleStore, event, selectedOrt);
              },
            },
            combinePlaceNames(askItem),
          ),
    ]);
  })(askList);
}
