import { scrollToTop } from '../../util/scrollIntoView';
import { loadDataIfNecessary } from '../loadDataIfNecessary';
import { LEISTUNG_SHOWN } from './detailEvents';
import type { LeistungRequestWithRegschl } from './detailTypes';
import type { SimpleStore } from '../../state/SimpleStore';

/**
 * Lädt die Detaildaten für eine Leistung und einen Ort und zeigt diese an.
 *
 * @param simpleStore Zugriff auf dispatch und getState
 * @param event Browser-Event wird verwendet, um preventDefault() aufzurufen
 * @param leistungId Id der anzuzeigenden Leistung
 * @param regschl Id des Ortes (Regionalschlüssel), für welchen die Darstellung erfolgen soll
 */
export function showLeistung(
  simpleStore: SimpleStore,
  {
    event,
    leistung_id: leistungId,
    regschl,
  }: { event?: MouseEvent } & LeistungRequestWithRegschl,
): void {
  event?.preventDefault();
  simpleStore.dispatch(LEISTUNG_SHOWN({ leistung_id: leistungId, regschl }));
  loadDataIfNecessary(simpleStore);
  scrollToTop();
}
