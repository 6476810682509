import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { div } from '../../render/html';

export const SELECTOR_CONTENT_LEFT = '.content-left[key="dynamic"]';

export function contentLeft(
  content: RenderResultWithSsr[] | RenderResultWithSsr | undefined,
) {
  return div({ class: 'content-left', key: 'dynamic' }, content);
}
