import type { SimpleStore } from '../state/SimpleStore';
import { ELEMENT_CLASS_NAME } from './mapConstants';

let leafletLoaded = false;

/**
 * Lädt die Kartenimplementierung nach (falls nötig) und ruft die Renderfunktion der Karte auf
 */
export function renderOnlinediensteMapSection(simpleStore: SimpleStore) {
  const hasOuterElements =
    document.getElementsByClassName(ELEMENT_CLASS_NAME).length > 0;
  // wenn leaflet bereits geladen ist (d.h. die Karte bereits einmal gerendert wurde), muss das Rendering auch bei leerer Liste aufgerufen werden, um ggf. noch vorhandene alte Map-Zustände aufzuräumen
  if (leafletLoaded || hasOuterElements) {
    import('./map/renderOnlinediensteMapSectionInternal')
      .then((dependency) => {
        leafletLoaded = true;
        dependency.renderOnlinediensteMapSectionInternal(
          // Elemente hier neu suchen, da sich die Seite in der Zwischenzeit (durch asynchronen import) geändert haben kann
          [
            // make collection non-live
            ...document.getElementsByClassName(ELEMENT_CLASS_NAME),
          ],
          simpleStore,
        );
      })
      .catch(() => {
        // ignore errors loading renderMapWithLeaflet
      });
  }
}
