import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { renderCityField } from '../blocks/citySearch';
import { renderPvLagenSearchField } from '../blocks/search';
import { selectParamsForSearch } from '../read/selectParamsForSearch';
import { selectParamsForCitySearch } from '../read/selectParamsForCitySearch';
import type { SimpleStore } from '../../state/SimpleStore';
import { contentLeft, SELECTOR_CONTENT_LEFT } from '../frame/contentLeft';
import { pvLagenNavigation } from '../read/extractDataForPvLagenNavigation';

export function lagenseite(
  simpleStore: SimpleStore,
): Partial<Record<string, RenderResultWithSsr>> {
  const state = simpleStore.getState();
  return {
    '.og-search-field-box': renderPvLagenSearchField(simpleStore, {
      ...selectParamsForSearch(state),
      isStartpage: false,
    }),
    '.og-city-field-box': renderCityField(
      simpleStore,
      selectParamsForCitySearch(state),
    ),
    [SELECTOR_CONTENT_LEFT]: contentLeft(
      pvLagenNavigation(
        simpleStore,
        true,
        state.search.request.filter.pvLage,
        state.search.request.filter.pvLage,
      ),
    ),
  };

  /*
  const loading = Boolean(state.abortRequest.lagenseite?.abortRequest);
  if (loading) {
    // TODO
    return sectionSeiteninhalt({ class: 'TODO' }, state, [loadingIndicator()]);
  }

  const { data } = state.lagenseite.content;
  if (!data || state.error.lagenseite) {
    return error(ERROR_LAGENSEITE);
  }
  */
}
