import h from './incremental-hyperscript';
import {
  createHyperscriptHelpers,
  createHyperscriptHelpersSvg,
} from '../lib/hyperscriptHelpers';
import { renderHtml } from '../render_srv/renderHtml';

// Rückgabetyp der Hyperscript-Helpers genauer spezifizieren
const hh = __SERVER_SIDE_RENDERING__
  ? createHyperscriptHelpers(renderHtml)
  : createHyperscriptHelpers(h);
const hhSvg = __SERVER_SIDE_RENDERING__
  ? createHyperscriptHelpersSvg(renderHtml)
  : createHyperscriptHelpersSvg(h);

export const {
  a,
  article,
  aside,
  button,
  datalist,
  div,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  header,
  i,
  img,
  input,
  label,
  legend,
  li,
  link,
  main,
  meta,
  nav,
  ol,
  option,
  p,
  section,
  select,
  span,
  svg,
  ul,
} = hh;

export const { circle } = hhSvg;

export const htmlIncrementalDom = { ...hh, ...hhSvg };
