import { SEARCH_RESULTS_HIDDEN } from './searchEvents';
import type { SimpleStore } from '../../state/SimpleStore';

export function hideSearchResults(simpleStore: SimpleStore): void {
  const state = simpleStore.getState();
  if (
    state.search.results.data ||
    state.search.results.notFound ||
    state.error.search
  ) {
    simpleStore.dispatch(SEARCH_RESULTS_HIDDEN());
  }
}
