import { headerSelector } from '../../render/cached-incremental-dom';

export function highlightCurrentMenu() {
  const currentPath = document.location.pathname;
  // alle Elemente im Header durchgehen
  document.querySelectorAll(headerSelector).forEach((item) => {
    iterateMenu(currentPath, item);
  });
}

function iterateMenu(currentPath: string, menu: Element) {
  // Elemente filtern nach li- und a-Tags, dabei aber die Baumstruktur beibehalten
  const treeWalker = document.createTreeWalker(menu, NodeFilter.SHOW_ELEMENT, {
    acceptNode(node) {
      if (
        node instanceof Element &&
        (node.tagName === 'LI' || node.tagName === 'A')
      ) {
        return NodeFilter.FILTER_ACCEPT;
      } else {
        return NodeFilter.FILTER_SKIP;
      }
    },
  });

  // Baum aus TreeEntry-Elementen bauen
  // Achtung: treeWalker ist statefull, d.h. nach dem Aufruf ist treeWalker hinter den Elementen
  const tree: TreeEntry = iterateChildren(treeWalker, []);
  // hieraus eine depth-first-Liste erstellen
  const todo = depthsFirstList(tree);
  // liefert eine Liste aller a-Tags zurück
  // für jedes a-Tag ist angegeben, ob es aktiv sein soll
  const markedList = markCurrent(currentPath, todo);
  // An den a-Tags Klassen setzen/löschen gemöß gewünschtem Status
  highlightTags(markedList);
}

type TreeEntry = {
  // alle a-Tags der Ebene
  aTags: Element[];
  // alle li-Tags der Ebene mit den untergeordneten Elementen
  liTags: TreeEntry[];
  // Parents bis zum Root-Element
  parents: TreeEntry[];
};
// baut aus dem TreeWalker eine Struktur aus TreeEntry-Elementen
function iterateChildren(
  treeWalker: TreeWalker,
  // alle übergeordneten Elemente bis zum Root-Element
  parents: TreeEntry[],
): TreeEntry {
  const aTags: Element[] = [];
  const liTags: TreeEntry[] = [];
  const result: TreeEntry = { aTags, liTags, parents };
  for (
    let child = treeWalker.firstChild();
    child != null;
    child = treeWalker.nextSibling()
  ) {
    if (
      // sollte durch den Filter bereits sichergestellt sind, aber Typescript weiß das nicht
      child instanceof Element
    ) {
      if (child.tagName === 'A') {
        aTags.push(child);
      } else if (child.tagName === 'LI') {
        liTags.push(iterateChildren(treeWalker, [...parents, result]));
        // treeWalker wieder auf aktuellen Knoten setzen, da durch iterateChildren() auf anderem Element
        // eslint-disable-next-line no-param-reassign
        treeWalker.currentNode = child;
      } else {
        // sollte durch den Filter A oder LI sein, daher hier nichts zu tun
      }
    }
  }
  return result;
}

function depthsFirstList(tree: TreeEntry): TreeEntry[] {
  const checked: Set<TreeEntry> = new Set<TreeEntry>();
  const result: TreeEntry[] = [];
  const todo: TreeEntry[] = [];
  for (let entry: TreeEntry | undefined = tree; entry; entry = todo.pop()) {
    if (checked.has(entry)) {
      throw new Error('Recursion in tree');
    }
    checked.add(entry);
    result.unshift(entry);
    todo.push(...entry.liTags);
  }
  return result;
}

type MarkedA = { a: Element; active: boolean };
function markCurrent(currentPath: string, list: TreeEntry[]): MarkedA[] {
  const result: MarkedA[] = [];
  const activeElements: Set<TreeEntry> = new Set<TreeEntry>();
  list.forEach((entry) => {
    if (!activeElements.has(entry)) {
      let anyActive = false;
      entry.aTags.forEach((atag) => {
        const href = atag.getAttribute('href') ?? '';
        const queryLocation = href.indexOf('?');
        const path =
          queryLocation < 0 ? href : href.substring(0, queryLocation);
        let active;
        if (path.endsWith('/')) {
          active = currentPath.startsWith(path);
        } else {
          active = currentPath === path || currentPath.startsWith(`${path}/`);
        }

        result.push({ a: atag, active });
        anyActive ||= active;
      });
      // false positive
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (anyActive) {
        activeElements.add(entry);
        entry.parents.forEach((parent) => {
          parent.aTags.forEach((atag) => {
            result.push({ a: atag, active: true });
          });
          activeElements.add(parent);
        });
      }
    }
  });
  return result;
}

const CLASSES_CURRENT_A = ['active', 'is-active'];

function highlightTags(markedList: MarkedA[]) {
  markedList.forEach((entry) => {
    if (entry.active) {
      entry.a.classList.add(...CLASSES_CURRENT_A);
    } else {
      entry.a.classList.remove(...CLASSES_CURRENT_A);
    }
  });
}
