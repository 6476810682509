import { type Gebiet, RegschlLevel } from '../action/search/searchTypes';
import { REGSCHL_BUND } from '../texts';

export function gebietToLevel(gebiet: Gebiet): RegschlLevel {
  if (gebiet.ort) {
    return RegschlLevel.kommune;
  } else if (gebiet.verband) {
    return RegschlLevel.gemeindeverband;
  } else if (gebiet.kreis) {
    return RegschlLevel.kreis;
  } else if (gebiet.bezirk) {
    return RegschlLevel.bezirk;
  } else if (gebiet.id === REGSCHL_BUND) {
    return RegschlLevel.bund;
  } else {
    return RegschlLevel.land;
  }
}
