import { loadDataIfNecessary } from '../loadDataIfNecessary';
import { abortSearches } from './searchCommon';
import { SEARCH_CLEARED } from './searchEvents';
import type { SimpleStore } from '../../state/SimpleStore';

/**
 * Leert die Suchergebnisanzeige.
 *
 * @param simpleStore Zugriff auf dispatch und getState
 */
export function clearSearch(simpleStore: SimpleStore): void {
  abortSearches(simpleStore);
  simpleStore.dispatch(SEARCH_CLEARED());
  loadDataIfNecessary(simpleStore);
}
