import { Dispatch } from 'redux';
import { Flags } from '../../../history/flags';
import { NfkScriptSrc } from '../../../constants';
import {
  NFK_LOADING_FAILED,
  NFK_LOADING_FINISHED,
  NFK_LOADING_STARTED,
} from '../../../reducer/nfkEvents';

/**
 * Fügt script-Tag zum HTML-Header hinzu, falls nötig
 *
 * @param {Dispatch} dispatch Redux-Dispatch-Funktion
 * @param {NfkScriptSrc | undefined} nfkScriptSrc DOM-ID und https-Adresse für das externe Feedback-Script
 * @returns {Promise<boolean>} true, falls Skript erfolgreich asynchron geladen wurde
 */
export async function addFeedbackHeaderIfMissing(
  dispatch: Dispatch,
  flags: Flags,
  nfkScriptSrc: NfkScriptSrc,
): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    if (!document.getElementById(nfkScriptSrc.id)) {
      dispatch(NFK_LOADING_STARTED());
      let scriptSrc = nfkScriptSrc.src;
      if (flags.fail_request && nfkScriptSrc.src.includes(flags.fail_request)) {
        scriptSrc = 'https://url.invalid/invalid.js';
      }
      const script = document.createElement('script');
      script.setAttribute('id', nfkScriptSrc.id);
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', scriptSrc);
      script.setAttribute('defer', 'defer');
      script.onload = () => {
        dispatch(NFK_LOADING_FINISHED());
        resolve(true);
      };
      script.onerror = (event) => {
        console.error('NFK script could not be loaded', event);
        document.head.removeChild(script);
        dispatch(NFK_LOADING_FAILED());
        resolve(false);
      };
      document.head.appendChild(script);
    } else {
      resolve(true);
    }
  });
}
