import { Dropdowns } from '../action/search/searchCommon';
import { Screen } from '../view';
import { createEvent, TriggeredBy } from './createEvent';

/** The app was updated, reload is needed */
export const APP_UPDATED = createEvent('APP_UPDATED', TriggeredBy.Other);
/** Dropdown für input-Feld verstecken */
export const DROPDOWN_HIDDEN = createEvent<Dropdowns>(
  'DROPDOWN_HIDDEN',
  TriggeredBy.User,
);
/** Ändern der Selection im Dropdown per Maus */
export const DROPDOWN_SELECTED = createEvent<{
  dropdown: Dropdowns;
  index: number | undefined;
}>('DROPDOWN_SELECTED', TriggeredBy.User);
/** Dropdown für Input-Feld anzeigen */
export const DROPDOWN_SHOWN = createEvent<Dropdowns>(
  'DROPDOWN_SHOWN',
  TriggeredBy.User,
);
/** Von der Org-Detailseite zurück navigieren */
export const ORG_NAVIGATED_BACK = createEvent(
  'ORG_NAVIGATED_BACK',
  TriggeredBy.User,
);
/** Screen wurde gewechselt */
export const SCREEN_CHANGED = createEvent<Screen>(
  'SCREEN_CHANGED',
  TriggeredBy.User,
);
/** Scrollen zum Text-Anker nach dem initialen Laden der Anwendung wurde ausgeführt */
export const SCROLLED_TO_ANCHOR = createEvent(
  'INITIAL_LOADED',
  TriggeredBy.Other,
);
/** Die Suchscreen wurde mit dem angegebenen Hierarchie-Filter angezeigt */
export const SEARCH_WITH_HIERARCHY_SHOWN = createEvent<{
  pvLage: string;
  searchScreen: Screen;
}>('SEARCH_WITH_HIERARCHY_SHOWN', TriggeredBy.User);
/** Validierungsfehler werden jetzt angezeigt */
export const VALIDATION_ERRORS_SHOWN = createEvent(
  'VALIDATION_ERRORS_SHOWN',
  TriggeredBy.Other,
);
/** Themen in Mobil-Ansicht wurden auf- oder zugeklappt */
export const THEMEN_COLLAPSED = createEvent<boolean>(
  'THEMEN_COLLAPSED',
  TriggeredBy.User,
);
/** Erzwinge eine Aktualisierung der URL basierend auf dem State, ändert den State nicht (erzwingt ein Ersetzen der window.history) */
export const URL_REPLACED = createEvent('URL_REPLACED', TriggeredBy.Other);
