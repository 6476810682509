/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { State } from '../state/createInitialState';
import {
  NFK_LOADING_FAILED,
  NFK_LOADING_FINISHED,
  NFK_LOADING_STARTED,
  NFK_PRIVACY_ACCEPTED,
} from './nfkEvents';

/** Redux-Reducer für die NFK-Events */
export function createNfkReducer(builder: ActionReducerMapBuilder<State>) {
  builder.addCase(NFK_LOADING_FAILED, (state) => {
    state.nfk.loading = false;
  });
  builder.addCase(NFK_LOADING_FINISHED, (state) => {
    state.nfk.loading = false;
  });
  builder.addCase(NFK_LOADING_STARTED, (state) => {
    state.nfk.loading = true;
  });
  builder.addCase(NFK_PRIVACY_ACCEPTED, (state) => {
    state.nfk.privacyAccepted = true;
  });
}
