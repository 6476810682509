import { SEARCHES_ABORTED } from './searchEvents';
import type { SimpleStore } from '../../state/SimpleStore';
import { abortRequestFor } from '../util/abortRequestFor';

export type DropdownOption = {
  value: string;
  datalistLabel?: string;
  customLabel?: string;
};
export type Dropdown = {
  visible: boolean;
  selection: number | undefined;
};
export enum Dropdowns {
  SEARCH = 'search',
  CITY = 'city',
}

export function abortSearches(simpleStore: SimpleStore): void {
  const state = simpleStore.getState();
  const {
    search: abortSearch,
    searchMore: abortSearchMore,
    citySearch: abortCitySearch,
  } = state.abortRequest;

  let atLeastOneWasAborted = false;
  if (abortSearch) {
    abortRequestFor(abortSearch);
    atLeastOneWasAborted = true;
  }
  if (abortSearchMore) {
    abortRequestFor(abortSearchMore);
    atLeastOneWasAborted = true;
  }
  if (abortCitySearch) {
    abortRequestFor(abortCitySearch);
    atLeastOneWasAborted = true;
  }
  if (atLeastOneWasAborted) {
    simpleStore.dispatch(SEARCHES_ABORTED());
  }
}
