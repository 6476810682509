import './render.css';
import type { HtmlFns } from '../../lib/renderTypes';

export type RenderButtonParams = {
  ariaLabel: string;
  class?: string;
  iconClass: string;
  onclick?: (event: MouseEvent) => void;
  type: 'button' | 'submit';
};

export function inputButton2<T>(
  html: HtmlFns<T>,
  { ariaLabel, iconClass, ...inputParams }: RenderButtonParams,
) {
  return html.button(
    {
      ...inputParams,
      'aria-label': ariaLabel,
      class: `${
        inputParams.class || ''
      } og-focusable og-input-button og-input-button-box`,
    },
    html.i({ class: `bi ${iconClass}` }),
  );
}
