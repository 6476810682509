import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { div, form, section } from '../../render/html';
import { filter } from '../blocks/filter';
import { renderSearchPageSearchField, searchResults } from '../blocks/search';
import type { SimpleStore } from '../../state/SimpleStore';
import h from '../../render/incremental-hyperscript';
import { share } from '../share';

export default function mainContent(
  simpleStore: SimpleStore,
): RenderResultWithSsr {
  const state = simpleStore.getState();
  return h.fragment([
    share(state.flags.share_generic === 'show', {
      title: document.title,
      url: String(document.location),
    }),
    div(
      { key: 'dynamic' },
      div({}, [
        form(
          {
            onsubmit: (event: Event) => {
              event.preventDefault();
            },
          },
          [
            section(
              {
                key: 'screen-search-search',
                'aria-label': 'Suche',
                class: 'og-search-search',
              },
              renderSearchPageSearchField(simpleStore),
            ),
            filter('screen-search-filter', 'og-search-filter', simpleStore),
          ],
        ),
        div(
          {
            key: 'screen-search-content',
            class: 'og-search-result',
          },
          searchResults(simpleStore),
        ),
      ]),
    ),
  ]);
}
