/*
hyperscript-helpers durch lokale, vereinfachte Version ersetzt wegen folgenden Problemen:
* wenn der erste Parameter ein String ist, der mit . beginnt, wird dies
  unerwarteterweise als class=".foo"-Property interpretiert
* Typen waren nicht sauber definiert
*/

import type {
  HyperscriptChild,
  HyperscriptElement,
  HyperscriptFunction,
  HyperscriptProperties,
} from '../render/common-render-types';

export type HyperscriptHelperFn<T> = {
  (props?: HyperscriptProperties, ...rest: HyperscriptElement<T>[]): () => T;
  (props: HyperscriptProperties, rest: HyperscriptChild<T>): () => T;
};

function node<T>(h: HyperscriptFunction<T>) {
  return (tagName: string): HyperscriptHelperFn<T> =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...rest: any[]) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      h(tagName, ...rest);
}

// The tag names are verified against html-tag-names in the tests
// See https://github.com/ohanhi/hyperscript-helpers/issues/34 for the reason
// why the tags aren't simply required from html-tag-names
enum TAG_NAMES {
  a,
  abbr,
  acronym,
  address,
  applet,
  area,
  article,
  aside,
  audio,
  b,
  base,
  basefont,
  bdi,
  bdo,
  bgsound,
  big,
  blink,
  blockquote,
  body,
  br,
  button,
  canvas,
  caption,
  center,
  cite,
  code,
  col,
  colgroup,
  command,
  content,
  data,
  datalist,
  dd,
  del,
  details,
  dfn,
  dialog,
  dir,
  div,
  dl,
  dt,
  element,
  em,
  embed,
  fieldset,
  figcaption,
  figure,
  font,
  footer,
  form,
  frame,
  frameset,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  head,
  header,
  hgroup,
  hr,
  html,
  i,
  iframe,
  image,
  img,
  input,
  ins,
  isindex,
  kbd,
  keygen,
  label,
  legend,
  li,
  link,
  listing,
  main,
  map,
  mark,
  marquee,
  math,
  menu,
  menuitem,
  meta,
  meter,
  multicol,
  nav,
  nextid,
  nobr,
  noembed,
  noframes,
  noscript,
  object,
  ol,
  optgroup,
  option,
  output,
  p,
  param,
  picture,
  plaintext,
  pre,
  progress,
  q,
  rb,
  rbc,
  rp,
  rt,
  rtc,
  ruby,
  s,
  samp,
  script,
  section,
  select,
  shadow,
  slot,
  small,
  source,
  spacer,
  span,
  strike,
  strong,
  style,
  sub,
  summary,
  sup,
  svg,
  table,
  tbody,
  td,
  template,
  textarea,
  tfoot,
  th,
  thead,
  time,
  title,
  tr,
  track,
  tt,
  u,
  ul,
  var,
  video,
  wbr,
  xmp,
}

enum SVG_TAG_NAMES {
  a,
  altGlyph,
  altGlyphDef,
  altGlyphItem,
  animate,
  animateColor,
  animateMotion,
  animateTransform,
  article,
  circle,
  clipPath,
  colorProfile,
  cursor,
  defs,
  desc,
  ellipse,
  feBlend,
  feColorMatrix,
  feComponentTransfer,
  feComposite,
  feConvolveMatrix,
  feDiffuseLighting,
  feDisplacementMap,
  feDistantLight,
  feFlood,
  feFuncA,
  feFuncB,
  feFuncG,
  feFuncR,
  feGaussianBlur,
  feImage,
  feMerge,
  feMergeNode,
  feMorphology,
  feOffset,
  fePointLight,
  feSpecularLighting,
  feSpotlight,
  feTile,
  feTurbulence,
  filter,
  font,
  fontFace,
  fontFaceFormat,
  fontFaceName,
  fontFaceSrc,
  fontFaceUri,
  foreignObject,
  g,
  glyph,
  glyphRef,
  hkern,
  image,
  line,
  linearGradient,
  marker,
  mask,
  metadata,
  missingGlyph,
  mpath,
  nav,
  path,
  pattern,
  polygon,
  polyline,
  radialGradient,
  rect,
  script,
  section,
  set,
  stop,
  style,
  switch,
  symbol,
  text,
  textPath,
  title,
  tref,
  tspan,
  use,
  view,
  vkern,
}

export type TagHelpers<T> = {
  [key in Extract<keyof typeof TAG_NAMES, string>]: HyperscriptHelperFn<T>;
};
export type SvgTagHelpers<T> = {
  [key in Extract<keyof typeof SVG_TAG_NAMES, string>]: HyperscriptHelperFn<T>;
};

function enumKeys<K extends string, V>(enumObj: Record<K, V>): K[] {
  return Object.keys(enumObj) as K[];
}

export function createHyperscriptHelpers<T>(h: HyperscriptFunction<T>) {
  const createTag = node(h);
  const exported: TagHelpers<T> = {} as TagHelpers<T>;
  enumKeys(TAG_NAMES).forEach((tag) => {
    exported[tag] = createTag(tag);
  });
  return exported;
}
export function createHyperscriptHelpersSvg<T>(h: HyperscriptFunction<T>) {
  const createTag = node(h);
  const exported: SvgTagHelpers<T> = {} as SvgTagHelpers<T>;
  enumKeys(SVG_TAG_NAMES).forEach((tag) => {
    exported[tag] = createTag(tag);
  });
  return exported;
}
