import {
  DOM_ID_SECTION_SEITENINHALT,
  DOM_ID_DIV_FILTER,
  SEARCH_RESULTS_SCROLL_HEIGHT_RATIO,
} from '../../constants';

export function focusSeiteninhalt(): void {
  const sectionSeiteninhalt = document.getElementById(
    DOM_ID_SECTION_SEITENINHALT,
  );
  sectionSeiteninhalt?.focus();
}

export function focusSearchResults(): void {
  setTimeout(() => {
    const sectionSeiteninhalt = document.getElementById(
      DOM_ID_SECTION_SEITENINHALT,
    );
    if (sectionSeiteninhalt) {
      const viewportOffset = sectionSeiteninhalt.getBoundingClientRect().top;
      const sectionWindowHeightRatio =
        1 - viewportOffset / document.documentElement.clientHeight;
      if (sectionWindowHeightRatio < SEARCH_RESULTS_SCROLL_HEIGHT_RATIO) {
        sectionSeiteninhalt.scrollIntoView();
      }
      sectionSeiteninhalt.focus();
    }
  });
}

export function focusSearchfilter(): void {
  setTimeout(() => {
    const divFilter = document.getElementById(DOM_ID_DIV_FILTER);
    if (divFilter) {
      const viewportOffset = divFilter.getBoundingClientRect().top;
      const sectionWindowHeightRatio =
        1 - viewportOffset / document.documentElement.clientHeight;
      if (sectionWindowHeightRatio < SEARCH_RESULTS_SCROLL_HEIGHT_RATIO) {
        divFilter.scrollIntoView();
      }
      divFilter.focus();
    }
  });
}
