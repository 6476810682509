import flow from 'lodash/fp/flow';
import mapValues from 'lodash/fp/mapValues';
import omitBy from 'lodash/fp/omitBy';

type NoBooleanField<T> = {
  [P in keyof T]: T[P] extends boolean ? '' : Exclude<T[P], boolean>;
};

/* Test:
type blub = NoBooleanField<{
  a: string;
  b: boolean;
  c: number;
  d: undefined;
  e: {
    f: string;
    g: boolean;
    h: number;
    i: undefined;
  };
}>;

const bla: blub = {
  a: 'string',
  b: 'asdf',
  c: 5,
  d: undefined,
  e: {
    f: 'string',
    g: true,
    h: 5,
    i: undefined,
  },
};
*/

/**
 * Attributes with value false will be removed, attributes with value true will get an empty string as value
 * all other attributes stay as they are
 * @param obj attributes
 * @returns {*}
 */
export function mapBooleanAttributes<A extends Record<string, unknown>>(
  obj: A,
): NoBooleanField<A> {
  return flow(
    omitBy((value) => value === false),
    mapValues((value) => (value === true ? '' : value)),
  )(obj) as NoBooleanField<A>;
}
