import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { a, div, i, li, span, ul } from '../../render/html';
import { DANGEROUSLY_HTML_CONTENT } from '../../render/incremental-hyperscript';
import { BACK } from '../../texts';

export type PvLagenBackEntry = {
  iconSvgSafeHtml: string | undefined;
  name: string;
  url: string;
};

export type PvLagenNavEntry = {
  count: number | undefined;
  current: boolean;
  iconSvgSafeHtml: string | undefined;
  name: string;
  url: string;
};

export function renderPvLagenNavigation(
  backLink: PvLagenBackEntry | undefined,
  navData: PvLagenNavEntry[],
): RenderResultWithSsr[] {
  const parentNav = backLink
    ? div(
        {
          class: 'm-0',
        },
        a(
          {
            class:
              'p-3 d-block bg-primary text-light border-1 border-dark border font-font-roc-grotesk-w05-regular font-size-4 text-decoration-none',
            'aria-label': BACK,
            href: backLink.url,
          },
          [
            div({ class: 'd-flex align-items-center' }, [
              i({ class: 'bi bi-chevron-left me-3' }),
              span({}, backLink.name),
            ]),
          ],
        ),
      )
    : undefined;
  let result: RenderResultWithSsr[];
  if (navData.length) {
    result = [
      ul(
        { class: 'navbar-nav d-flex mb-0 mb-lg-6' },
        navData.map((nav) =>
          li(
            { class: `nav-item` },
            a(
              {
                class: `nav-link d-flex align-items-center justify-content-between${
                  nav.current ? ' active' : ''
                }`,
                href: nav.url,
              },
              [
                div({ class: 'd-flex align-items-center' }, [
                  nav.iconSvgSafeHtml
                    ? span({
                        class: `og-icon-with-text`,
                        'aria-label': '',
                        role: 'img',
                        [DANGEROUSLY_HTML_CONTENT]: nav.iconSvgSafeHtml,
                      })
                    : span(
                        {
                          class: `og-icon-with-text`,
                        },
                        '',
                      ),
                  span({}, nav.name),
                ]),
                nav.count === undefined
                  ? undefined
                  : span({}, String(nav.count)),
              ],
            ),
          ),
        ),
      ),
    ];
  } else {
    result = [];
  }
  if (parentNav) {
    return [parentNav, ...result];
  } else {
    return result;
  }
}
