import { ORG_SHOWN } from './detailEvents';
import { scrollToTop } from '../../util/scrollIntoView';
import { loadDataIfNecessary } from '../loadDataIfNecessary';
import type { OrgRequest } from './detailTypes';
import type { SimpleStore } from '../../state/SimpleStore';

/**
 * Lädt die Detaildaten für eine Organisationseinheit und einen Ort und zeigt diese an.
 *
 * @param simpleStore Zugriff auf dispatch und getState
 * @param event Browser-Event wird verwendet, um preventDefault() aufzurufen
 * @param org Id der Organisationseinheit, die angezeigt werden soll
 */
export function showOrg(
  simpleStore: SimpleStore,
  { event, org_id: orgId }: { event?: MouseEvent } & OrgRequest,
): void {
  event?.preventDefault();
  simpleStore.dispatch(ORG_SHOWN({ org_id: orgId }));
  loadDataIfNecessary(simpleStore);
  scrollToTop();
}
