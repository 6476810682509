import type { Action } from 'redux';
import { LEISTUNG_SHOWN, ORG_SHOWN } from '../../action/detail/detailEvents';
import type {
  LeistungRequestWithRegschl,
  OrgRequest,
} from '../../action/detail/detailTypes';
import { SERVER_URL_IMAGE } from '../../constants';
import { urlPathSearchParamsToString } from '../../history/myHistoryApi';
import myHistoryImpl from '../../history/myHistoryImpl';
import type { State } from '../../state/createInitialState';
import type { SimpleStore } from '../../state/SimpleStore';

function urlParamsSorted(params: Partial<Record<string, string>>) {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    // eigentlich kann value nie undefined sein, da wir nur über die Einträge
    // iterieren. Allerdings interpretiert TypeScript die Einträge
    //  fälschlicherweise als string|undefined
    if (value !== undefined) {
      searchParams.set(key, value);
    }
  });
  searchParams.sort();
  return searchParams;
}

export function buildImageUrl(
  image: { hash: string } | { url: string },
): string {
  if ('hash' in image) {
    return `${SERVER_URL_IMAGE()}?${urlParamsSorted({
      id: image.hash,
    }).toString()}`;
  } else {
    return image.url;
  }
}
export function buildLeistungUrl({
  leistung_id: leistungId,
  regschl,
  simpleStore,
}: {
  simpleStore: SimpleStore;
} & LeistungRequestWithRegschl): string {
  return buildUrl(simpleStore, [
    LEISTUNG_SHOWN({ leistung_id: leistungId, regschl }),
  ]);
}
export function buildOrgUrl({
  org_id: orgId,
  simpleStore,
}: {
  simpleStore: SimpleStore;
} & OrgRequest): string {
  return buildUrl(simpleStore, [ORG_SHOWN({ org_id: orgId })]);
}

export function buildUrl(simpleStore: SimpleStore, events: Action[]): string {
  const futureState = events.reduce(
    (acc: State, event: Action) => simpleStore.rootReducer(acc, event),
    simpleStore.getState(),
  );
  const futureUrl = myHistoryImpl.stateToUrl(futureState);
  return urlPathSearchParamsToString(futureUrl);
}
