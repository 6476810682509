import {
  SERVER_URL_ORGEINHEIT,
  SERVER_URL_ORGEINHEIT_SUCHE_STATISTIC,
} from '../../constants';
import { logError } from '../../util/logError';
import { scrollToAnchor } from '../../util/scrollIntoView';
import type { HtmlPageOrg } from '../htmlTypes';
import { isAbortError, isErrorWithStatus, postNoRedirect } from '../util/fetch';
import { fetchStatistics } from '../util/fetchStatistics';
import {
  ORG_ERROR,
  ORG_FINISHED,
  ORG_NOT_FOUND,
  ORG_STARTED,
} from './detailEvents';
import { deepEquals } from '../../util/deepEquals';
import type { OrgRequest } from './detailTypes';
import type { State } from '../../state/createInitialState';
import type { SimpleStore } from '../../state/SimpleStore';
import { abortRequestFor } from '../util/abortRequestFor';

export function buildOrgseitenRequest(state: State) {
  return state.org.request ?? { org_id: '' };
}

export function loadOrgIfNotLoading(
  simpleStore: SimpleStore,
  request: OrgRequest,
): void {
  const state = simpleStore.getState();
  const suche = state.search.request.searchTerm;
  if (
    !deepEquals(request, state.abortRequest.organisationseinheit?.forRequest)
  ) {
    const { abortRequest, result } = postNoRedirect<OrgRequest, HtmlPageOrg>({
      url: SERVER_URL_ORGEINHEIT(),
      data: request,
      flags: state.flags,
    });
    if (suche) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchStatistics({
        url: SERVER_URL_ORGEINHEIT_SUCHE_STATISTIC(),
        data: { ...request, suche },
      });
    }
    abortRequestFor(state.abortRequest.organisationseinheit);
    simpleStore.dispatch(ORG_STARTED({ abortRequest, forRequest: request }));
    result
      .then((results: HtmlPageOrg) => {
        const latestRequest =
          simpleStore.getState().abortRequest.organisationseinheit?.forRequest;
        if (deepEquals(latestRequest, request)) {
          simpleStore.dispatch(
            ORG_FINISHED({ patch: results.page, forRequest: request }),
          );
          scrollToAnchor(simpleStore);
        }
      })
      .catch((e: unknown) => {
        const latestRequest =
          simpleStore.getState().abortRequest.organisationseinheit?.forRequest;
        if (deepEquals(latestRequest, request)) {
          // request war nicht erfolgreich
          if (isAbortError(e)) {
            // Request wurde absichtlich (abortRequest()) abgebrochen --> nichts zu tun
          } else if (isErrorWithStatus(e) && e.status === 404) {
            // keine Daten gefunden
            simpleStore.dispatch(ORG_NOT_FOUND(request));
          } else {
            // sonstiger Fehler
            logError(e);
            simpleStore.dispatch(ORG_ERROR(e));
          }
        }
      });
  }
}
