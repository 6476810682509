import isEmpty from 'lodash/fp/isEmpty';
import { paramsToString, prepareData } from './fetch';

export function fetchStatistics({
  url,
  data,
}: {
  url: string;
  data: Partial<
    Record<string, string | string[] | number | boolean | undefined>
  >;
}): Promise<unknown> {
  const preparedData = prepareData(data);
  const method = 'GET';
  const requestUrl =
    preparedData && !isEmpty(preparedData)
      ? `${url}?${paramsToString(preparedData)}`
      : url;
  return fetch(requestUrl, {
    method,
  });
}
