export type Flags = {
  // eslint-disable-next-line camelcase
  all_words?: 'off' | 'on';
  // eslint-disable-next-line camelcase
  drupal?: 'off' | 'on';
  // eslint-disable-next-line camelcase
  external_services?: 'off' | 'on';
  // eslint-disable-next-line camelcase
  fail_request?: string;
  infix?: 'off' | 'on';
  invalid?: 'include';
  leading?: 'og';
  map_od?: 'unsynced' | 'synced';
  // eslint-disable-next-line camelcase
  non_public?: 'show';
  // eslint-disable-next-line camelcase
  org_filter?: 'multi' | 'single' | 'dual';
  // eslint-disable-next-line camelcase
  org_hierarchy?: 'show';
  // eslint-disable-next-line camelcase
  prefix_urls?: 'true' | 'false';
  // eslint-disable-next-line camelcase
  search_in?: 'title';
  // eslint-disable-next-line camelcase
  share_generic?: 'show';
  technical?: 'show';
};

const flagKeys: {
  [P in NonNullable<keyof Flags>]: Flags[P][];
} = {
  all_words: [undefined, 'off', 'on'],
  drupal: [undefined, 'off', 'on'],
  external_services: [undefined, 'off', 'on'],
  fail_request: [undefined],
  infix: [undefined, 'off', 'on'],
  invalid: [undefined, 'include'],
  leading: [undefined, 'og'],
  map_od: [undefined, 'unsynced', 'synced'],
  non_public: [undefined, 'show'],
  org_filter: [undefined, 'multi', 'single', 'dual'],
  org_hierarchy: [undefined, 'show'],
  prefix_urls: [undefined, 'true', 'false'],
  search_in: [undefined, 'title'],
  share_generic: [undefined, 'show'],
  technical: [undefined, 'show'],
};

function isInKeys<A extends Partial<Record<string, unknown>>>(
  value: string | number | symbol,
  obj: A,
): value is keyof A {
  return value in obj;
}

function isInList<V, A extends V>(value: V, list: A[]): value is A {
  return list.includes(value as A);
}

export function flagsToObject(flags = ''): Flags {
  const parts = flags.split(',');
  const result: Flags = {};
  parts.forEach((part) => {
    const keyValue = part.split(':');
    const key = keyValue[0];
    const value = keyValue.length > 1 ? keyValue[1] : undefined;
    if (isInKeys(key, flagKeys)) {
      if (key === 'fail_request') {
        result[key] = value;
      } else if (isInList(value, flagKeys[key])) {
        result[key] = value as keyof Flags[typeof key];
      }
    }
  });
  return result;
}

export function flagsToString(flags: Flags): string {
  return (Object.keys(flags) as (keyof Flags)[])
    .map((key: keyof Flags) => {
      const value = flags[key];
      return (
        encodeURIComponent(key) + (value ? `:${encodeURIComponent(value)}` : '')
      );
    })
    .join(',');
}
