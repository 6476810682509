import { Dropdowns } from '../../action/search/searchCommon';
import type { State } from '../../state/createInitialState';
import { REGSCHL_HESSEN } from '../../constants';
import type { RenderCityFieldParams } from '../blocks/citySearch';
import combinePlaceNames from '../util/combinePlaceNames';
import { CITY_INPUT_DESCRIPTION } from '../../texts';

export function selectParamsForCitySearch(state: State): RenderCityFieldParams {
  const inputValue = state.citySearch.input.searchTerm;
  const { selectedOrt } = state.citySearch;

  let placeholder: string;
  if (inputValue) {
    placeholder = '';
  } else if (selectedOrt.id === REGSCHL_HESSEN) {
    placeholder = CITY_INPUT_DESCRIPTION;
  } else if (selectedOrt.isFindable) {
    placeholder = '';
  } else {
    placeholder = combinePlaceNames(selectedOrt);
  }

  return {
    dropdownState: state.dropdowns[Dropdowns.CITY],
    inputValue,
    loading: Boolean(state.abortRequest.citySearch),
    ariaDescription: CITY_INPUT_DESCRIPTION,
    placeholder,
    showFeedbackHint: state.citySearch.feedbackHint,
    suggestions: state.citySearch.suggestions.data,
  };
}
