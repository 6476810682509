import { fetchStatistics } from '../action/util/fetchStatistics';
import { DOM_ID_SECTION_SHARE, SERVER_URL_SHARE_STATISTIC } from '../constants';
import type { RenderResultWithSsr } from '../lib/renderTypes';
import { frameSelector, patchOuterAll } from '../render/cached-incremental-dom';
import { a, button, div } from '../render/html';
// eslint-disable-next-line import/no-unresolved
import envelopIcon from './share-icons/envelope.svg';
// eslint-disable-next-line import/no-unresolved
import facebookIcon from './share-icons/facebook.svg';
// eslint-disable-next-line import/no-unresolved
import linkedinIcon from './share-icons/linkedin.svg';
// eslint-disable-next-line import/no-unresolved
import shareIcon from './share-icons/share.svg';
// eslint-disable-next-line import/no-unresolved
import twitterIcon from './share-icons/x.svg';
// eslint-disable-next-line import/no-unresolved
import xingIcon from './share-icons/xing.svg';
// eslint-disable-next-line import/no-unresolved
import printerIcon from './share-icons/printer.svg';
import { rawImageResizable } from './elements/rawImage';
import loadingOverlay from './blocks/loadingOverlay';

type ShareTemplate = {
  alt: string;
  destination: string;
  class?: string;
  checkShow?: (
    showGenericShare: boolean,
    params: ShareParam | undefined,
  ) => boolean;
  src: string;
} & (
  | {
      link: (params: ShareParam) => string;
    }
  | {
      action: (params: ShareParam | undefined) => () => void;
    }
);

export type ShareParam = { title: string; url: string };

const templates: ShareTemplate[] = [
  {
    alt: 'Teilen',
    action: (params) => () => {
      if (params) {
        const shareData: ShareData = {
          text: 'foo bar blub!',
          title: params.title,
          url: params.url,
        };
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigator.share(shareData);
      }
    },
    checkShow(showGenericShare, params) {
      if (
        !showGenericShare ||
        !params ||
        !('navigator' in window) ||
        !('canShare' in navigator) ||
        !('share' in navigator)
      ) {
        return false;
      } else {
        const shareData: ShareData = {
          title: params.title,
          url: params.url,
        };
        return navigator.canShare(shareData);
      }
    },
    destination: 'share',
    src: shareIcon,
  },
  {
    alt: 'Auf Facebook teilen',
    src: facebookIcon,
    link: ({ title, url }) =>
      `https://www.facebook.com/sharer/sharer.php?u=${url}&title=${title}`,
    destination: 'facebook',
  },
  {
    alt: 'Auf X teilen',
    src: twitterIcon,
    link: ({ title, url }) =>
      `https://twitter.com/intent/tweet?text=${title}+${url}`,
    destination: 'twitter',
  },
  {
    alt: 'Auf LinkedIn teilen',
    src: linkedinIcon,
    link: ({ title, url }) =>
      `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`,
    destination: 'linkedin',
  },
  {
    alt: 'Auf Xing teilen',
    src: xingIcon,
    link: ({ url }) =>
      `https://www.xing-share.com/app/user?op=share;sc_p=xing-share;url=${url}`,
    destination: 'xing',
  },
  {
    alt: 'Per E-Mail versenden',
    src: envelopIcon,
    link: ({ title, url }) => `mailto:?subject=${title}&body=${url}`,
    destination: 'email',
  },
  {
    alt: 'Drucken',
    src: printerIcon,
    class: 'og-share-printer',
    action: () => () => {
      window.print();
    },
    destination: 'print',
  },
];

export function patchShare(showGenericShare: boolean) {
  const shareMappings = {
    [frameSelector.share]: share(showGenericShare, {
      title: document.title,
      url: String(document.location),
    }),
  };
  patchOuterAll(shareMappings);
}

export function share(
  showGenericShare: boolean,
  params?: ShareParam,
): RenderResultWithSsr {
  return div(
    {
      class:
        'my-3 d-flex w-100 justify-content-end font-size-3 position-relative',
      id: DOM_ID_SECTION_SHARE,
    },
    [
      div({ class: 'd-flex justify-content-end position-relative flex-wrap' }, [
        ...templates.map((template) => {
          if (
            template.checkShow &&
            !template.checkShow(showGenericShare, params)
          ) {
            return undefined;
          } else {
            let href;
            if (params) {
              if ('link' in template) {
                href = template.link(params);
              } else {
                href = undefined;
              }
            } else {
              href = undefined;
            }

            let tag;
            let type;
            let classNames;
            if (href) {
              tag = a;
              type = undefined;
              classNames = '';
            } else {
              tag = button;
              type = button;
              classNames = 'btn m-0 p-0';
            }

            return tag(
              {
                type,
                target: '_blank',
                href,
                class: `vwp-icon--touch ${classNames} ${template.class ?? ''}`,
                onclick:
                  'action' in template
                    ? template.action(params)
                    : attachStatisticCall(template.destination),
                onauxclick:
                  'link' in template
                    ? attachStatisticCall(template.destination)
                    : undefined,
              },
              [
                rawImageResizable({
                  alt: template.alt,
                  data: template.src,
                }),
              ],
            );
          }
        }),
        params ? undefined : loadingOverlay(),
      ]),
    ],
  );
}

function attachStatisticCall(destination: string) {
  return () =>
    fetchStatistics({
      url: SERVER_URL_SHARE_STATISTIC(),
      data: {
        dest: destination,
        title: document.title,
        location: document.location.toString(),
      },
    });
}
