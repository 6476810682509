import { FILTER_PVLAGE_CHANGED } from '../../reducer/filterEvents';
import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { div, fieldset, form, input, label, legend } from '../../render/html';
import { getBereiche } from '../../util/pvLagenUtil';
import { renderCityField } from './citySearch';
import error from './error';
import { renderPvLagenSearchField } from './search';
import './startseite.css';
import type { Dropdown } from '../../action/search/searchCommon';
import type { Gebiet, SearchSuggestion } from '../../action/search/searchTypes';
import type { SimpleStore } from '../../state/SimpleStore';

export type StartpageSearchParams = {
  title: string;
  search: {
    dropdownState: Dropdown;
    inputValue: string;
    pvLage: string | undefined;
    showError: boolean;
    showValidationErrors: boolean;
    suggestions: SearchSuggestion[];
  };
  citySearch: {
    dropdownState: Dropdown;
    inputValue: string;
    loading: boolean;
    ariaDescription: string;
    placeholder: string;
    showFeedbackHint: boolean;
    suggestions: Gebiet[];
  };
};

export function search(
  simpleStoreForHandlers: SimpleStore,
  params: StartpageSearchParams,
): RenderResultWithSsr {
  const { pvLage, showError } = params.search;

  return form(
    {
      onsubmit: (event: Event) => {
        event.preventDefault();
      },
    },
    [
      renderPvLagenBereichRadioButtons(simpleStoreForHandlers, pvLage),
      renderPvLagenSearchField(simpleStoreForHandlers, {
        ...params.search,
        bereich: pvLage,
        isStartpage: true,
      }),
      renderCityField(simpleStoreForHandlers, params.citySearch),
      showError
        ? error(
            'Es ist leider ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben oder versuchen Sie es später erneut.',
          )
        : undefined,
    ],
  );
}

function renderPvLagenBereichRadioButtons(
  simpleStoreForHandlers: SimpleStore,
  pvLage: string | undefined,
) {
  const bereiche = getBereiche();
  return fieldset({}, [
    legend({ class: 'visually-hidden' }, 'Suche im Thema:'),
    div(
      { class: 'd-flex align-items-center' },
      ...bereiche.flatMap((bereich, index) => {
        const checked = pvLage === bereich.code || (index === 0 && !pvLage);
        return div(
          { class: 'form-check form-check-wrapped me-6' },
          label(
            { class: 'form-check-label' },
            input({
              type: 'radio',
              name: 'bereich',
              class: `form-check-input`,
              value: bereich.code,
              checked,
              onchange: (event: Event) => {
                const radio = event.target as HTMLInputElement;
                simpleStoreForHandlers.dispatch(
                  FILTER_PVLAGE_CHANGED(radio.value),
                );
              },
            }),
            bereich.name,
          ),
        );
      }),
    ),
  ]);
}
